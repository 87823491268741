// ENTITY fr.hiji.metier.certif.dto.back.stats.CertificationStatsInfoBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class CertificationStatsInfoBODTO {

  nbParticipantsDistinct: number | null = 0;
  nbParticipations: number | null = 0;
  nbCertifiedTotal: number | null = 0;
  nbSessions: number | null = 0;
  nbNotCertifiedTotal: number | null = 0;

  constructor(init?:Partial<CertificationStatsInfoBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.CertificationStatsInfoBODTO'] = CertificationStatsInfoBODTO;

reverseMapping[CertificationStatsInfoBODTO.name] = 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsInfoBODTO';

fields['fr.hiji.metier.certif.dto.back.stats.CertificationStatsInfoBODTO']  = {
    nbParticipantsDistinct: 'java.lang.Integer',
    nbParticipations: 'java.lang.Integer',
    nbCertifiedTotal: 'java.lang.Integer',
    nbSessions: 'java.lang.Integer',
    nbNotCertifiedTotal: 'java.lang.Integer'
};
