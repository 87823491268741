// fr.hiji.metier.user.services.front.CoachServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { CoachPerimeterOrganisationDTO } from '../entities/CoachPerimeterOrganisationDTO';

@Injectable({
  providedIn: 'root',
})
export class CoachService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getUsersInMyPerimeters(): RpcRequestBuilder<CoachPerimeterOrganisationDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CoachService.getUsersInMyPerimeters';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
