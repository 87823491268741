// ENTITY fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathFormationBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { TrainingPathFormationRequirementBODTO } from './TrainingPathFormationRequirementBODTO';
import { TrainingPathFormation } from './TrainingPathFormation';

export class TrainingPathFormationBODTO {

  formation: TrainingPathFormation | null = null;
  formationName: MultilingualString | null = new MultilingualString();
  formationImageExtension: string | null = '';
  formationDel: boolean | null = false;
  formationColorCode: string | null = '';
  requirements: TrainingPathFormationRequirementBODTO[] | null = [];

  constructor(init?:Partial<TrainingPathFormationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathFormationBODTO'] = TrainingPathFormationBODTO;

reverseMapping[TrainingPathFormationBODTO.name] = 'fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathFormationBODTO';

fields['fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathFormationBODTO']  = {
    formation: 'fr.hiji.metier.mif.entities.trainingpath.TrainingPathFormation',
    formationName: 'com.ic2.sc.MultilingualString',
    formationImageExtension: 'java.lang.String',
    formationDel: 'java.lang.Boolean',
    formationColorCode: 'java.lang.String',
    requirements: 'java.util.List<fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathFormationRequirementBODTO>'
};
