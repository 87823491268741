// ENTITY fr.hiji.metier.campaign.dto.front.SkillStatDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class SkillStatDTO {

  idRepository: number | null = 0;
  idTag: number | null = 0;
  tagName: MultilingualString | null = new MultilingualString();
  avg: number | null = 0;

  constructor(init?:Partial<SkillStatDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.SkillStatDTO'] = SkillStatDTO;

reverseMapping[SkillStatDTO.name] = 'fr.hiji.metier.campaign.dto.front.SkillStatDTO';

fields['fr.hiji.metier.campaign.dto.front.SkillStatDTO']  = {
    idRepository: 'java.lang.Integer',
    idTag: 'java.lang.Integer',
    tagName: 'com.ic2.sc.MultilingualString',
    avg: 'java.lang.Double'
};
