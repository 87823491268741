// ENTITY fr.hiji.metier.mif.dto.front.quiz.QuizMIFDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { FormationQuizOpenEndedAnswer } from './FormationQuizOpenEndedAnswer';
import { FormationQuizQCMAnswer } from './FormationQuizQCMAnswer';
import { ModalityQuizQuestionDTO } from './ModalityQuizQuestionDTO';

export class QuizMIFDTO {

  idFormationSession: number | null = 0;
  idFormationQuiz: number | null = 0;
  quizName: MultilingualString | null = new MultilingualString();
  questions: ModalityQuizQuestionDTO[] | null = [];
  startAnsweringDate: Date | null = null;
  draft: boolean | null = false;
  openEndedAnswers: FormationQuizOpenEndedAnswer[] | null = [];
  qcmSelectedAnswers: FormationQuizQCMAnswer[] | null = [];

  constructor(init?:Partial<QuizMIFDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.quiz.QuizMIFDTO'] = QuizMIFDTO;

reverseMapping[QuizMIFDTO.name] = 'fr.hiji.metier.mif.dto.front.quiz.QuizMIFDTO';

fields['fr.hiji.metier.mif.dto.front.quiz.QuizMIFDTO']  = {
    idFormationSession: 'java.lang.Integer',
    idFormationQuiz: 'java.lang.Integer',
    quizName: 'com.ic2.sc.MultilingualString',
    questions: 'java.util.List<fr.hiji.metier.certif.dto.front.modality.quiz.ModalityQuizQuestionDTO>',
    startAnsweringDate: 'java.time.Instant',
    draft: 'java.lang.Boolean',
    openEndedAnswers: 'java.util.List<fr.hiji.metier.mif.entities.quiz.FormationQuizOpenEndedAnswer>',
    qcmSelectedAnswers: 'java.util.List<fr.hiji.metier.mif.entities.quiz.FormationQuizQCMAnswer>'
};
