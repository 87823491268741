// ENTITY fr.hiji.metier.ema.dto.back.EMATemplateCoachingActionBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMAPredefinedCoachingAction } from './EMAPredefinedCoachingAction';
import { EMATemplateCoachingAction } from './EMATemplateCoachingAction';

export class EMATemplateCoachingActionBODTO {

  templateCoachingAction: EMATemplateCoachingAction | null = null;
  predefinedCoachingAction: EMAPredefinedCoachingAction | null = null;

  constructor(init?:Partial<EMATemplateCoachingActionBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.EMATemplateCoachingActionBODTO'] = EMATemplateCoachingActionBODTO;

reverseMapping[EMATemplateCoachingActionBODTO.name] = 'fr.hiji.metier.ema.dto.back.EMATemplateCoachingActionBODTO';

fields['fr.hiji.metier.ema.dto.back.EMATemplateCoachingActionBODTO']  = {
    templateCoachingAction: 'fr.hiji.metier.ema.entities.template.EMATemplateCoachingAction',
    predefinedCoachingAction: 'fr.hiji.metier.ema.entities.template.EMAPredefinedCoachingAction'
};
