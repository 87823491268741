// ENTITY fr.hiji.metier.mif.dto.front.trainer.TrainingPathFormationDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { FormationState } from './FormationState';
import { TrainingPathFormation } from './TrainingPathFormation';
import { TrainingPathFormationRequirementDTO } from './TrainingPathFormationRequirementDTO';
import { FormationDoneByUser } from './FormationDoneByUser';

export class TrainingPathFormationDTO {

  formation: TrainingPathFormation | null = null;
  formationName: MultilingualString | null = new MultilingualString();
  formationImageExtension: string | null = '';
  formationColorCode: string | null = '';
  requirements: TrainingPathFormationRequirementDTO[] | null = [];
  formationDoneByUser: FormationDoneByUser | null = null;
  state: FormationState | null = null;
  userMaxDateAmongAllSessions: Date | null = null;
  userMaxIdFormationSessionAmongAllSessions: number | null = 0;
  userMaxFormationSessionNameAmongAllSessions: string | null = '';
  order: number | null = 0;

  constructor(init?:Partial<TrainingPathFormationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.trainer.TrainingPathFormationDTO'] = TrainingPathFormationDTO;

reverseMapping[TrainingPathFormationDTO.name] = 'fr.hiji.metier.mif.dto.front.trainer.TrainingPathFormationDTO';

fields['fr.hiji.metier.mif.dto.front.trainer.TrainingPathFormationDTO']  = {
    formation: 'fr.hiji.metier.mif.entities.trainingpath.TrainingPathFormation',
    formationName: 'com.ic2.sc.MultilingualString',
    formationImageExtension: 'java.lang.String',
    formationColorCode: 'java.lang.String',
    requirements: 'java.util.List<fr.hiji.metier.mif.dto.front.trainer.TrainingPathFormationRequirementDTO>',
    formationDoneByUser: 'fr.hiji.metier.mif.entities.formation.FormationDoneByUser',
    state: 'fr.hiji.metier.mif.entities.formation.FormationState',
    userMaxDateAmongAllSessions: 'java.time.LocalDate',
    userMaxIdFormationSessionAmongAllSessions: 'java.lang.Integer',
    userMaxFormationSessionNameAmongAllSessions: 'java.lang.String',
    order: 'java.lang.Integer'
};
