import { Location } from '@angular/common';
import { Directive, HostListener, inject } from '@angular/core';

@Directive({
    selector: '[back]',
    standalone: false
})
export class BackDirective {
  private location = inject(Location);


  @HostListener('click')
  onClick() {
    this.location.back();
  }
}
