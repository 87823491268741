// fr.hiji.metier.mif.services.back.formation.stats.FormationOverviewStatsBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { FormationStatsFiltersBODTO } from '../entities/FormationStatsFiltersBODTO';
import { RecapFormationPerOrganisationMIFDTO } from '../entities/RecapFormationPerOrganisationMIFDTO';
import { RecapFormationMIFDTO } from '../entities/RecapFormationMIFDTO';

@Injectable({
  providedIn: 'root',
})
export class FormationOverviewStatsBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getDetailPerOrganisation(filter: FormationStatsFiltersBODTO, genericFilter: GenericFilter): RpcRequestBuilder<ListWithCount<RecapFormationPerOrganisationMIFDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationOverviewStatsBOService.getDetailPerOrganisation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(genericFilter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  downloadDetailPerOrganisationCSV(filter: FormationStatsFiltersBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationOverviewStatsBOService.downloadDetailPerOrganisationCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  getFormationsDetails(filter: FormationStatsFiltersBODTO, genericFilter: GenericFilter): RpcRequestBuilder<ListWithCount<RecapFormationMIFDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationOverviewStatsBOService.getFormationsDetails';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(genericFilter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  downloadFormationsDetailsCSV(filter: FormationStatsFiltersBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationOverviewStatsBOService.downloadFormationsDetailsCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

}
