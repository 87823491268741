<div class="row mt-1">
  <div class="col-1 d-flex align-items-center">{{ title() }}</div>
  <div class="col">
    <div class="input-group flex-nowrap d-flex flex-grow-1">
      <div class="input-group-text">{{ sourceLang }}</div>
      <textarea class="form-control" [(ngModel)]="translation.obj[sourceLang]" type="text" [rows]="textAreaRows()" [disabled]="true"></textarea>
    </div>
  </div>
  <div class="col">
    <div class="input-group flex-nowrap d-flex flex-grow-1">
      <div class="input-group-text">{{ destinationLang }}</div>
      <textarea class="form-control" [(ngModel)]="translation.obj[destinationLang]" type="text" [rows]="textAreaRows()" [disabled]="false"></textarea>
    </div>
  </div>
  <div class="col-auto d-flex align-items-center">
    <button class="btn btn-light" type="button" (click)="translate()" [disabled]="this.translation.obj[this.sourceLang] == null">
      {{ 'common.translate-textarea.Traduire' | translate }}
      @if (!loadingTrad) {
        <i class="fa-solid fa-globe"></i>
      }
      @if (loadingTrad) {
        <i class="fa-light fa-spinner spinning"></i>
      }
    </button>
  </div>
</div>
