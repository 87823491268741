// ENTITY fr.hiji.metier.reporting.dto.mif.recap.RecapFormationMIFDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Formation } from './Formation';

export class RecapFormationMIFDTO {

  formation: Formation | null = null;
  nbSessions: number | null = 0;
  nbAnswerSatisfaction: number | null = 0;
  avgSatisfaction: number | null = null;
  nps: number | null = null;
  avgAcquisitionBefore: number | null = null;
  avgAcquisitionAfter: number | null = null;
  nbParticipants: number | null = 0;
  nbParticipantsFullAttendance: number | null = 0;
  nbActions: number | null = 0;
  avgActions: number | null = null;
  nbExpectations: number | null = 0;
  avgExpectations: number | null = null;
  nbBusinessExpectations: number | null = 0;
  avgBusinessExpectations: number | null = null;
  nbEVA: number | null = 0;

  constructor(init?:Partial<RecapFormationMIFDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.mif.recap.RecapFormationMIFDTO'] = RecapFormationMIFDTO;

reverseMapping[RecapFormationMIFDTO.name] = 'fr.hiji.metier.reporting.dto.mif.recap.RecapFormationMIFDTO';

fields['fr.hiji.metier.reporting.dto.mif.recap.RecapFormationMIFDTO']  = {
    formation: 'fr.hiji.metier.mif.entities.formation.Formation',
    nbSessions: 'java.lang.Integer',
    nbAnswerSatisfaction: 'java.lang.Integer',
    avgSatisfaction: 'java.lang.Double',
    nps: 'java.lang.Double',
    avgAcquisitionBefore: 'java.lang.Double',
    avgAcquisitionAfter: 'java.lang.Double',
    nbParticipants: 'java.lang.Integer',
    nbParticipantsFullAttendance: 'java.lang.Integer',
    nbActions: 'java.lang.Integer',
    avgActions: 'java.lang.Double',
    nbExpectations: 'java.lang.Integer',
    avgExpectations: 'java.lang.Double',
    nbBusinessExpectations: 'java.lang.Integer',
    avgBusinessExpectations: 'java.lang.Double',
    nbEVA: 'java.lang.Integer'
};
