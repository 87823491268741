// ENTITY fr.hiji.metier.conf.dto.front.submodules.Module360DTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class Module360DTO {

  enabled: boolean | null = false;
  infoCollaborator: MultilingualString | null = new MultilingualString();
  infoPair: MultilingualString | null = new MultilingualString();
  infoManager: MultilingualString | null = new MultilingualString();
  infoExternal: MultilingualString | null = new MultilingualString();
  custom360Allowed: boolean | null = false;
  campaign360ExternalEnabled: boolean | null = false;
  campaign360AllowOnlyOneAnonymousInATargetType: boolean | null = false;

  constructor(init?:Partial<Module360DTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.front.submodules.Module360DTO'] = Module360DTO;

reverseMapping[Module360DTO.name] = 'fr.hiji.metier.conf.dto.front.submodules.Module360DTO';

fields['fr.hiji.metier.conf.dto.front.submodules.Module360DTO']  = {
    enabled: 'java.lang.Boolean',
    infoCollaborator: 'com.ic2.sc.MultilingualString',
    infoPair: 'com.ic2.sc.MultilingualString',
    infoManager: 'com.ic2.sc.MultilingualString',
    infoExternal: 'com.ic2.sc.MultilingualString',
    custom360Allowed: 'java.lang.Boolean',
    campaign360ExternalEnabled: 'java.lang.Boolean',
    campaign360AllowOnlyOneAnonymousInATargetType: 'java.lang.Boolean'
};
