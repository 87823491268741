import { Injectable, inject } from '@angular/core';
import { EnvService } from '@ic2/ic2-lib';
import { HijiBundle } from '../../lib/ic2/entities/HijiBundle';
import { Config } from '../tools/Config';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  authService = inject(AuthService);
  private env = inject<EnvService<Config>>(EnvService);

  avatar: string = 'assets/avatars/avatar_blanc.png';
  public defaultAvatarUrl: string = 'assets/avatars/avatar_blanc.png';
  public defaultAvatarUrlBlue: string = 'assets/avatars/avatar_bleu.png';
  r: number = 0;
  constructor() {
    this.reload();
  }

  reload() {
    this.r = Math.random();
    this.avatar = this.defaultAvatarUrl;
    if (this.authService.userBundle !== null)
      this.avatar = this.getAvatarUrlFor(
        this.authService.userBundle.user.idUser,
        (<HijiBundle>this.authService.userBundle.data).hijiUser.avatarExtension,
        false
      );
    //console.log(this.avatar);
    //else console.log('no avatar because userBundle is null');
  }

  getAvatarUrlFor(idUser: number, avatarExtension: string, defaultBlue: boolean) {
    if (!avatarExtension || !idUser) return defaultBlue ? this.defaultAvatarUrlBlue : this.defaultAvatarUrl;
    return this.env.configuration.rpcHost + '/avatar/' + idUser + '.' + avatarExtension + '?r=' + this.r;
    //return (<HijiBundle>this.authService.userBundle.data).avatarUrl + idUser + '.' + avatarExtension;
  }

  getInitials(firstName: string, lastName: string) {
    return (firstName + ' ' + lastName)
      .split('-')
      .filter((element) => element && element.length > 0)
      .map((element) => element[0].toUpperCase())
      .join('');
  }
}
