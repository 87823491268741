// ENTITY fr.hiji.metier.certif.entities.modality.quiz.ModalityQuizTarget generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityQuizTarget {

  idModality: number | null = 0;
  idTag: number | null = 0;
  value: number | null = 0;
  max: number | null = 0;

  constructor(init?:Partial<ModalityQuizTarget>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.quiz.ModalityQuizTarget'] = ModalityQuizTarget;

reverseMapping[ModalityQuizTarget.name] = 'fr.hiji.metier.certif.entities.modality.quiz.ModalityQuizTarget';

fields['fr.hiji.metier.certif.entities.modality.quiz.ModalityQuizTarget']  = {
    idModality: 'java.lang.Integer',
    idTag: 'java.lang.Integer',
    value: 'java.lang.Double',
    max: 'java.lang.Double'
};
