// ENTITY fr.hiji.metier.home.dto.front.FeedbackSolicitationDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FeedbackSolicitationDTO {

  idFeedbackSolicitation: number | null = 0;
  idUser: number | null = 0;
  functionName: string | null = '';
  avatarExtension: string | null = '';
  firstName: string | null = '';
  lastName: string | null = '';
  subject: string | null = '';
  description: string | null = '';
  creationDate: Date | null = null;

  constructor(init?:Partial<FeedbackSolicitationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.home.dto.front.FeedbackSolicitationDTO'] = FeedbackSolicitationDTO;

reverseMapping[FeedbackSolicitationDTO.name] = 'fr.hiji.metier.home.dto.front.FeedbackSolicitationDTO';

fields['fr.hiji.metier.home.dto.front.FeedbackSolicitationDTO']  = {
    idFeedbackSolicitation: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    functionName: 'java.lang.String',
    avatarExtension: 'java.lang.String',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    subject: 'java.lang.String',
    description: 'java.lang.String',
    creationDate: 'java.time.Instant'
};
