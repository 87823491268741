import { Component, OnInit, inject, output, viewChild, input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import { ObservableGestureStatDTO } from '../../../ic2/entities/entities';

@Component({
    selector: 'hiji-stats-og-chart-nps',
    templateUrl: './stats-og-chart-nps.component.html',
    styleUrls: ['./stats-og-chart-nps.component.scss'],
    standalone: false
})
export class StatsOgChartNpsComponent implements OnInit {
  private translate = inject(TranslateService);

  readonly observableGesture = input<Partial<ObservableGestureStatDTO>>(undefined);
  readonly showClose = input<boolean>(true);
  readonly close = output();
  readonly chart = viewChild(BaseChartDirective);

  pieChartPlugins = [];
  colors = {
    rouge: '#ed4731',
    orange: '#ed7d31',
    gris: '#c2c4d6',
    vertclair: '#92d050',
  };

  public doughnutChartData: ChartData<'doughnut'> = {
    labels: ['.', '.'],
    datasets: [
      {
        data: [20, 20, 20, 20],
        backgroundColor: [this.colors.vertclair, this.colors.gris, this.colors.orange, this.colors.rouge],
        hoverBackgroundColor: [this.colors.vertclair, this.colors.gris, this.colors.orange, this.colors.rouge],
        hoverBorderColor: [this.colors.vertclair, this.colors.gris, this.colors.orange, this.colors.rouge],
        borderColor: [this.colors.vertclair, this.colors.gris, this.colors.orange, this.colors.rouge],
        weight: 1,
      },
    ],
  };
  public doughnutChartType: ChartType = 'doughnut';
  chartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] !== 0;
        },
        formatter: function (value, context) {
          return Math.round(value) + '%';
        },
      },
    },
  };
  nps = 10;

  constructor() {
    this.pieChartPlugins = [ChartDataLabels];
  }

  ngOnInit(): void {
    this.doughnutChartData.labels = [
      this.translate.instant('common.charts.stats-og-chart-nps.Promoteurs'),
      this.translate.instant('common.charts.stats-og-chart-nps.Passifs'),
      this.translate.instant('common.charts.stats-og-chart-nps.Non-promoteurs'),
      this.translate.instant('common.charts.stats-og-chart-nps.Détracteurs'),
    ];
    const promoteurs = ((this.observableGesture().nbNps100 + this.observableGesture().nbNps90) / this.observableGesture().nb) * 100;
    const passifs = ((this.observableGesture().nbNps70 + this.observableGesture().nbNps80) / this.observableGesture().nb) * 100;
    const nonpromoteurs =
      ((this.observableGesture().nbNps30 + this.observableGesture().nbNps40 + this.observableGesture().nbNps50 + this.observableGesture().nbNps60) /
        this.observableGesture().nb) *
      100;
    const detracteurs = ((this.observableGesture().nbNps0 + this.observableGesture().nbNps10 + this.observableGesture().nbNps20) / this.observableGesture().nb) * 100;
    this.nps = promoteurs - (nonpromoteurs + detracteurs);
    this.doughnutChartData.datasets[0].data = [promoteurs, passifs, nonpromoteurs, detracteurs];
    const chart = this.chart();
    if (chart) chart.chart.update();
  }

  getAssignedTags() {
    return this.observableGesture().tags.filter((tag) => tag.assigned);
  }
}
