@if (loadingRepositoryName) {
  <div>
    <i class="fa-light fa-spinner spinning"></i>
  </div>
}
@if (!loadingRepositoryName) {
  <ic2-textassist
    name="repository"
    [ngbTooltip]="noDataTooltipTemplate()"
    placeholder="{{ placeholder() }}"
    [(ngModel)]="idRepository"
    (ngModelChange)="setValue($event)"
    [keyExtractor]="keyExtractorRepository"
    [values]="repositories"
    [presenter]="presenterRepository"
    (textChanges)="repositoryTextChanged($event)"
    [initialText]="initialText"
    [disabled]="loading || disabled()"
    [min]="1"
    [showClearButton]="!loading"
    [autoClose]="true"
    #noDataTooltip="ngbTooltip"
    #ta
    >
    <ng-template let-val>
      <span>{{ presenterRepository(val) }}</span>
    </ng-template>
    <ng-container append>
      @if (loading) {
        <div class="input-group-text">
          <i class="fa-light fa-spinner spinning"></i>
        </div>
      }
    </ng-container>
  </ic2-textassist>
}
