import { Directive, ElementRef, HostListener, inject, output } from '@angular/core';

@Directive({
    selector: '[scrollTracker]',
    standalone: false
})
export class ScrollTrackerDirective {
  private el = inject(ElementRef);

  readonly scrollingFinished = output<void>();

  emitted = false;

  @HostListener('scroll', [])
  onScroll(): void {
    //console.log(this.el.nativeElement.scrollTop, this.el.nativeElement.scrollHeight, this.el.nativeElement.clientHeight);
    if (this.el.nativeElement.clientHeight + this.el.nativeElement.scrollTop >= this.el.nativeElement.scrollHeight * 0.8 && !this.emitted) {
      this.emitted = true;
      this.scrollingFinished.emit();
    } else if (this.el.nativeElement.clientHeight + this.el.nativeElement.scrollTop < this.el.nativeElement.scrollHeight * 0.8) {
      this.emitted = false;
    } else if (this.el.nativeElement.clientHeight + this.el.nativeElement.scrollTop === this.el.nativeElement.scrollHeight) {
      //emit absolutely if scroll touch bottom
      this.emitted = true;
      this.scrollingFinished.emit();
    }
  }
}
