// ENTITY fr.hiji.metier.referential.dto.back.QuestionQCMBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { QuestionQCM } from './QuestionQCM';
import { QuestionQCMAnswer } from './QuestionQCMAnswer';

export class QuestionQCMBODTO {

  questionQCM: QuestionQCM | null = null;
  answers: QuestionQCMAnswer[] | null = [];

  constructor(init?:Partial<QuestionQCMBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.back.QuestionQCMBODTO'] = QuestionQCMBODTO;

reverseMapping[QuestionQCMBODTO.name] = 'fr.hiji.metier.referential.dto.back.QuestionQCMBODTO';

fields['fr.hiji.metier.referential.dto.back.QuestionQCMBODTO']  = {
    questionQCM: 'fr.hiji.metier.referential.entities.quiz.QuestionQCM',
    answers: 'java.util.List<fr.hiji.metier.referential.entities.quiz.QuestionQCMAnswer>'
};
