// ENTITY fr.hiji.metier.mif.dto.back.event.stats.FormationEventSkillStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { OGStatsPerFormationEventObservationTypeBODTO } from './OGStatsPerFormationEventObservationTypeBODTO';

export class FormationEventSkillStatsBODTO {

  idTag: number | null = 0;
  tagName: MultilingualString | null = new MultilingualString();
  colorCode: string | null = '';
  showInRadar: boolean | null = false;
  avg: number | null = null;
  obsTypes: OGStatsPerFormationEventObservationTypeBODTO[] | null = [];

  constructor(init?:Partial<FormationEventSkillStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.event.stats.FormationEventSkillStatsBODTO'] = FormationEventSkillStatsBODTO;

reverseMapping[FormationEventSkillStatsBODTO.name] = 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventSkillStatsBODTO';

fields['fr.hiji.metier.mif.dto.back.event.stats.FormationEventSkillStatsBODTO']  = {
    idTag: 'java.lang.Integer',
    tagName: 'com.ic2.sc.MultilingualString',
    colorCode: 'java.lang.String',
    showInRadar: 'java.lang.Boolean',
    avg: 'java.lang.Double',
    obsTypes: 'java.util.List<fr.hiji.metier.mif.dto.back.event.stats.OGStatsPerFormationEventObservationTypeBODTO>'
};
