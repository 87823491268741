// ENTITY fr.hiji.metier.mif.dto.back.stats.acquisition.OGStatsPerFormationObservationTypeBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:59 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationObservationType } from './FormationObservationType';

export class OGStatsPerFormationObservationTypeBODTO {

  type: FormationObservationType | null = null;
  nb: number | null = 0;
  avg: number | null = null;
  nbValue1: number | null = 0;
  nbValue2: number | null = 0;
  nbValue3: number | null = 0;
  nbValue4: number | null = 0;
  nbValue5: number | null = 0;
  nbNps0: number | null = 0;
  nbNps10: number | null = 0;
  nbNps20: number | null = 0;
  nbNps30: number | null = 0;
  nbNps40: number | null = 0;
  nbNps50: number | null = 0;
  nbNps60: number | null = 0;
  nbNps70: number | null = 0;
  nbNps80: number | null = 0;
  nbNps90: number | null = 0;
  nbNps100: number | null = 0;

  constructor(init?:Partial<OGStatsPerFormationObservationTypeBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.stats.acquisition.OGStatsPerFormationObservationTypeBODTO'] = OGStatsPerFormationObservationTypeBODTO;

reverseMapping[OGStatsPerFormationObservationTypeBODTO.name] = 'fr.hiji.metier.mif.dto.back.stats.acquisition.OGStatsPerFormationObservationTypeBODTO';

fields['fr.hiji.metier.mif.dto.back.stats.acquisition.OGStatsPerFormationObservationTypeBODTO']  = {
    type: 'fr.hiji.metier.mif.entities.observation.FormationObservationType',
    nb: 'java.lang.Integer',
    avg: 'java.lang.Double',
    nbValue1: 'java.lang.Integer',
    nbValue2: 'java.lang.Integer',
    nbValue3: 'java.lang.Integer',
    nbValue4: 'java.lang.Integer',
    nbValue5: 'java.lang.Integer',
    nbNps0: 'java.lang.Integer',
    nbNps10: 'java.lang.Integer',
    nbNps20: 'java.lang.Integer',
    nbNps30: 'java.lang.Integer',
    nbNps40: 'java.lang.Integer',
    nbNps50: 'java.lang.Integer',
    nbNps60: 'java.lang.Integer',
    nbNps70: 'java.lang.Integer',
    nbNps80: 'java.lang.Integer',
    nbNps90: 'java.lang.Integer',
    nbNps100: 'java.lang.Integer'
};
