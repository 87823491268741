// ENTITY fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFStatsDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { OrangeMIFOpenAnswerStatDTO } from './OrangeMIFOpenAnswerStatDTO';
import { OrangeMIFSessionSatisfactionStatDTO } from './OrangeMIFSessionSatisfactionStatDTO';
import { OrangeMIFSessionAcquisitionStatDTO } from './OrangeMIFSessionAcquisitionStatDTO';
import { OrangeMIFTagStatDTO } from './OrangeMIFTagStatDTO';
import { OrangeMIFQuizStatDTO } from './OrangeMIFQuizStatDTO';

export class OrangeMIFStatsDTO {

  globalSatisfactionRepositoryName: MultilingualString | null = new MultilingualString();
  npsOGName: MultilingualString | null = new MultilingualString();
  pourcentageRepondants: number | null = 0;
  globalNPS: number | null = 0;
  userNPS: number | null = 0;
  tags: OrangeMIFTagStatDTO[] | null = [];
  openAnswers: OrangeMIFOpenAnswerStatDTO[] | null = [];
  sessionsSatisfaction: OrangeMIFSessionSatisfactionStatDTO[] | null = [];
  acquisitionTags: OrangeMIFSessionAcquisitionStatDTO[] | null = [];
  quizes: OrangeMIFQuizStatDTO[] | null = [];

  constructor(init?:Partial<OrangeMIFStatsDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFStatsDTO'] = OrangeMIFStatsDTO;

reverseMapping[OrangeMIFStatsDTO.name] = 'fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFStatsDTO';

fields['fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFStatsDTO']  = {
    globalSatisfactionRepositoryName: 'com.ic2.sc.MultilingualString',
    npsOGName: 'com.ic2.sc.MultilingualString',
    pourcentageRepondants: 'java.lang.Double',
    globalNPS: 'java.lang.Double',
    userNPS: 'java.lang.Double',
    tags: 'java.util.List<fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFTagStatDTO>',
    openAnswers: 'java.util.List<fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFOpenAnswerStatDTO>',
    sessionsSatisfaction: 'java.util.List<fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFSessionSatisfactionStatDTO>',
    acquisitionTags: 'java.util.List<fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFSessionAcquisitionStatDTO>',
    quizes: 'java.util.List<fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFQuizStatDTO>'
};
