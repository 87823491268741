<div class="align-items-center d-flex flex-column justify-content-center" style="min-height: 100vh !important">
  <p class="mb-5 text-center">
    {{ "common.sso-error.Une erreur de connexion s'est produite" | translate }}
    <br />
    @if (error === 'no_user') {
      <span>{{ "common.sso-error.Vous n'avez pas de compte de paramétré" | translate }}</span>
    }
    @if (error === 'fnacdarty_no_user') {
      <span>Vous n'avez pas de compte de paramétré avec cette adresse email ({{ email }}), utilisez-vous bien votre adresse email nominative ?</span>
    }
    @if (error === 'user_deleted') {
      <span>{{ 'common.sso-error.Votre compte est désactivé' | translate }} ({{ email }})</span>
    }
    @if (error === 'no_right') {
      <span>{{ "common.sso-error.Votre compte n'a pas le droit d'accéder au backoffice" | translate }} ({{ email }})</span>
    }
    <br />
    @if (error !== undefined && error !== 'no_right' && error !== 'user_deleted') {
      <span>
        {{ "common.sso-error.Notre équipe à été avertie et s'occupe déjà de vous ! Nous revenons vers vous via mail dans les plus brefs délais" | translate }}.
      </span>
    }
    <br />
  </p>
  @if (error !== 'user_deleted') {
    <a class="btn btn-primary" (click)="retry()">{{ 'common.sso-error.Retenter la connexion' | translate }}</a>
  }
  @if (SsoErrorComponent.last_error) {
    <p class="mt-5 text-center">
      {{ 'common.sso-error.Merci de fournir cette erreur aux équipes techniques pour nous aider à corriger le problème' | translate }}
      <br />
      <br />
      {{ SsoErrorComponent.last_error }}
    </p>
  }
</div>
