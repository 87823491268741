// ENTITY fr.hiji.metier.certif.dto.back.modality.quiz.ModalityQuizBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ModalityQuizTarget } from './ModalityQuizTarget';
import { ModalityQuizQuestionsPerTag } from './ModalityQuizQuestionsPerTag';
import { ModalityQuiz } from './ModalityQuiz';

export class ModalityQuizBODTO {

  modalityQuiz: ModalityQuiz | null = null;
  questionsPerTags: ModalityQuizQuestionsPerTag[] | null = [];
  targets: ModalityQuizTarget[] | null = [];
  quizName: MultilingualString | null = new MultilingualString();
  quizDel: boolean | null = false;

  constructor(init?:Partial<ModalityQuizBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.modality.quiz.ModalityQuizBODTO'] = ModalityQuizBODTO;

reverseMapping[ModalityQuizBODTO.name] = 'fr.hiji.metier.certif.dto.back.modality.quiz.ModalityQuizBODTO';

fields['fr.hiji.metier.certif.dto.back.modality.quiz.ModalityQuizBODTO']  = {
    modalityQuiz: 'fr.hiji.metier.certif.entities.modality.quiz.ModalityQuiz',
    questionsPerTags: 'java.util.List<fr.hiji.metier.certif.entities.modality.quiz.ModalityQuizQuestionsPerTag>',
    targets: 'java.util.List<fr.hiji.metier.certif.entities.modality.quiz.ModalityQuizTarget>',
    quizName: 'com.ic2.sc.MultilingualString',
    quizDel: 'java.lang.Boolean'
};
