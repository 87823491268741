// ENTITY fr.hiji.metier.dashboard.dto.DashboardCampaignBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class DashboardCampaignBODTO {

  idCampaign: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  nbTarget: number | null = 0;

  constructor(init?:Partial<DashboardCampaignBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.dashboard.dto.DashboardCampaignBODTO'] = DashboardCampaignBODTO;

reverseMapping[DashboardCampaignBODTO.name] = 'fr.hiji.metier.dashboard.dto.DashboardCampaignBODTO';

fields['fr.hiji.metier.dashboard.dto.DashboardCampaignBODTO']  = {
    idCampaign: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    nbTarget: 'java.lang.Integer'
};
