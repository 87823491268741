import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { L, UserEditValidators } from '@ic2/ic2-lib';
import { ResetPwdService } from '../../../ic2/services/ResetPwdService';
import { SignupComponent } from '../../signup/signup.component';

@Component({
    selector: 'hiji-reset-password-form',
    templateUrl: './reset-password-form.component.html',
    styleUrls: ['./reset-password-form.component.scss'],
    standalone: false
})
export class ResetPasswordFormComponent implements OnInit {
  private fb = inject(FormBuilder);
  private resetPwdService = inject(ResetPwdService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  form: FormGroup = this.fb.group(
    {
      password: [null, [Validators.required, Validators.pattern(SignupComponent.passwordPattern)]],
      passwordConfirm: [null, [Validators.required, Validators.pattern(SignupComponent.passwordPattern)]],
    },
    {
      validator: UserEditValidators.checkPasswords('password', 'passwordConfirm'),
    }
  );
  loading: boolean = false;
  error: boolean = false;
  token: string = null;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params['token'] === undefined) return;
      this.token = params['token'];
    });
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    this.loading = true;
    this.resetPwdService.changePassword(this.token, this.form.value.password).subscribe(
      (data) => {
        this.loading = false;
        this.router.navigate(['/login']);
      },
      (err) => {
        this.loading = false;
        if (err.isBusinessError()) {
          this.error = true;
        } else L.e(err);
      }
    );
  }
}
