// ENTITY fr.hiji.metier.reporting.dto.back.OrganisationReportingEMADTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Organisation } from './Organisation';

export class OrganisationReportingEMADTO {

  organisation: Organisation | null = null;
  manualOrPerimeter: boolean | null = false;
  nbUserInsideOrg: number | null = 0;

  constructor(init?:Partial<OrganisationReportingEMADTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.back.OrganisationReportingEMADTO'] = OrganisationReportingEMADTO;

reverseMapping[OrganisationReportingEMADTO.name] = 'fr.hiji.metier.reporting.dto.back.OrganisationReportingEMADTO';

fields['fr.hiji.metier.reporting.dto.back.OrganisationReportingEMADTO']  = {
    organisation: 'fr.hiji.metier.reseau.entities.Organisation',
    manualOrPerimeter: 'java.lang.Boolean',
    nbUserInsideOrg: 'java.lang.Integer'
};
