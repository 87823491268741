// fr.hiji.metier.ema.services.back.EMATemplateBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { EMATemplateBODTO } from '../entities/EMATemplateBODTO';
import { EMATemplateItemBODTO } from '../entities/EMATemplateItemBODTO';

@Injectable({
  providedIn: 'root',
})
export class EMATemplateBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getEMATemplates(filter: GenericFilter): RpcRequestBuilder<ListWithCount<EMATemplateItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMATemplateBOService.getEMATemplates';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getEMATemplate(idEMATemplate: number): RpcRequestBuilder<EMATemplateBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMATemplateBOService.getEMATemplate';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idEMATemplate, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.dto.back.EMATemplateBODTO');
  }

  saveEMATemplate(dto: EMATemplateBODTO): RpcRequestBuilder<EMATemplateBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMATemplateBOService.saveEMATemplate';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.ema.dto.back.EMATemplateBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.dto.back.EMATemplateBODTO');
  }

}
