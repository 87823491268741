// ENTITY fr.hiji.metier.campaign.dto.back.stats.SkillStatPerObservationTypeBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:59 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ObservationType } from './ObservationType';

export class SkillStatPerObservationTypeBODTO {

  type: ObservationType | null = null;
  avg: number | null = null;
  avgFirstHalf: number | null = null;
  avgSecondHalf: number | null = null;

  constructor(init?:Partial<SkillStatPerObservationTypeBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.stats.SkillStatPerObservationTypeBODTO'] = SkillStatPerObservationTypeBODTO;

reverseMapping[SkillStatPerObservationTypeBODTO.name] = 'fr.hiji.metier.campaign.dto.back.stats.SkillStatPerObservationTypeBODTO';

fields['fr.hiji.metier.campaign.dto.back.stats.SkillStatPerObservationTypeBODTO']  = {
    type: 'fr.hiji.metier.campaign.entities.ObservationType',
    avg: 'java.lang.Double',
    avgFirstHalf: 'java.lang.Double',
    avgSecondHalf: 'java.lang.Double'
};
