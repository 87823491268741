// fr.hiji.metier.campaign.services.back.survey.SurveyStatsParticipationBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { SurveyStatsParticipationPerDayBODTO } from '../entities/SurveyStatsParticipationPerDayBODTO';
import { SurveyStatsParticipationByOrganisationBODTO } from '../entities/SurveyStatsParticipationByOrganisationBODTO';

@Injectable({
  providedIn: 'root',
})
export class SurveyStatsParticipationBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getParticipationByOrganisation(idCampaign: number): RpcRequestBuilder<SurveyStatsParticipationByOrganisationBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsParticipationBOService.getParticipationByOrganisation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getParticipationByOrganisationCsv(idCampaign: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsParticipationBOService.getParticipationByOrganisationCsv';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  getParticipationEvolution(idCampaign: number): RpcRequestBuilder<SurveyStatsParticipationPerDayBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsParticipationBOService.getParticipationEvolution';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
