// ENTITY fr.hiji.metier.home.dto.front.ManagerInvitationDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ManagerInvitationDTO {

  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  avatarExtension: string | null = '';

  constructor(init?:Partial<ManagerInvitationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.home.dto.front.ManagerInvitationDTO'] = ManagerInvitationDTO;

reverseMapping[ManagerInvitationDTO.name] = 'fr.hiji.metier.home.dto.front.ManagerInvitationDTO';

fields['fr.hiji.metier.home.dto.front.ManagerInvitationDTO']  = {
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    avatarExtension: 'java.lang.String'
};
