import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Ic2ToastrService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: { classname?: string; delay?: number } = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  showDanger(textOrTpl: string | TemplateRef<any>) {
    this.toasts.push({ textOrTpl, ...{ classname: 'bg-danger text-white' } });
  }

  showWarning(textOrTpl: string | TemplateRef<any>) {
    this.toasts.push({ textOrTpl, ...{ classname: 'bg-warning text-white' } });
  }

  showSuccess(textOrTpl: string | TemplateRef<any>) {
    this.toasts.push({ textOrTpl, ...{ classname: 'bg-success text-white' } });
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
