import { Component, inject, input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EnvService, L, MsPipe } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { StaticPageService } from '../../ic2/services/StaticPageService';
import { AuthService } from '../../services/auth.service';
import { Config } from '../../tools/Config';

@Component({
    selector: 'hiji-static-page-display',
    templateUrl: './static-page-display.component.html',
    styleUrls: ['./static-page-display.component.scss'],
    standalone: false
})
export class StaticPageDisplayComponent {
  private staticPageService = inject(StaticPageService);
  private translate = inject(TranslateService);
  private domSanitizer = inject(DomSanitizer);
  authService = inject(AuthService);
  private env = inject<EnvService<Config>>(EnvService);
  private msPipe = inject(MsPipe);

  readonly slug = input<string>(undefined);
  html: SafeHtml | string;
  loading: boolean = true;
  ngOnInit(): void {
    this.staticPageService.getPage(this.slug()).subscribe(
      (data) => {
        this.loading = false;
        this.html = this.domSanitizer.bypassSecurityTrustHtml(this.msPipe.transform(data.html));
      },
      (err) => {
        this.loading = false;
        if (err.isBusinessError())
          this.html = this.domSanitizer.bypassSecurityTrustHtml(
            'Désolé, cette page n\'a pas encoré été rédigée. Merci de la rédiger dans le <a href="' +
              this.env.configuration.boUrl +
              '/static-page">backoffice</a>'
          );
        else L.e(err);
      }
    );
  }
}
