// fr.hiji.metier.campaign.services.front.ExchangeServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { HistogramDTO } from '../entities/HistogramDTO';
import { ExchangeDTO } from '../entities/ExchangeDTO';
import { ExchangeManagerSelectionDTO } from '../entities/ExchangeManagerSelectionDTO';

@Injectable({
  providedIn: 'root',
})
export class ExchangeService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getManagerRepositories(idUser: number): RpcRequestBuilder<ExchangeManagerSelectionDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ExchangeService.getManagerRepositories';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getPastExchange(idExchange: number): RpcRequestBuilder<ExchangeDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ExchangeService.getPastExchange';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idExchange, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.front.ExchangeDTO');
  }

  getExchange(idUser: number, idUserManager: number, idRepositories: number[]): RpcRequestBuilder<ExchangeDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ExchangeService.getExchange';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUserManager, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idRepositories, 'java.util.List<java.lang.Integer>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.front.ExchangeDTO');
  }

  createExchange(idUser: number, idUserManager: number, conclusion: string, startExchangeDate: Date, idRepositories: number[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ExchangeService.createExchange';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUserManager, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(conclusion, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(startExchangeDate, 'java.time.Instant', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idRepositories, 'java.util.List<java.lang.Integer>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getHistogram(idUser: number, idUserManager: number, idObservableGesture: number): RpcRequestBuilder<HistogramDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ExchangeService.getHistogram';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUserManager, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idObservableGesture, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
