// fr.hiji.metier.reseau.services.common.FonctionServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { FonctionLiteDTO } from '../entities/FonctionLiteDTO';

@Injectable({
  providedIn: 'root',
})
export class FonctionService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getFonctions(): RpcRequestBuilder<FonctionLiteDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FonctionService.getFonctions';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getFonctionWithFilter(filter: GenericFilter): RpcRequestBuilder<ListWithCount<FonctionLiteDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FonctionService.getFonctionWithFilter';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

}
