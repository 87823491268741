// ENTITY fr.hiji.metier.ema.dto.common.EMASkillMapDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMASkillMapOGDTO } from './EMASkillMapOGDTO';
import { EMASkillMapSkillSerieDTO } from './EMASkillMapSkillSerieDTO';
import { EMASkillMapFilterDTO } from './EMASkillMapFilterDTO';

export class EMASkillMapDTO {

  filter: EMASkillMapFilterDTO | null = null;
  user: string | null = '';
  dataLastDate: Date | null = null;
  nbUsers: number | null = 0;
  skillSeries: EMASkillMapSkillSerieDTO[] | null = [];
  ogs: EMASkillMapOGDTO[] | null = [];

  constructor(init?:Partial<EMASkillMapDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.common.EMASkillMapDTO'] = EMASkillMapDTO;

reverseMapping[EMASkillMapDTO.name] = 'fr.hiji.metier.ema.dto.common.EMASkillMapDTO';

fields['fr.hiji.metier.ema.dto.common.EMASkillMapDTO']  = {
    filter: 'fr.hiji.metier.ema.dto.common.EMASkillMapFilterDTO',
    user: 'java.lang.String',
    dataLastDate: 'java.time.LocalDate',
    nbUsers: 'java.lang.Integer',
    skillSeries: 'java.util.List<fr.hiji.metier.ema.dto.common.EMASkillMapSkillSerieDTO>',
    ogs: 'java.util.List<fr.hiji.metier.ema.dto.common.EMASkillMapOGDTO>'
};
