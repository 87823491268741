// ENTITY fr.hiji.metier.conf.dto.back.submodules.FrontConfigurationBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FrontConfigurationBODTO {

  displayFunctionsFront: boolean | null = false;
  hideHomeTeamButtonsEnabled: boolean | null = false;
  tagRadarSortDefaultLang: boolean | null = false;

  constructor(init?:Partial<FrontConfigurationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.back.submodules.FrontConfigurationBODTO'] = FrontConfigurationBODTO;

reverseMapping[FrontConfigurationBODTO.name] = 'fr.hiji.metier.conf.dto.back.submodules.FrontConfigurationBODTO';

fields['fr.hiji.metier.conf.dto.back.submodules.FrontConfigurationBODTO']  = {
    displayFunctionsFront: 'java.lang.Boolean',
    hideHomeTeamButtonsEnabled: 'java.lang.Boolean',
    tagRadarSortDefaultLang: 'java.lang.Boolean'
};
