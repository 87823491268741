// ENTITY fr.hiji.metier.ema.dto.front.EMAUserOGCDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMAUserOGC } from './EMAUserOGC';

export class EMAUserOGCDTO {

  emaUserOGC: EMAUserOGC | null = null;
  nbObservationGeste: number | null = 0;

  constructor(init?:Partial<EMAUserOGCDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMAUserOGCDTO'] = EMAUserOGCDTO;

reverseMapping[EMAUserOGCDTO.name] = 'fr.hiji.metier.ema.dto.front.EMAUserOGCDTO';

fields['fr.hiji.metier.ema.dto.front.EMAUserOGCDTO']  = {
    emaUserOGC: 'fr.hiji.metier.ema.entities.EMAUserOGC',
    nbObservationGeste: 'java.lang.Integer'
};
