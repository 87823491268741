import { Component, OnInit, inject, output, input } from '@angular/core';
import { Tag } from '../../ic2/entities/Tag';
import { TagManager } from '../../services/tag-manager.service';

@Component({
    selector: 'hiji-tag-display',
    templateUrl: './tag-display.component.html',
    styleUrls: ['./tag-display.component.scss'],
    standalone: false
})
export class TagDisplayComponent implements OnInit {
  tagManager = inject(TagManager);

  readonly idTag = input<number>(undefined);
  readonly frontMode = input<boolean>(false);
  readonly hideDescInfoButton = input<boolean>(false);
  readonly noWrap = input<boolean>(true);
  readonly textAfterTagName = input<string>('');
  readonly descInfoClick = output<Tag>();

  ngOnInit(): void {}

  getParents() {
    return this.tagManager.getAllParentsOf(this.idTag(), true).reverse();
  }
}
