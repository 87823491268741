// ENTITY fr.hiji.metier.conf.dto.back.submodules.Module180BODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class Module180BODTO {

  moduleCampaign: boolean | null = false;
  coacheeAllowedToExchange: boolean | null = false;
  actionPlanSharedBetweenManagers: boolean | null = false;
  campainFeaturePopulation: number | null = 0;
  collabAutoObsNeedExchangeMailEnabled: boolean | null = false;

  constructor(init?:Partial<Module180BODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.back.submodules.Module180BODTO'] = Module180BODTO;

reverseMapping[Module180BODTO.name] = 'fr.hiji.metier.conf.dto.back.submodules.Module180BODTO';

fields['fr.hiji.metier.conf.dto.back.submodules.Module180BODTO']  = {
    moduleCampaign: 'java.lang.Boolean',
    coacheeAllowedToExchange: 'java.lang.Boolean',
    actionPlanSharedBetweenManagers: 'java.lang.Boolean',
    campainFeaturePopulation: 'java.lang.Integer',
    collabAutoObsNeedExchangeMailEnabled: 'java.lang.Boolean'
};
