// fr.hiji.metier.user.services.common.MeltingSpotJWTServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class MeltingSpotJWTService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getJWTToken(): RpcRequestBuilder<string> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MeltingSpotJWTService.getJWTToken';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.String');
  }

}
