<div class="row">
  <div class="col-10 mt-2">
    <h3 class="m-3">{{ observableGesture().observableGestureName | ms }}</h3>
    <div class="d-flex flex-column">
      @for (tag of getAssignedTags(); track tag) {
        <hiji-tag-display [idTag]="tag.idTag"></hiji-tag-display>
      }
    </div>
  </div>
  @if (showClose()) {
    <div class="col-2 mt-2 text-end">
      <i class="fa-light fa-times-circle fa-3x pointer" style="color: #feba01" (click)="close.emit()"></i>
    </div>
  }
</div>
<div class="row justify-content-center">
  <div class="position-relative" style="max-width: 400px; max-height: 400px">
    <hiji-download-graph [imgName]="observableGesture().observableGestureName | ms">
      <canvas
        id="ogChart{{ observableGesture().idObservableGesture }}"
        baseChart
        [data]="doughnutChartData"
        [type]="doughnutChartType"
        [options]="chartOptions"
        [plugins]="pieChartPlugins"
      ></canvas>
    </hiji-download-graph>
    <div class="translate-middle top-50 start-50 position-absolute text-center my-fs-5">{{ nps.toFixed(2) }}</div>
  </div>
</div>
