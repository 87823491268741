<hiji-wavy-container>
  <div class="container bg-white rounded shadow-2 py-3 px-0 px-md-3 m-3 mx-sm-auto">
    <div class="row justify-content-center align-items-center">
      <div class="container-fluid">
        @if (step === STEP_1) {
          <form #f1="ngForm" [formGroup]="formMail" (submit)="onSubmitEmail()">
            <div class="row">
              <div class="col text-center">
                <img class="p-3" style="width: 100px" src="../assets/logo.png" alt="logo" />
                <h5>{{ "common.signup.C'est votre première connexion ?" | translate }}</h5>
                <span class="mt-2">{{ 'common.signup.Créez vite votre compte pour commencer à' | translate }}</span>
                <br />
                <span>{{ 'common.signup.utiliser la plateforme hiji!' | translate }}</span>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <input
                  class="form-control"
                  id="email"
                  type="email"
                  autocomplete="email"
                  [placeholder]="'common.signup.Votre email' | translate"
                  formControlName="email"
                  aria-describedby="emailHelp"
                  />
                </div>
              </div>
              @if (loginError || (f1.submitted && !formMail.valid)) {
                <div class="row mt-4">
                  <div class="col">
                    <ul class="errors">
                      @if (loginError) {
                        <li>{{ loginError }}.</li>
                      }
                      @if (formMail.hasError('required', 'email')) {
                        <li>{{ "common.signup.L'Email est requis" | translate }}</li>
                      }
                      @if (formMail.hasError('email', 'email')) {
                        <li>{{ "common.signup.L'Email est invalide" | translate }}</li>
                      }
                    </ul>
                  </div>
                </div>
              }
              <div class="row mt-4 justify-content-between">
                <div class="col-auto align-items-center d-flex">
                  <a class="link-dark btn-link" type="button" type="reset" routerLink="/login">{{ 'common.signup.Annuler' | translate }}</a>
                </div>
                <div class="col-auto">
                  <button class="btn btn-primary text-uppercase" type="submit" type="submit" [disabled]="loading">
                    {{ 'common.signup.Me connecter' | translate }}
                    @if (loading) {
                      <i class="fa-light fa-spinner spinning"></i>
                    }
                  </button>
                </div>
              </div>
            </form>
          }
          @if (step === STEP_CREATE) {
            <form #f2="ngForm" [formGroup]="formUser" (submit)="onSubmitUser()">
              <div class="row">
                <div class="col text-center">
                  <img class="p-3" style="width: 100px" src="../assets/logo.png" alt="logo" />
                  <h5>{{ "common.signup.C'est votre première connexion ?" | translate }}</h5>
                  <span class="mt-2">{{ 'common.signup.Créez vite votre compte pour commencer à' | translate }}</span>
                  <br />
                  <span>{{ 'common.signup.utiliser la plateforme hiji!' | translate }}</span>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <input
                    class="form-control"
                    id="lastName"
                    type="text"
                    autocomplete="given-name"
                    [placeholder]="'common.signup.Votre nom' | translate"
                    formControlName="lastName"
                    (input)="formUser.value.lastName = formUser.value.lastName.toUpperCase()"
                    aria-describedby="lastNameHelp"
                    />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col">
                    <input
                      class="form-control"
                      id="firstName"
                      type="text"
                      autocomplete="family-name"
                      placeholder="{{ 'common.signup.Votre prénom' | translate }}"
                      formControlName="firstName"
                (input)="
                  formUser.value.firstName =
                    formUser.value === null ? null : formUser.value.firstName.charAt(0).toUpperCase() + formUser.value.firstName.slice(1).toLowerCase()
                "
                      aria-describedby="firstNameHelp"
                      />
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col">
                      <div class="position-relative">
                        <input
                          class="form-control input-with-icon"
                          id="password"
                          type="password"
                          [placeholder]="'common.signup.Votre mot de passe' | translate"
                          autocomplete="new-password"
                          formControlName="password"
                          #p
                          />
                          <div class="input-icon" (click)="p.type === 'password' ? (p.type = 'text') : (p.type = 'password')">
                            <i class="fa-light" [class.fa-eye]="p.type === 'text'" [class.fa-eye-slash]="p.type === 'password'"></i>
                          </div>
                        </div>
                        <div
                          class="my-fs-1"
                          [class.text-danger]="formUser.get('password').touched && !formUser.hasError('required', 'password') && formUser.hasError('pattern', 'password')"
                [class.text-success]="
                  formUser.get('password').touched && !formUser.hasError('required', 'password') && !formUser.hasError('pattern', 'password')
                "
                          >
                          <i class="fa-light fa-info-circle"></i>
                          {{
                          'common.signup.Au minimum 8 caractères avec au moins 3 des 4 critères suivants : 1 chiffre, 1 majuscule, 1 minuscule, un caractère spécial'
                          | translate
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col">
                        <div class="position-relative">
                          <input
                            class="form-control input-with-icon"
                            id="password2"
                            type="password"
                            [placeholder]="'common.signup.Confirmation de votre mot de passe' | translate"
                            autocomplete="new-password"
                            formControlName="confirmPassword"
                            #p2
                            />
                            <div class="input-icon" (click)="p2.type === 'password' ? (p2.type = 'text') : (p2.type = 'password')">
                              <i class="fa-light" [class.fa-eye]="p2.type === 'text'" [class.fa-eye-slash]="p2.type === 'password'"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      @if (loginError || (f2.submitted && !formUser.valid)) {
                        <div class="row mt-4">
                          <div class="col">
                            <ul class="errors">
                              @if (loginError) {
                                <li>{{ loginError }}.</li>
                              }
                              @if (formUser.hasError('required', 'firstName')) {
                                <li>{{ 'common.signup.Le nom est requis' | translate }}</li>
                              }
                              @if (formUser.hasError('required', 'lastName')) {
                                <li>{{ 'common.signup.Le prénom est requis' | translate }}</li>
                              }
                              @if (formUser.hasError('pattern', 'password')) {
                                <li>{{ 'common.signup.Le mot de passe ne respecte pas les contraintes' | translate }}</li>
                              }
                              @if (formUser.hasError('required', 'password')) {
                                <li>{{ 'common.signup.Le mot de passe est requis' | translate }}</li>
                              }
                              @if (formUser.hasError('required', 'confirmPassword')) {
                                <li>
                                  {{ 'common.signup.La confirmation du mot de passe est requise' | translate }}
                                </li>
                              }
                              @if (formUser.hasError('notSame')) {
                                <li>{{ 'common.signup.Les mots de passe ne correspondent pas' | translate }}</li>
                              }
                            </ul>
                          </div>
                        </div>
                      }
                      <div class="row mt-4 justify-content-between">
                        <div class="col-auto align-items-center d-flex">
                          <a class="link-dark btn-link" type="button" type="reset" routerLink="/login">{{ 'common.signup.Annuler' | translate }}</a>
                        </div>
                        <div class="col-auto">
                          <button class="btn btn-primary text-uppercase" type="submit" type="submit" [disabled]="loading">
                            {{ 'common.signup.Me connecter' | translate }}
                            @if (loading) {
                              <i class="fa-light fa-spinner spinning"></i>
                            }
                          </button>
                        </div>
                      </div>
                    </form>
                  }

                  @if (step === STEP_PWD) {
                    <form #f3="ngForm" [formGroup]="formPass" (submit)="onSubmitPwd()">
                      <div class="row">
                        <div class="col text-center">
                          <img class="p-3" style="width: 100px" src="../assets/logo.png" alt="logo" />
                          <h5>{{ 'common.signup.Bienvenue' | translate }} {{ data.firstName }} {{ data.lastName }}</h5>
                          @if (data.fonctionName !== null && data.fonctionName !== '') {
                            <div class="mt-2">{{ data.fonctionName }}</div>
                          }
                          @if (data.etablissementNames.length > 0) {
                            <div class="mt-2">
                              {{ getEtabs() }}
                            </div>
                          }
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col">
                          <div class="position-relative">
                            <input
                              class="form-control input-with-icon"
                              id="password"
                              type="password"
                              [placeholder]="'common.signup.Votre mot de passe' | translate"
                              autocomplete="new-password"
                              formControlName="password"
                              #p
                              />
                              <div class="input-icon" (click)="p.type === 'password' ? (p.type = 'text') : (p.type = 'password')">
                                <i class="fa-light" [class.fa-eye]="p.type === 'text'" [class.fa-eye-slash]="p.type === 'password'"></i>
                              </div>
                            </div>
                            <div
                              class="my-fs-1"
                              [class.text-danger]="formPass.get('password').touched && !formPass.hasError('required', 'password') && formPass.hasError('pattern', 'password')"
                [class.text-success]="
                  formPass.get('password').touched && !formPass.hasError('required', 'password') && !formPass.hasError('pattern', 'password')
                "
                              >
                              <i class="fa-light fa-info-circle"></i>
                              {{
                              'common.signup.Au minimum 8 caractères avec au moins 3 des 4 critères suivants : 1 chiffre, 1 majuscule, 1 minuscule, un caractère spécial'
                              | translate
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="row mt-4">
                          <div class="col">
                            <div class="position-relative">
                              <input
                                class="form-control input-with-icon"
                                id="password2"
                                type="password"
                                [placeholder]="'common.signup.Confirmation de votre mot de passe' | translate"
                                autocomplete="new-password"
                                formControlName="confirmPassword"
                                #p2
                                />
                                <div class="input-icon" (click)="p2.type === 'password' ? (p2.type = 'text') : (p2.type = 'password')">
                                  <i class="fa-light" [class.fa-eye]="p2.type === 'text'" [class.fa-eye-slash]="p2.type === 'password'"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          @if (loginError || (f3.submitted && !formPass.valid)) {
                            <div class="row mt-4">
                              <div class="col">
                                <ul class="errors">
                                  @if (formPass.hasError('minlength', 'password')) {
                                    <li>{{ 'common.signup.Le mot de passe doit faire 6 caractères minimum' | translate }}</li>
                                  }
                                  @if (formPass.hasError('pattern', 'password')) {
                                    <li>{{ 'common.signup.Le mot de passe ne respecte pas les contraintes' | translate }}</li>
                                  }
                                  @if (formPass.hasError('required', 'password')) {
                                    <li>{{ 'common.signup.Le mot de passe est requis' | translate }}</li>
                                  }
                                  @if (formPass.hasError('required', 'confirmPassword')) {
                                    <li>
                                      {{ 'common.signup.La confirmation du mot de passe est requise' | translate }}
                                    </li>
                                  }
                                  @if (formPass.hasError('notSame')) {
                                    <li>{{ 'common.signup.Les mots de passe ne correspondent pas' | translate }}</li>
                                  }
                                </ul>
                              </div>
                            </div>
                          }
                          <div class="row mt-4 justify-content-between">
                            <div class="col-auto align-items-center d-flex">
                              <a class="link-dark btn-link" type="button" type="reset" routerLink="/login">{{ 'common.signup.Annuler' | translate }}</a>
                            </div>
                            <div class="col-auto">
                              <button class="btn btn-primary text-uppercase" type="submit" type="submit" [disabled]="loading">
                                {{ 'common.signup.Me connecter' | translate }}
                                @if (loading) {
                                  <i class="fa-light fa-spinner spinning"></i>
                                }
                              </button>
                            </div>
                          </div>
                        </form>
                      }

                      <div class="row mt-3">
                        <div class="col">
                          {{ 'common.signup.hiji vient de collecter des informations vous concernant, pour en savoir plus' | translate }}
                          <a class="btn-link link-dark" [routerLink]="null" (click)="showPopup()">{{ 'common.signup.cliquez ici' | translate }}</a>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <a class="btn-link link-dark" routerLink="/out/gdpr">{{ 'common.signup.Politique de confidentialité' | translate }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </hiji-wavy-container>
