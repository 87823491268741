import { Injectable, inject } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private ngbModal = inject(NgbModal);


  openModal(type: 'confirm' | 'warning', message: string, onValidate: () => void, onCancel: () => void = () => {}): void {
    const modal: NgbModalRef = this.ngbModal.open(ModalComponent, {
      backdrop: 'static',
      centered: true,
      animation: false,
      fullscreen: 'md',
      modalDialogClass: 'animate__animated animate__bounceIn',
      windowClass: 'overflow-hidden',
    });
    modal.componentInstance.type = type;
    modal.componentInstance.message = message;

    modal.result.then(
      (result) => {
        if (result) {
          onValidate();
        } else onCancel();
      },
      (err) => {
        onCancel();
      }
    );
  }
}
