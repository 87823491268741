// ENTITY fr.hiji.metier.campaign.entities.Campaign generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CampaignType } from './CampaignType';

export class Campaign {

  idCampaign: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  campaignType: CampaignType | null = null;
  startDate: Date | null = null;
  endDate: Date | null = null;
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = 0;
  forceShowDescription: boolean | null = false;
  monthlyEmailRecap: boolean | null = false;
  del: boolean | null = false;

  constructor(init?:Partial<Campaign>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities.Campaign'] = Campaign;

reverseMapping[Campaign.name] = 'fr.hiji.metier.campaign.entities.Campaign';

fields['fr.hiji.metier.campaign.entities.Campaign']  = {
    idCampaign: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    campaignType: 'fr.hiji.metier.campaign.entities.CampaignType',
    startDate: 'java.time.LocalDate',
    endDate: 'java.time.LocalDate',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    forceShowDescription: 'java.lang.Boolean',
    monthlyEmailRecap: 'java.lang.Boolean',
    del: 'java.lang.Boolean'
};
