// ENTITY fr.hiji.metier.conf.dto.back.submodules.ModuleBilanBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleBilanBODTO {

  moduleBilan: boolean | null = false;
  bilanFeaturePopulation: number | null = 0;

  constructor(init?:Partial<ModuleBilanBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.back.submodules.ModuleBilanBODTO'] = ModuleBilanBODTO;

reverseMapping[ModuleBilanBODTO.name] = 'fr.hiji.metier.conf.dto.back.submodules.ModuleBilanBODTO';

fields['fr.hiji.metier.conf.dto.back.submodules.ModuleBilanBODTO']  = {
    moduleBilan: 'java.lang.Boolean',
    bilanFeaturePopulation: 'java.lang.Integer'
};
