import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { ClientEnv } from '../../services/client.env.service';

@Component({
  selector: 'hiji-sso-error',
  templateUrl: './sso-error.component.html',
  styleUrls: ['./sso-error.component.scss'],
  standalone: false,
})
export class SsoErrorComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private oauthService = inject(OAuthService);
  private clientEnv = inject(ClientEnv);

  error; //no_user ou user_deleted voir SSOService
  email;
  static last_error;
  SsoErrorComponent = SsoErrorComponent;

  constructor() {
    const route = this.route;
    const clientEnv = this.clientEnv;

    this.error = route.snapshot.params['error'];
    this.email = route.snapshot.params['email'];
    if (this.error === 'no_user' && clientEnv.isAnyOf(clientEnv.DARTY, clientEnv.FNAC)) {
      this.error = 'fnacdarty_no_user';
    }
  }

  ngOnInit(): void {}

  retry() {
    this.oauthService.logOut();
    localStorage.setItem('forcelogin', 'true');
    this.router.navigate(['/']);
  }
}
