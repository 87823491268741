// ENTITY fr.hiji.metier.conf.dto.back.submodules.ModulePDIBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModulePDIBODTO {

  modulePDI: boolean | null = false;

  constructor(init?:Partial<ModulePDIBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.back.submodules.ModulePDIBODTO'] = ModulePDIBODTO;

reverseMapping[ModulePDIBODTO.name] = 'fr.hiji.metier.conf.dto.back.submodules.ModulePDIBODTO';

fields['fr.hiji.metier.conf.dto.back.submodules.ModulePDIBODTO']  = {
    modulePDI: 'java.lang.Boolean'
};
