import { Component, Input, inject, input } from '@angular/core';
import { MsPipe, MultilingualString } from '@ic2/ic2-lib';

@Component({
    selector: 'hiji-translate-entity',
    templateUrl: './translate-entity.component.html',
    styleUrl: './translate-entity.component.scss',
    standalone: false
})
export class TranslateEntityComponent {
  msPipe = inject(MsPipe);

  @Input()
  name: MultilingualString;
  @Input()
  description: MultilingualString = null;
  @Input()
  sourceLang: string;
  readonly destinationLang = input<string>(undefined);
  readonly textAreaRows = input<number>(5);
  @Input()
  title: string = null;
  readonly alreadyExist = input<boolean>(false);
  readonly alreadyExistTitle = input<string>(undefined);
  @Input()
  icon: string;
  readonly image = input<boolean>(false);
}
