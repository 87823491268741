// ENTITY fr.hiji.metier.conf.dto.back.submodules.ModuleEventBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleEventBODTO {

  moduleEventEnabled: boolean | null = false;
  anonymousEventEnabled: boolean | null = false;

  constructor(init?:Partial<ModuleEventBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.back.submodules.ModuleEventBODTO'] = ModuleEventBODTO;

reverseMapping[ModuleEventBODTO.name] = 'fr.hiji.metier.conf.dto.back.submodules.ModuleEventBODTO';

fields['fr.hiji.metier.conf.dto.back.submodules.ModuleEventBODTO']  = {
    moduleEventEnabled: 'java.lang.Boolean',
    anonymousEventEnabled: 'java.lang.Boolean'
};
