// ENTITY fr.hiji.metier.campaign.entities.survey.SurveyUser generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class SurveyUser {

  idCampaign: number | null = 0;
  idUser: number | null = 0;
  uniqueCode: string | null = '';
  deployed: boolean | null = false;
  deployedDate: Date | null = null;
  launchEmailSent: boolean | null = false;

  constructor(init?:Partial<SurveyUser>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities.survey.SurveyUser'] = SurveyUser;

reverseMapping[SurveyUser.name] = 'fr.hiji.metier.campaign.entities.survey.SurveyUser';

fields['fr.hiji.metier.campaign.entities.survey.SurveyUser']  = {
    idCampaign: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    uniqueCode: 'java.lang.String',
    deployed: 'java.lang.Boolean',
    deployedDate: 'java.time.Instant',
    launchEmailSent: 'java.lang.Boolean'
};
