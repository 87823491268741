// ENTITY fr.hiji.metier.mif.dto.back.event.FormationEventBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { RepositoryObservableGestureBODTO } from './RepositoryObservableGestureBODTO';
import { FormationEventDiffusionListBODTO } from './FormationEventDiffusionListBODTO';
import { FormationEventTag } from './FormationEventTag';
import { FormationEvent } from './FormationEvent';
import { FormationEventAnimationBODTO } from './FormationEventAnimationBODTO';
import { FormationEventParticipantBODTO } from './FormationEventParticipantBODTO';
import { Criteria } from './Criteria';

export class FormationEventBODTO {

  formationEvent: FormationEvent | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  repositoryGlobalSatisfactionName: MultilingualString | null = new MultilingualString();
  repositoryGlobalSatisfactionDel: boolean | null = false;
  repositoryDefaultAnimationSatisfactionName: MultilingualString | null = new MultilingualString();
  repositoryDefaultAnimationSatisfactionDel: boolean | null = false;
  repositoryAcquisitionName: MultilingualString | null = new MultilingualString();
  repositoryAcquisitionDel: boolean | null = false;
  animations: FormationEventAnimationBODTO[] | null = [];
  participants: FormationEventParticipantBODTO[] | null = [];
  tags: FormationEventTag[] | null = [];
  ogsRepoDefaultAnimationSatisfaction: RepositoryObservableGestureBODTO[] | null = [];
  ogsOld: RepositoryObservableGestureBODTO[] | null = [];
  diffusionLists: FormationEventDiffusionListBODTO[] | null = [];
  criterias: Criteria[] | null = [];

  constructor(init?:Partial<FormationEventBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.event.FormationEventBODTO'] = FormationEventBODTO;

reverseMapping[FormationEventBODTO.name] = 'fr.hiji.metier.mif.dto.back.event.FormationEventBODTO';

fields['fr.hiji.metier.mif.dto.back.event.FormationEventBODTO']  = {
    formationEvent: 'fr.hiji.metier.mif.entities.event.FormationEvent',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    repositoryGlobalSatisfactionName: 'com.ic2.sc.MultilingualString',
    repositoryGlobalSatisfactionDel: 'java.lang.Boolean',
    repositoryDefaultAnimationSatisfactionName: 'com.ic2.sc.MultilingualString',
    repositoryDefaultAnimationSatisfactionDel: 'java.lang.Boolean',
    repositoryAcquisitionName: 'com.ic2.sc.MultilingualString',
    repositoryAcquisitionDel: 'java.lang.Boolean',
    animations: 'java.util.List<fr.hiji.metier.mif.dto.back.event.FormationEventAnimationBODTO>',
    participants: 'java.util.List<fr.hiji.metier.mif.dto.back.event.FormationEventParticipantBODTO>',
    tags: 'java.util.List<fr.hiji.metier.mif.entities.event.FormationEventTag>',
    ogsRepoDefaultAnimationSatisfaction: 'java.util.List<fr.hiji.metier.referential.dto.RepositoryObservableGestureBODTO>',
    ogsOld: 'java.util.List<fr.hiji.metier.referential.dto.RepositoryObservableGestureBODTO>',
    diffusionLists: 'java.util.List<fr.hiji.metier.mif.dto.back.event.FormationEventDiffusionListBODTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>'
};
