// ENTITY fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathFilterItemBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class TrainingPathFilterItemBODTO {

  idTrainingPath: number | null = 0;
  trainingPathName: MultilingualString | null = new MultilingualString();
  idFormations: number[] | null = [];
  idCertifications: number[] | null = [];

  constructor(init?:Partial<TrainingPathFilterItemBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathFilterItemBODTO'] = TrainingPathFilterItemBODTO;

reverseMapping[TrainingPathFilterItemBODTO.name] = 'fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathFilterItemBODTO';

fields['fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathFilterItemBODTO']  = {
    idTrainingPath: 'java.lang.Integer',
    trainingPathName: 'com.ic2.sc.MultilingualString',
    idFormations: 'java.util.List<java.lang.Integer>',
    idCertifications: 'java.util.List<java.lang.Integer>'
};
