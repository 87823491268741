// ENTITY fr.hiji.metier.mif.dto.front.trainer.ParticipantAttendanceDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { FormationSessionParticipantAttendance } from './FormationSessionParticipantAttendance';

export class ParticipantAttendanceDTO {

  user: UserFuncAvatarDTO | null = null;
  attendances: FormationSessionParticipantAttendance[] | null = [];

  constructor(init?:Partial<ParticipantAttendanceDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.trainer.ParticipantAttendanceDTO'] = ParticipantAttendanceDTO;

reverseMapping[ParticipantAttendanceDTO.name] = 'fr.hiji.metier.mif.dto.front.trainer.ParticipantAttendanceDTO';

fields['fr.hiji.metier.mif.dto.front.trainer.ParticipantAttendanceDTO']  = {
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    attendances: 'java.util.List<fr.hiji.metier.mif.entities.session.FormationSessionParticipantAttendance>'
};
