// ENTITY fr.hiji.metier.conf.dto.front.submodules.BackConfigurationDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class BackConfigurationDTO {

  hideConnexionDateToGestionnaires: boolean | null = false;
  userImportEnabled: boolean | null = false;
  emailUserImportEnabled: boolean | null = false;
  lemonProjectKey: string | null = '';
  allowedToEditEmailSender: boolean | null = false;

  constructor(init?:Partial<BackConfigurationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.front.submodules.BackConfigurationDTO'] = BackConfigurationDTO;

reverseMapping[BackConfigurationDTO.name] = 'fr.hiji.metier.conf.dto.front.submodules.BackConfigurationDTO';

fields['fr.hiji.metier.conf.dto.front.submodules.BackConfigurationDTO']  = {
    hideConnexionDateToGestionnaires: 'java.lang.Boolean',
    userImportEnabled: 'java.lang.Boolean',
    emailUserImportEnabled: 'java.lang.Boolean',
    lemonProjectKey: 'java.lang.String',
    allowedToEditEmailSender: 'java.lang.Boolean'
};
