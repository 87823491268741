// fr.hiji.metier.ema.services.front.CoachingActionServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { EMACoachingActionDTO } from '../entities/EMACoachingActionDTO';
import { EMACoachingAction } from '../entities/EMACoachingAction';

@Injectable({
  providedIn: 'root',
})
export class CoachingActionService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getCurrentCoachingActionsForUser(idUser: number): RpcRequestBuilder<EMACoachingActionDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CoachingActionService.getCurrentCoachingActionsForUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  toggleDone(idEMACoachingAction: number): RpcRequestBuilder<EMACoachingAction> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CoachingActionService.toggleDone';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idEMACoachingAction, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.entities.EMACoachingAction');
  }

}
