// ENTITY fr.hiji.metier.ema.entities.template.EMAPredefinedCoachingAction generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class EMAPredefinedCoachingAction {

  idEMAPredefinedCoachingAction: number | null = 0;
  action: MultilingualString | null = new MultilingualString();
  del: boolean | null = false;

  constructor(init?:Partial<EMAPredefinedCoachingAction>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.template.EMAPredefinedCoachingAction'] = EMAPredefinedCoachingAction;

reverseMapping[EMAPredefinedCoachingAction.name] = 'fr.hiji.metier.ema.entities.template.EMAPredefinedCoachingAction';

fields['fr.hiji.metier.ema.entities.template.EMAPredefinedCoachingAction']  = {
    idEMAPredefinedCoachingAction: 'java.lang.Integer',
    action: 'com.ic2.sc.MultilingualString',
    del: 'java.lang.Boolean'
};
