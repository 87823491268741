// ENTITY fr.hiji.metier.campaign.entities.ObservationObservableGestures generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ObservationObservableGestures {

  idObservation: number | null = 0;
  idObservableGesture: number | null = 0;
  value: number | null = 0;
  observed: boolean | null = false;
  answerDate: Date | null = null;
  text: string | null = '';
  categorized: boolean | null = false;

  constructor(init?:Partial<ObservationObservableGestures>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities.ObservationObservableGestures'] = ObservationObservableGestures;

reverseMapping[ObservationObservableGestures.name] = 'fr.hiji.metier.campaign.entities.ObservationObservableGestures';

fields['fr.hiji.metier.campaign.entities.ObservationObservableGestures']  = {
    idObservation: 'java.lang.Integer',
    idObservableGesture: 'java.lang.Integer',
    value: 'java.lang.Integer',
    observed: 'java.lang.Boolean',
    answerDate: 'java.time.Instant',
    text: 'java.lang.String',
    categorized: 'java.lang.Boolean'
};
