// ENTITY fr.hiji.metier.user.dto.back.SurveyLiteBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class SurveyLiteBODTO {

  idCampaign: number | null = 0;
  name: MultilingualString | null = new MultilingualString();

  constructor(init?:Partial<SurveyLiteBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.back.SurveyLiteBODTO'] = SurveyLiteBODTO;

reverseMapping[SurveyLiteBODTO.name] = 'fr.hiji.metier.user.dto.back.SurveyLiteBODTO';

fields['fr.hiji.metier.user.dto.back.SurveyLiteBODTO']  = {
    idCampaign: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString'
};
