// ENTITY fr.hiji.metier.teamboard.entities.UserSawTeamBoard generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class UserSawTeamBoard {

  idTeamBoard: number | null = 0;
  idUser: number | null = 0;
  date: Date | null = null;

  constructor(init?:Partial<UserSawTeamBoard>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.teamboard.entities.UserSawTeamBoard'] = UserSawTeamBoard;

reverseMapping[UserSawTeamBoard.name] = 'fr.hiji.metier.teamboard.entities.UserSawTeamBoard';

fields['fr.hiji.metier.teamboard.entities.UserSawTeamBoard']  = {
    idTeamBoard: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    date: 'java.time.Instant'
};
