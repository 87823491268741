import { Component, OnInit, inject } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
    selector: 'hiji-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    standalone: false
})
export class LogoutComponent implements OnInit {
  private oauthService = inject(OAuthService);


  ngOnInit(): void {
    this.oauthService.logOut(true);
  }
}
