// ENTITY fr.hiji.metier.page.dto.back.StaticPageBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { StaticPage } from './StaticPage';

export class StaticPageBODTO {

  staticPage: StaticPage | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';

  constructor(init?:Partial<StaticPageBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.page.dto.back.StaticPageBODTO'] = StaticPageBODTO;

reverseMapping[StaticPageBODTO.name] = 'fr.hiji.metier.page.dto.back.StaticPageBODTO';

fields['fr.hiji.metier.page.dto.back.StaticPageBODTO']  = {
    staticPage: 'fr.hiji.metier.page.entities.StaticPage',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String'
};
