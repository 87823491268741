// ENTITY fr.hiji.metier.translate.entities.LangueUpdatedChangedDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class LangueUpdatedChangedDTO {

  langue: string | null = '';
  nbUpdated: number | null = 0;
  nbNotTranslated: number | null = 0;
  nbUnchanged: number | null = 0;

  constructor(init?:Partial<LangueUpdatedChangedDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.translate.entities.LangueUpdatedChangedDTO'] = LangueUpdatedChangedDTO;

reverseMapping[LangueUpdatedChangedDTO.name] = 'fr.hiji.metier.translate.entities.LangueUpdatedChangedDTO';

fields['fr.hiji.metier.translate.entities.LangueUpdatedChangedDTO']  = {
    langue: 'java.lang.String',
    nbUpdated: 'java.lang.Integer',
    nbNotTranslated: 'java.lang.Integer',
    nbUnchanged: 'java.lang.Integer'
};
