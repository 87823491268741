// DB ENUM fr.hiji.metier.mif.entities.session.FormationSessionQuizResultMode generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:59 UTC 2025

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class FormationSessionQuizResultMode {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly NO_RESULT = new FormationSessionQuizResultMode(1, "NO_RESULT", "NO_RESULT");
  static readonly SCORE = new FormationSessionQuizResultMode(2, "SCORE", "SCORE");
  static readonly SCORE_ANSWERS = new FormationSessionQuizResultMode(3, "SCORE_ANSWERS", "SCORE_ANSWERS");
  static readonly SCORE_ANSWERS_EXPLANATION = new FormationSessionQuizResultMode(4, "SCORE_ANSWERS_EXPLANATION", "SCORE_ANSWERS_EXPLANATION");

  static readonly values = [
    FormationSessionQuizResultMode.NO_RESULT,
 
    FormationSessionQuizResultMode.SCORE,
 
    FormationSessionQuizResultMode.SCORE_ANSWERS,
 
    FormationSessionQuizResultMode.SCORE_ANSWERS_EXPLANATION
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of FormationSessionQuizResultMode.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of FormationSessionQuizResultMode.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.mif.entities.session.FormationSessionQuizResultMode'] = FormationSessionQuizResultMode;
reverseMapping[FormationSessionQuizResultMode.name] = 'fr.hiji.metier.mif.entities.session.FormationSessionQuizResultMode';
