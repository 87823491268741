// DB ENUM fr.hiji.metier.ema.dto.common.EMASkillMapPopulationType generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:59 UTC 2025

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class EMASkillMapPopulationType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly USER = new EMASkillMapPopulationType(1, "Utilisateur", "USER");
  static readonly MANAGER_TEAM = new EMASkillMapPopulationType(2, "Équipe", "MANAGER_TEAM");
  static readonly COACHEES = new EMASkillMapPopulationType(3, "Coachés", "COACHEES");
  static readonly GLOBAL = new EMASkillMapPopulationType(4, "Global", "GLOBAL");

  static readonly values = [
    EMASkillMapPopulationType.USER,
 
    EMASkillMapPopulationType.MANAGER_TEAM,
 
    EMASkillMapPopulationType.COACHEES,
 
    EMASkillMapPopulationType.GLOBAL
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of EMASkillMapPopulationType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of EMASkillMapPopulationType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.ema.dto.common.EMASkillMapPopulationType'] = EMASkillMapPopulationType;
reverseMapping[EMASkillMapPopulationType.name] = 'fr.hiji.metier.ema.dto.common.EMASkillMapPopulationType';
