<div class="d-flex align-items-center" style="min-height: 100vh !important">
  <div class="container bg-white rounded shadow-2 py-3 m-3 mx-sm-auto" style="z-index: 10">
    <!--<div style="width: 23rem"></div>-->

    <!--logo-->
    <div class="row justify-content-center">
      <div class="col-auto">
        <img style="width: 75px" src="assets/logo.png" />
      </div>
    </div>

    <!--form-->
    <div class="row mt-3">
      <div class="col-12 col-md-6 line">
        <form #f="ngForm" [formGroup]="form" (ngSubmit)="submit()">
          <div class="container-fluid">
            <div class="row mt-4">
              <div class="col-12 text-center justify-content-center">
                <div class="text-left">
                  <h3>{{ 'common.login.Bienvenue dans votre espace' | translate }}</h3>
                </div>
                <div class="text-left mt-2">{{ 'common.login.Connectez vous pour accéder à toutes vos données' | translate }}</div>
              </div>
            </div>

            @if (loadingSSOConfigs) {
              <div class="row my-4 justify-content-center">
                <div class="col-12 text-center">
                  <i class="fa-light fa-spinner spinning"></i>
                </div>
              </div>
            }

            @if (ssoConfigs.length === 0 && !localAccountLoginEnabled && !loadingSSOConfigs) {
              <div class="row my-4 justify-content-center">
                <div class="col-12">
                  <div class="alert alert-danger text-center">
                    {{ "common.login.Aucune méthode de connexion n'est disponible" | translate }}
                  </div>
                </div>
              </div>
            }

            @if (ssoConfigs.length > 0 && !loadingSSOConfigs) {
              <div class="row my-4 justify-content-center">
                @for (sso of ssoConfigs; track sso; let first = $first) {
                  <div class="col-12 text-center" [class.mt-3]="!first">
                    @if (!sso.hideOnLoginPage) {
                      <a class="btn btn-primary text-uppercase" [routerLink]="['/sso', sso.id]">
                        {{ 'common.login.Connexion avec SSO \{\{ ssoName \}\}' | translate: { ssoName: sso.name } }}
                      </a>
                    }
                  </div>
                }
              </div>
            }

            @if (localAccountLoginEnabled) {
              <div class="row mt-4 justify-content-center">
                <div class="col-12 col-md-8">
                  <div class="form-group" style="margin-bottom: 2rem">
                    <input class="form-control" type="text" [placeholder]="'common.login.Login' | translate" formControlName="login" autocomplete="username" />
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-12 col-md-8">
                  <div class="form-group position-relative">
                    <input
                      class="form-control input-with-icon"
                      type="password"
                      autocomplete="current-password"
                      [placeholder]="'common.login.Mot de passe' | translate"
                      formControlName="password"
                      #p
                      />
                      <div class="input-icon" (click)="p.type === 'password' ? (p.type = 'text') : (p.type = 'password')">
                        <i class="fa-light" [class.fa-eye]="p.type === 'text'" [class.fa-eye-slash]="p.type === 'password'"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-1 justify-content-center">
                  <div class="col-12 col-md-8 text-end">
                    <a class="fw-400 my-fs-2 pointer" [routerLink]="config.configuration.resetPasswordViaPinCode ? '/reset-password-pincode' : '/reset-password'">
                      {{ 'common.login.Mot de passe oublié' | translate }}
                    </a>
                  </div>
                </div>
                <!--Errors handling-->
                @if (f.submitted && (!form.valid || error)) {
                  <div class="row justify-content-center mt-4">
                    <div class="col-12 col-md-8">
                      <ul class="errors">
                        @if (form.hasError('required', 'login')) {
                          <li>{{ 'common.login.Le login est requis' | translate }}</li>
                        }
                        @if (form.hasError('required', 'password')) {
                          <li>{{ 'common.login.Le mot de passe est requis' | translate }}</li>
                        }
                        @if (form.hasError('minlength', 'password')) {
                          <li>{{ 'common.login.Le mot de passe est trop court' | translate }}</li>
                        }
                        @if (error) {
                          <li>{{ error }}</li>
                        }
                      </ul>
                    </div>
                  </div>
                }
                <div class="row mt-4">
                  <div class="col text-center">
                    <button class="btn btn-primary text-uppercase" type="submit" [disabled]="loading">
                      {{ 'common.login.Me connecter' | translate }}
                      @if (loading) {
                        <i class="fa-light fa-spinner spinning"></i>
                      }
                    </button>
                  </div>
                </div>
              }
            </div>
          </form>

          <div class="row mt-3 justify-content-center d-md-none">
            <div class="col-9">
              <hr />
            </div>
          </div>
        </div>

        <!-- Première connexion-->
        <div class="col-12 col-md-6">
          <div class="container-fluid">
            <div class="row mt-4 text-center justify-content-center">
              <div class="col-auto">
                <div>
                  <h3>{{ "common.login.C'est votre première connexion ?" | translate }}</h3>
                </div>
                <div class="mt-2">{{ 'common.login.Créez vite votre compte pour commencer à utiliser la plateforme !' | translate }}</div>
              </div>
            </div>
            @if (signupEnabled) {
              <div class="row text-center justify-content-center-5 mt-4">
                <div class="col">
                  <a class="btn btn-primary text-uppercase" routerLink="/signup">{{ 'common.login.Créer mon compte' | translate }}</a>
                </div>
              </div>
            }
            @if (!signupEnabled) {
              <div class="row text-center justify-content-center mt-4">
                <div class="col-auto">
                  <div class="alert alert-warning text-center">
                    {{ 'common.login.Création de compte désactivée' | translate }}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <!--bottom-wave-->
    </div>
  </div>
  <ic2-toastr-container class="position-fixed bottom-0 start-50 translate-middle-x"></ic2-toastr-container>
