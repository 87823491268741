// ENTITY fr.hiji.metier.campaign.dto.front.pdi.PDIObservationDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ObservationType } from './ObservationType';
import { ObservationObservableGesturesDTO } from './ObservationObservableGesturesDTO';
import { Criteria } from './Criteria';
import { RepositoryAccessDTO } from './RepositoryAccessDTO';

export class PDIObservationDTO {

  idCampaign: number | null = 0;
  campaignName: MultilingualString | null = new MultilingualString();
  campaignDescription: MultilingualString | null = new MultilingualString();
  repositorie: RepositoryAccessDTO | null = null;
  endDate: Date | null = null;
  startDate: Date | null = null;
  draft: boolean | null = false;
  idObservation: number | null = 0;
  criterias: Criteria[] | null = [];
  observableGestures: ObservationObservableGesturesDTO[] | null = [];
  repositoryName: MultilingualString | null = new MultilingualString();
  repositoryDescription: MultilingualString | null = new MultilingualString();
  startAnsweringDate: Date | null = null;
  finishAnswering: boolean | null = false;
  type: ObservationType | null = null;
  forceShowDescription: boolean | null = false;

  constructor(init?:Partial<PDIObservationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.pdi.PDIObservationDTO'] = PDIObservationDTO;

reverseMapping[PDIObservationDTO.name] = 'fr.hiji.metier.campaign.dto.front.pdi.PDIObservationDTO';

fields['fr.hiji.metier.campaign.dto.front.pdi.PDIObservationDTO']  = {
    idCampaign: 'java.lang.Integer',
    campaignName: 'com.ic2.sc.MultilingualString',
    campaignDescription: 'com.ic2.sc.MultilingualString',
    repositorie: 'fr.hiji.metier.campaign.dto.front.RepositoryAccessDTO',
    endDate: 'java.time.LocalDate',
    startDate: 'java.time.LocalDate',
    draft: 'java.lang.Boolean',
    idObservation: 'java.lang.Integer',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    observableGestures: 'java.util.List<fr.hiji.metier.campaign.dto.front.ObservationObservableGesturesDTO>',
    repositoryName: 'com.ic2.sc.MultilingualString',
    repositoryDescription: 'com.ic2.sc.MultilingualString',
    startAnsweringDate: 'java.time.Instant',
    finishAnswering: 'java.lang.Boolean',
    type: 'fr.hiji.metier.campaign.entities.ObservationType',
    forceShowDescription: 'java.lang.Boolean'
};
