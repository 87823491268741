import { Component, inject, input, output } from '@angular/core';
import { L } from '@ic2/ic2-lib';
import { HijiFileDTO } from '../../ic2/entities/HijiFileDTO';
import { HijiFileService } from '../../ic2/services/HijiFileService';
@Component({
  selector: 'hiji-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  standalone: false,
})
export class FileUploadComponent {
  private hijiFileService = inject(HijiFileService);

  readonly uploaded = output<HijiFileDTO>();
  readonly progress = output<number>();
  readonly btnSize = input<string>('');
  readonly private = input<boolean>(true);
  file: File;
  loadImport: boolean = false;
  errorAppear: boolean = false;
  progressValue: number = 100;

  handleFileInput(event) {
    this.progressValue = 0;
    this.errorAppear = false;
    this.loadImport = true;
    const files: FileList = event.target.files;
    this.file = files[0];
    this.hijiFileService
      .upload(this.private())
      .withFile(this.file)
      .withUploadProgress((loaded, total) => {
        this.progressValue = (loaded / total) * 100;
        this.progress.emit((loaded / total) * 100);
      })
      .onError((err) => {
        L.e(err);
        this.errorAppear = true;
        this.loadImport = false;
      })
      .execute((data) => {
        this.uploaded.emit(data);
        this.loadImport = false;
      });
  }
}
