<div class="align-items-center d-flex flex-column justify-content-center" style="min-height: 100vh !important">
  <h1 class="mb-5 animate__animated animate__slow animate__tada animate__infinite">{{ 'common.update.Mise à jour disponible' | translate }}</h1>
  <button class="btn btn-primary" [disabled]="loading" (click)="update()">
    {{ "common.update.Mettre à jour l'appli" | translate }}
    @if (loading) {
      <i class="fa-light fa-spinner spinning ms-2"></i>
    }
  </button>
  <!--<div class="my-fs-1 mt-5">Version : {{ version.hash }} {{ version.date }}</div>-->
</div>
