@if (!hasAnyTagsToShow()) {
  <div>
    {{ 'common.radar.Pas de compétences associées' | translate }}
  </div>
}
@if (hasAnyTagsToShow()) {
  <div class="position-relative">
    @if (this.showChildrenOf !== null) {
      <button
        class="btn btn-sm btn-primary position-absolute"
        ngbTooltip="{{ 'common.radar.Réinitialiser' | translate }}"
        (click)="resetShowChildren()"
        >
        <i class="fa-light fa-refresh"></i>
      </button>
    }
    @if (this.showChildrenOf !== null) {
      <h3 class="text-center pt-2" [ngStyle]="{ color: getCurrentTag().colorCode }">{{ getCurrentTag().name | ms }}</h3>
    }
    <hiji-download-graph [imgName]="'radar'">
      <hiji-radar-chart
        [data]="reworkedData"
        [criteriaType]="criteriaType()"
        (titleClick)="radarTitleClick($event)"
        [canBeExpanded]="canBeExpanded.bind(this)"
      ></hiji-radar-chart>
    </hiji-download-graph>
  </div>
}
