// ENTITY fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFQuizStatDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { OrangeMIFQuizTagStatDTO } from './OrangeMIFQuizTagStatDTO';

export class OrangeMIFQuizStatDTO {

  name: MultilingualString | null = new MultilingualString();
  score: number | null = null;
  total: number | null = null;
  tags: OrangeMIFQuizTagStatDTO[] | null = [];

  constructor(init?:Partial<OrangeMIFQuizStatDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFQuizStatDTO'] = OrangeMIFQuizStatDTO;

reverseMapping[OrangeMIFQuizStatDTO.name] = 'fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFQuizStatDTO';

fields['fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFQuizStatDTO']  = {
    name: 'com.ic2.sc.MultilingualString',
    score: 'java.lang.Double',
    total: 'java.lang.Double',
    tags: 'java.util.List<fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFQuizTagStatDTO>'
};
