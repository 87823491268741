// ENTITY fr.hiji.metier.certif.dto.back.stats.overview.RecapCertifPerOrganisationDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { OrganisationType } from './OrganisationType';

export class RecapCertifPerOrganisationDTO {

  idOrganisation: number | null = 0;
  type: OrganisationType | null = null;
  organisationName: string | null = '';
  idCertification: number | null = 0;
  certificationName: MultilingualString | null = new MultilingualString();
  imageExtension: string | null = '';
  nbSessions: number | null = 0;
  nbCandidats: number | null = 0;
  nbCertified: number | null = 0;
  nbAssessors: number | null = 0;
  nbModalities: number | null = 0;

  constructor(init?:Partial<RecapCertifPerOrganisationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.overview.RecapCertifPerOrganisationDTO'] = RecapCertifPerOrganisationDTO;

reverseMapping[RecapCertifPerOrganisationDTO.name] = 'fr.hiji.metier.certif.dto.back.stats.overview.RecapCertifPerOrganisationDTO';

fields['fr.hiji.metier.certif.dto.back.stats.overview.RecapCertifPerOrganisationDTO']  = {
    idOrganisation: 'java.lang.Integer',
    type: 'fr.hiji.metier.reseau.entities.OrganisationType',
    organisationName: 'java.lang.String',
    idCertification: 'java.lang.Integer',
    certificationName: 'com.ic2.sc.MultilingualString',
    imageExtension: 'java.lang.String',
    nbSessions: 'java.lang.Integer',
    nbCandidats: 'java.lang.Integer',
    nbCertified: 'java.lang.Integer',
    nbAssessors: 'java.lang.Integer',
    nbModalities: 'java.lang.Integer'
};
