// DB ENUM fr.hiji.metier.reseau.entities.OrganisationType generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:59 UTC 2025

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class OrganisationType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly ENSEIGNE = new OrganisationType(1, "Enseigne", "ENSEIGNE");
  static readonly SOCIETE = new OrganisationType(2, "Société", "SOCIETE");
  static readonly PAYS = new OrganisationType(3, "Pays", "PAYS");
  static readonly REGION = new OrganisationType(4, "Région", "REGION");
  static readonly ETABLISSEMENT = new OrganisationType(5, "Établissement", "ETABLISSEMENT");
  static readonly DIRECTION = new OrganisationType(6, "Direction", "DIRECTION");
  static readonly SERVICE = new OrganisationType(7, "Service", "SERVICE");
  static readonly AUTRE = new OrganisationType(8, "Autre", "AUTRE");

  static readonly values = [
    OrganisationType.ENSEIGNE,
 
    OrganisationType.SOCIETE,
 
    OrganisationType.PAYS,
 
    OrganisationType.REGION,
 
    OrganisationType.ETABLISSEMENT,
 
    OrganisationType.DIRECTION,
 
    OrganisationType.SERVICE,
 
    OrganisationType.AUTRE
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of OrganisationType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of OrganisationType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.reseau.entities.OrganisationType'] = OrganisationType;
reverseMapping[OrganisationType.name] = 'fr.hiji.metier.reseau.entities.OrganisationType';
