// ENTITY fr.hiji.metier.conf.dto.back.submodules.ModuleFBSBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleFBSBODTO {

  moduleFBS: boolean | null = false;
  fbsFeaturePopulation: number | null = 0;
  fbsSendLimitedToAroundMe: boolean | null = false;
  fbsReceivedNotifMailEnabled: boolean | null = false;
  fbsRecapMailEnabled: boolean | null = false;
  displayFBSHomeButton: boolean | null = false;
  fbsSolicitationMailEnabled: boolean | null = false;

  constructor(init?:Partial<ModuleFBSBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.back.submodules.ModuleFBSBODTO'] = ModuleFBSBODTO;

reverseMapping[ModuleFBSBODTO.name] = 'fr.hiji.metier.conf.dto.back.submodules.ModuleFBSBODTO';

fields['fr.hiji.metier.conf.dto.back.submodules.ModuleFBSBODTO']  = {
    moduleFBS: 'java.lang.Boolean',
    fbsFeaturePopulation: 'java.lang.Integer',
    fbsSendLimitedToAroundMe: 'java.lang.Boolean',
    fbsReceivedNotifMailEnabled: 'java.lang.Boolean',
    fbsRecapMailEnabled: 'java.lang.Boolean',
    displayFBSHomeButton: 'java.lang.Boolean',
    fbsSolicitationMailEnabled: 'java.lang.Boolean'
};
