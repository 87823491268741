// ENTITY fr.hiji.metier.certif.dto.back.stats.candidates.CertificationCandidateExamResultBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ModalityType } from './ModalityType';

export class CertificationCandidateExamResultBODTO {

  idModality: number | null = 0;
  type: ModalityType | null = null;
  name: MultilingualString | null = new MultilingualString();
  score: number | null = 0;
  done: boolean | null = false;
  success: boolean | null = false;

  constructor(init?:Partial<CertificationCandidateExamResultBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.candidates.CertificationCandidateExamResultBODTO'] = CertificationCandidateExamResultBODTO;

reverseMapping[CertificationCandidateExamResultBODTO.name] = 'fr.hiji.metier.certif.dto.back.stats.candidates.CertificationCandidateExamResultBODTO';

fields['fr.hiji.metier.certif.dto.back.stats.candidates.CertificationCandidateExamResultBODTO']  = {
    idModality: 'java.lang.Integer',
    type: 'fr.hiji.metier.certif.entities.modality.ModalityType',
    name: 'com.ic2.sc.MultilingualString',
    score: 'java.lang.Double',
    done: 'java.lang.Boolean',
    success: 'java.lang.Boolean'
};
