// ENTITY fr.hiji.metier.certif.entities.session.CertificationSessionModality generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class CertificationSessionModality {

  idCertificationSession: number | null = 0;
  idModality: number | null = 0;
  order: number | null = 0;

  constructor(init?:Partial<CertificationSessionModality>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.session.CertificationSessionModality'] = CertificationSessionModality;

reverseMapping[CertificationSessionModality.name] = 'fr.hiji.metier.certif.entities.session.CertificationSessionModality';

fields['fr.hiji.metier.certif.entities.session.CertificationSessionModality']  = {
    idCertificationSession: 'java.lang.Integer',
    idModality: 'java.lang.Integer',
    order: 'java.lang.Integer'
};
