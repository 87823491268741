// ENTITY fr.hiji.metier.mif.entities.quiz.FormationQuizQCMAnswer generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationQuizQCMAnswer {

  idFormationQuizAnswer: number | null = 0;
  idQuestion: number | null = 0;
  numAnswer: number | null = 0;
  date: Date | null = null;

  constructor(init?:Partial<FormationQuizQCMAnswer>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.quiz.FormationQuizQCMAnswer'] = FormationQuizQCMAnswer;

reverseMapping[FormationQuizQCMAnswer.name] = 'fr.hiji.metier.mif.entities.quiz.FormationQuizQCMAnswer';

fields['fr.hiji.metier.mif.entities.quiz.FormationQuizQCMAnswer']  = {
    idFormationQuizAnswer: 'java.lang.Integer',
    idQuestion: 'java.lang.Integer',
    numAnswer: 'java.lang.Integer',
    date: 'java.time.Instant'
};
