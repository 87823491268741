// ENTITY fr.hiji.metier.opinion.dto.back.OpinionSuggestionBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { OpinionSuggestion } from './OpinionSuggestion';

export class OpinionSuggestionBODTO {

  opinionSuggestion: OpinionSuggestion | null = null;
  firstName: string | null = '';
  lastName: string | null = '';
  fonction: string | null = '';
  avatarExtension: string | null = '';
  email: string | null = '';
  del: boolean | null = false;

  constructor(init?:Partial<OpinionSuggestionBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.opinion.dto.back.OpinionSuggestionBODTO'] = OpinionSuggestionBODTO;

reverseMapping[OpinionSuggestionBODTO.name] = 'fr.hiji.metier.opinion.dto.back.OpinionSuggestionBODTO';

fields['fr.hiji.metier.opinion.dto.back.OpinionSuggestionBODTO']  = {
    opinionSuggestion: 'fr.hiji.metier.opinion.entities.OpinionSuggestion',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    fonction: 'java.lang.String',
    avatarExtension: 'java.lang.String',
    email: 'java.lang.String',
    del: 'java.lang.Boolean'
};
