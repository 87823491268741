// ENTITY fr.hiji.metier.conf.dto.front.submodules.ModuleMIFDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleMIFDTO {

  enabled: boolean | null = false;
  idCriteriaAction: number | null = 0;
  idCriteriaExpectation: number | null = 0;
  idCriteriaBusinessExpectation: number | null = 0;
  trainer: boolean | null = false;
  hideQualiopiMIF: boolean | null = false;
  hideQuizMIF: boolean | null = false;
  defaultMIFEnabledOptions: object | null = null;
  addPopulationToSessionEnabled: boolean | null = false;
  activationEnabled: boolean | null = false;
  expectationsManagerEnabled: boolean | null = false;
  attendanceEnabled: boolean | null = false;

  constructor(init?:Partial<ModuleMIFDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.front.submodules.ModuleMIFDTO'] = ModuleMIFDTO;

reverseMapping[ModuleMIFDTO.name] = 'fr.hiji.metier.conf.dto.front.submodules.ModuleMIFDTO';

fields['fr.hiji.metier.conf.dto.front.submodules.ModuleMIFDTO']  = {
    enabled: 'java.lang.Boolean',
    idCriteriaAction: 'java.lang.Integer',
    idCriteriaExpectation: 'java.lang.Integer',
    idCriteriaBusinessExpectation: 'java.lang.Integer',
    trainer: 'java.lang.Boolean',
    hideQualiopiMIF: 'java.lang.Boolean',
    hideQuizMIF: 'java.lang.Boolean',
    defaultMIFEnabledOptions: 'com.ic2.json.JSONObject',
    addPopulationToSessionEnabled: 'java.lang.Boolean',
    activationEnabled: 'java.lang.Boolean',
    expectationsManagerEnabled: 'java.lang.Boolean',
    attendanceEnabled: 'java.lang.Boolean'
};
