// ENTITY fr.hiji.metier.ema.dto.front.EMASkillMessageWithUserDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { EMASkillMessage } from './EMASkillMessage';

export class EMASkillMessageWithUserDTO {

  emaSkillMessage: EMASkillMessage | null = null;
  tagName: MultilingualString | null = new MultilingualString();
  user: UserFuncAvatarDTO | null = null;
  creationDate: Date | null = null;

  constructor(init?:Partial<EMASkillMessageWithUserDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMASkillMessageWithUserDTO'] = EMASkillMessageWithUserDTO;

reverseMapping[EMASkillMessageWithUserDTO.name] = 'fr.hiji.metier.ema.dto.front.EMASkillMessageWithUserDTO';

fields['fr.hiji.metier.ema.dto.front.EMASkillMessageWithUserDTO']  = {
    emaSkillMessage: 'fr.hiji.metier.ema.entities.EMASkillMessage',
    tagName: 'com.ic2.sc.MultilingualString',
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    creationDate: 'java.time.Instant'
};
