<div class="card bg-light-30p rounded-2 shadow-1 h-100">
  <div class="row g-0 justify-content-center align-items-center h-100">
    <div class="col-auto pt-2 px-4 text-center">
      <i class="fa-light fa-2x" [ngClass]="icon()"></i>
    </div>
    <div class="col py-3 px-1">
      <h6>{{ title() }}</h6>
      @if (detail) {
        <div class="my-fs-2 text-center text-lg-start mt-2">{{ detail }}</div>
      }
    </div>
    @if (value) {
      <div class="col-auto my-fs-5 px-3">{{ value }}</div>
    }
  </div>
  <div class="row g-0">
    <div class="col text-muted text-end my-fs-1 px-1">
      @if (footerTemplate) {
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
      } @else {
        {{ footer() }}
      }
    </div>
  </div>
</div>
