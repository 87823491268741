import { Component, Input, TemplateRef, input } from '@angular/core';

@Component({
    selector: 'hiji-stat-card',
    templateUrl: './stat-card.component.html',
    styleUrl: './stat-card.component.scss',
    standalone: false
})
export class StatCardComponent {
  readonly title = input.required<string>();
  @Input() value: string;
  readonly icon = input.required<string>();
  @Input() detail: string;
  readonly footer = input<string>(undefined);
  @Input() footerTemplate: TemplateRef<any> | null = null;
}
