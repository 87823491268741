// ENTITY fr.hiji.metier.referential.dto.CriteriaBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Criteria } from './Criteria';

export class CriteriaBODTO {

  criteria: Criteria | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  nbObservableGestureUsing: number | null = 0;

  constructor(init?:Partial<CriteriaBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.CriteriaBODTO'] = CriteriaBODTO;

reverseMapping[CriteriaBODTO.name] = 'fr.hiji.metier.referential.dto.CriteriaBODTO';

fields['fr.hiji.metier.referential.dto.CriteriaBODTO']  = {
    criteria: 'fr.hiji.metier.referential.entities.Criteria',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    nbObservableGestureUsing: 'java.lang.Integer'
};
