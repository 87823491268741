<button class="btn btn-secondary shadow-2" [ngClass]="btnSize()" (click)="fileInput.click()" [disabled]="progressValue !== 100 || errorAppear">
  <input style="display: none" id="file" type="file" #fileInput (change)="handleFileInput($event)" />
  <i class="fa-light fa-upload me-0 me-md-2"></i>
  @if (progressValue === 100 && !errorAppear) {
    <span class="d-none d-md-inline">{{ 'common.fileUpload.Parcourir' | translate }}</span>
  }
  @if (progressValue !== 100 || errorAppear) {
    <span class="d-none d-md-inline">{{ progressValue | number: '1.0-0' }} %</span>
  }
  @if (progressValue !== 100 || errorAppear) {
    <i class="ms-2 fa-light fa-spinner spinning"></i>
  }
</button>
