<hiji-wavy-container>
  <div class="container bg-white rounded shadow-2 py-3 m-3 mx-sm-auto" style="z-index: 10">
    <div class="row justify-content-center">
      <div class="col-auto">
        <img style="width: 75px" src="assets/logo.png" />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 text-center justify-content-center">
        <div class="col">
          <h4>
            {{ 'common.reset-password.Vous avez oublié votre mot de passe ?' | translate }}
            <br />
            {{ 'common.reset-password.Ça arrive même aux meilleurs' | translate }}
          </h4>
          <p class="mt-2">
            {{ "common.reset-password.Saisissez l'adresse email associée à votre compte" | translate }}
            <br />
            {{ 'common.reset-password.Nous vous enverrons un lien pour réinitialiser votre mot de passe' | translate }}
          </p>
        </div>
      </div>
    </div>
    <form #f="ngForm" [formGroup]="form" (ngSubmit)="submit()">
      <div class="row mt-4">
        <div class="col"><input class="form-control" type="email" placeholder="E-mail" formControlName="email" autocomplete="email" /></div>
      </div>
      @if (error || (f.submitted && !form.valid)) {
        <div class="row mt-3">
          <div class="col">
            <div class="errors">
              <ul class="text-start">
                @if (form.hasError('required', 'email')) {
                  <li>{{ "common.reset-password.L'E-mail est requis" | translate }}</li>
                }
                @if (form.hasError('email', 'email')) {
                  <li>{{ "common.reset-password.L'E-mail est invalide" | translate }}</li>
                }
                @if (error) {
                  <li>
                    {{ "common.reset-password.Oops, cette adresse n'est pas répertoriée dans nos bases_DOT_ essayez une autre adresse ou" | translate }}
                    <a routerLink="/signup">
                      <u>{{ 'common.reset-password.créez tout de suite votre compte' | translate }}</u>
                    </a>
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>
      }
      <div class="row mt-5">
        <div class="col text-end">
          <button class="btn btn-primary text-uppercase" type="submit" [disabled]="loading">
            {{ 'common.reset-password.Valider' | translate }}
            @if (loading) {
              <i class="fa-light fa-spinner ms-2 spinning"></i>
            }
          </button>
        </div>
      </div>
    </form>
  </div>
</hiji-wavy-container>
