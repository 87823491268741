// ENTITY fr.hiji.metier.mif.dto.front.trainer.TrainingPathCertificationRequirementDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { TrainingPathCertificationRequirement } from './TrainingPathCertificationRequirement';

export class TrainingPathCertificationRequirementDTO {

  trainingPathCertificationRequirement: TrainingPathCertificationRequirement | null = null;
  certificationName: MultilingualString | null = new MultilingualString();
  certificationImageExtension: string | null = '';
  certified: boolean | null = false;

  constructor(init?:Partial<TrainingPathCertificationRequirementDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.trainer.TrainingPathCertificationRequirementDTO'] = TrainingPathCertificationRequirementDTO;

reverseMapping[TrainingPathCertificationRequirementDTO.name] = 'fr.hiji.metier.mif.dto.front.trainer.TrainingPathCertificationRequirementDTO';

fields['fr.hiji.metier.mif.dto.front.trainer.TrainingPathCertificationRequirementDTO']  = {
    trainingPathCertificationRequirement: 'fr.hiji.metier.mif.entities.trainingpath.TrainingPathCertificationRequirement',
    certificationName: 'com.ic2.sc.MultilingualString',
    certificationImageExtension: 'java.lang.String',
    certified: 'java.lang.Boolean'
};
