// ENTITY fr.hiji.metier.campaign.dto.front.ExchangeObservationObservableGestureDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ExchangeObservationObservableGestureDTO {

  idObservableGesture: number | null = 0;
  idObservation: number | null = 0;
  value: number | null = 0;
  text: string | null = '';
  observed: boolean | null = false;

  constructor(init?:Partial<ExchangeObservationObservableGestureDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.ExchangeObservationObservableGestureDTO'] = ExchangeObservationObservableGestureDTO;

reverseMapping[ExchangeObservationObservableGestureDTO.name] = 'fr.hiji.metier.campaign.dto.front.ExchangeObservationObservableGestureDTO';

fields['fr.hiji.metier.campaign.dto.front.ExchangeObservationObservableGestureDTO']  = {
    idObservableGesture: 'java.lang.Integer',
    idObservation: 'java.lang.Integer',
    value: 'java.lang.Integer',
    text: 'java.lang.String',
    observed: 'java.lang.Boolean'
};
