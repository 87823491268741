import { Injectable, inject } from '@angular/core';
import { FilterOrder, GenericFilter, OrderBy } from '@ic2/ic2-lib';
import { Observable, Subject } from 'rxjs';
import { RepositoryBOService, RepositoryItemBODTO } from '../../public-api';

@Injectable({
  providedIn: 'root',
})
export class RepositoryManagerService {
  private repositoryBOService = inject(RepositoryBOService);

  firstRepositories: RepositoryItemBODTO[] = [];
  loaded: boolean = false;
  loading: boolean = false;
  loadedSub = new Subject<RepositoryItemBODTO[]>();

  load(): Observable<RepositoryItemBODTO[]> {
    if (this.loaded) return new Observable((observer) => observer.next(this.firstRepositories));
    if (!this.loaded && !this.loading) return this.reload();
    return this.loadedSub.asObservable();
  }

  reload(): Observable<RepositoryItemBODTO[]> {
    this.loading = true;
    const filter = new GenericFilter();
    filter.page = 1;
    filter.nbPerPage = 50;
    filter.orderFilters.push(new FilterOrder('updatedDate', OrderBy.DESC));
    filter.orderFilters.push(new FilterOrder('creationDate', OrderBy.DESC));
    filter.orderFilters.push(new FilterOrder('name', OrderBy.ASC));
    return new Observable((observer) => {
      this.repositoryBOService
        .getRepositories(filter)
        .defaultOnError()
        .execute((data) => {
          this.loaded = true;
          this.loading = false;
          this.firstRepositories = data.data;
          this.loadedSub.next(this.firstRepositories);
          observer.next(this.firstRepositories);
        });
    });
  }
}
