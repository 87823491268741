// fr.hiji.metier.ema.services.back.EMACoachStatsBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { EMAStatsFilterBODTO } from '../entities/EMAStatsFilterBODTO';
import { EMACoachStatsDTO } from '../entities/EMACoachStatsDTO';

@Injectable({
  providedIn: 'root',
})
export class EMACoachStatsBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getCoachStats(filters: EMAStatsFilterBODTO): RpcRequestBuilder<EMACoachStatsDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMACoachStatsBOService.getCoachStats';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.ema.dto.back.stats.EMAStatsFilterBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.dto.back.stats.EMACoachStatsDTO');
  }

  getCoachStatsCsv(filters: EMAStatsFilterBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMACoachStatsBOService.getCoachStatsCsv';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.ema.dto.back.stats.EMAStatsFilterBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

}
