import { Component, Input, inject, viewChild } from '@angular/core';
import { ControlContainer, NgModelGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EnvService, L, MsPipe, MultilingualString, MultilingualstringConfigService } from '@ic2/ic2-lib';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EditorComponent } from 'ngx-monaco-editor-v2';
import { HijiEmail } from '../../ic2/entities/HijiEmail';
import { EmailBOService } from '../../ic2/services/EmailBOService';
import { AuthService } from '../../services/auth.service';
import { Ic2ToastrService } from '../../services/ic2-toastr.service';
import { Config } from '../../tools/Config';

interface EmailLike {
  emailType: HijiEmail | null;
  senderEmail: string | null;
  senderName: MultilingualString | null;
  subject: MultilingualString | null;
  content: MultilingualString | null;
}

@Component({
    selector: 'hiji-email-edit',
    templateUrl: './email-edit.component.html',
    styleUrls: ['./email-edit.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
    standalone: false
})
export class EmailEditComponent {
  multilingualstringConfigService = inject(MultilingualstringConfigService);
  private domSanitizer = inject(DomSanitizer);
  private emailBOService = inject(EmailBOService);
  private msPipe = inject(MsPipe);
  private modalService = inject(NgbModal);
  private translate = inject(TranslateService);
  private ic2ToastrService = inject(Ic2ToastrService);
  config = inject<EnvService<Config>>(EnvService);
  authService = inject(AuthService);

  readonly previewTemplate = viewChild('previewTemplate');
  subject: SafeHtml;
  body: SafeHtml;
  currentLanguage: string;
  languages: string[];
  loadingEmail: boolean = false;
  loadingTestEmail: boolean = false;
  loadingResetEmail: boolean = false;
  editorOptions = { theme: 'vs', language: 'html', automaticLayout: true, readOnly: true, wordWrap: 'on' };
  readonly editor = viewChild<EditorComponent>('editor');

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    this.editorOptions.readOnly = value;
    const editor = this.editor();
    if (editor) (editor as any)._editor.updateOptions({ readOnly: this._disabled }); //dirty hack
  }
  _disabled: boolean = false;
  @Input()
  email: EmailLike;
  @Input()
  canResetToDefaultEmail: boolean = true;
  @Input()
  canResetToHijiEmail: boolean = true;

  constructor() {
    this.currentLanguage = this.translate.currentLang;
    this.languages = this.multilingualstringConfigService.availableLanguages;
    if (!this.languages.includes(this.currentLanguage)) this.currentLanguage = this.languages[0];
  }

  askServerForPreview(email: EmailLike, language: string) {
    this.loadingEmail = true;
    this.subject = null;
    this.body = null;
    console.log(email.subject.getOrEnglishOrAny(language));
    this.emailBOService.previewEmail(email.emailType, email.subject.getOrEnglishOrAny(language), email.content.getOrEnglishOrAny(language)).subscribe(
      (data) => {
        this.loadingEmail = false;
        this.subject = this.domSanitizer.bypassSecurityTrustHtml(data.subject);
        this.body = this.domSanitizer.bypassSecurityTrustHtml(data.body);
        this.showModal();
      },
      (err) => {
        L.e(err);
        if (err.isBusinessError())
          this.ic2ToastrService.showDanger(this.translate.instant("common.email-edit.toast.Le template de l'email contient une erreur"));
        this.loadingEmail = false;
      }
    );
  }

  askServerForTestEmail(language) {
    this.subject = null;
    this.body = null;
    console.log(this.email.subject.getOrEnglishOrAny(language));
    this.loadingTestEmail = true;
    this.emailBOService
      .sendTestEmail(this.email.emailType, this.email.subject.getOrEnglishOrAny(language), this.email.content.getOrEnglishOrAny(language))
      .subscribe(
        (data) => {
          this.ic2ToastrService.showSuccess(this.translate.instant('common.email-edit.toast.Email envoyé'));
          this.loadingTestEmail = false;
        },
        (err) => {
          L.e(err);
          if (err.isBusinessError())
            this.ic2ToastrService.showDanger(this.translate.instant("common.email-edit.toast.Le template de l'email contient une erreur"));
          this.loadingTestEmail = false;
        }
      );
  }

  resetToDefault() {
    this.loadingResetEmail = true;
    this.emailBOService.getEmail(this.email.emailType.id).subscribe(
      (data) => {
        this.email.content.obj[this.currentLanguage] = data.email.content.obj[this.currentLanguage];
        this.loadingResetEmail = false;
      },
      (err) => {
        this.loadingResetEmail = false;
        L.e(err);
      }
    );
  }

  resetToHijiEmail() {
    this.loadingResetEmail = true;
    this.emailBOService.getEmailHiji(this.email.emailType).subscribe(
      (data) => {
        if (!(this.currentLanguage in data.obj)) {
          this.ic2ToastrService.showDanger(
            this.translate.instant("common.email-edit.toast.La version {{lang}} du mail hiji n'existe pas", {
              lang: this.translate.instant('common.languages.' + this.currentLanguage),
            })
          );
        } else this.email.content.obj[this.currentLanguage] = data.obj[this.currentLanguage];
        this.loadingResetEmail = false;
      },
      (err) => {
        this.loadingResetEmail = false;
        L.e(err);
      }
    );
  }

  showModal() {
    const modal: NgbModalRef = this.modalService.open(this.previewTemplate(), { size: 'lg', centered: true });

    modal.result.then(
      (result) => {},
      (err) => {}
    );
  }
  getEditorConfig(): AngularEditorConfig {
    return {
      editable: !this.disabled,
      spellcheck: false,
      minHeight: '20rem',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      defaultParagraphSeparator: '',
      defaultFontName: 'Roboto',
      defaultFontSize: '',
      fonts: [
        { class: 'roboto', name: 'Roboto' },
        { class: 'arial', name: 'Arial' },
        { class: 'times-new-roman', name: 'Times New Roman' },
        { class: 'calibri', name: 'Calibri' },
        { class: 'comic-sans-ms', name: 'Comic Sans MS' },
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText',
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      uploadUrl: null,
      upload: null,
      uploadWithCredentials: false,
      sanitize: false,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [[], ['insertImage', 'insertVideo']],
    };
  }

  deleteLanguage(lang: string) {
    delete this.email.content.obj[lang];
  }
}
