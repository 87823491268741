// DB ENUM fr.hiji.metier.tools.email.HijiEmail generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:59 UTC 2025

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class HijiEmail {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly _1_360_LANCEMENT_CAMPAGNE = new HijiEmail(1, "360_LANCEMENT_CAMPAGNE", "_1_360_LANCEMENT_CAMPAGNE");
  static readonly _2_360_LANCEMENT_INQUIRY = new HijiEmail(2, "360_LANCEMENT_INQUIRY", "_2_360_LANCEMENT_INQUIRY");
  static readonly _3_360_RELANCE_INQUIRY_NOT_DEPLOYED = new HijiEmail(3, "360_RELANCE_INQUIRY_NOT_DEPLOYED", "_3_360_RELANCE_INQUIRY_NOT_DEPLOYED");
  static readonly _4_360_RELANCE_INQUIRY_NOT_AUTOOBS = new HijiEmail(4, "360_RELANCE_INQUIRY_NOT_AUTOOBS", "_4_360_RELANCE_INQUIRY_NOT_AUTOOBS");
  static readonly _5_360_RELANCE_INQUIRY_TARGETS_NO_ANSWER = new HijiEmail(5, "360_RELANCE_INQUIRY_TARGETS_NO_ANSWER", "_5_360_RELANCE_INQUIRY_TARGETS_NO_ANSWER");
  static readonly _6_360_INQUIRY_TARGET_ANSWER = new HijiEmail(6, "360_INQUIRY_TARGET_ANSWER", "_6_360_INQUIRY_TARGET_ANSWER");
  static readonly _7_360_INQUIRY_END_NOT_DEPLOYED_ALERT = new HijiEmail(7, "360_INQUIRY_END_NOT_DEPLOYED_ALERT", "_7_360_INQUIRY_END_NOT_DEPLOYED_ALERT");
  static readonly _8_360_INQUIRY_END_TARGET_NO_ANSWER_ALERT = new HijiEmail(8, "360_INQUIRY_END_TARGET_NO_ANSWER_ALERT", "_8_360_INQUIRY_END_TARGET_NO_ANSWER_ALERT");
  static readonly _9_360_INQUIRY_END = new HijiEmail(9, "360_INQUIRY_END", "_9_360_INQUIRY_END");
  static readonly _10_RESULT_AVAILABLE = new HijiEmail(10, "RESULT_AVAILABLE", "_10_RESULT_AVAILABLE");
  static readonly _11_360_INQUIRY_TARGET_DEPLOYED = new HijiEmail(11, "360_INQUIRY_TARGET_DEPLOYED", "_11_360_INQUIRY_TARGET_DEPLOYED");
  static readonly _12_NOTIF_FEEDBACK = new HijiEmail(12, "NOTIF_FEEDBACK", "_12_NOTIF_FEEDBACK");
  static readonly _13_SEND_EMAIL_RECAP = new HijiEmail(13, "SEND_EMAIL_RECAP", "_13_SEND_EMAIL_RECAP");
  static readonly _14_SEND_EMAIL_FEEDBACK_REMINDER = new HijiEmail(14, "SEND_EMAIL_FEEDBACK_REMINDER", "_14_SEND_EMAIL_FEEDBACK_REMINDER");
  static readonly _15_ACCOUNT_CREATED = new HijiEmail(15, "ACCOUNT_CREATED", "_15_ACCOUNT_CREATED");
  static readonly _16_RESET_PWD = new HijiEmail(16, "RESET_PWD", "_16_RESET_PWD");
  static readonly _17_ENVOI_RAPPORT_MENSUEL = new HijiEmail(17, "ENVOI_RAPPORT_MENSUEL", "_17_ENVOI_RAPPORT_MENSUEL");
  static readonly _18_ENVOI_RAPPORT_HEBDO = new HijiEmail(18, "ENVOI_RAPPORT_HEBDO", "_18_ENVOI_RAPPORT_HEBDO");
  static readonly _19_MAIL_AUTH = new HijiEmail(19, "MAIL_AUTH", "_19_MAIL_AUTH");
  static readonly _20_EXCHANGE_REMINDER_NEVER = new HijiEmail(20, "EXCHANGE_REMINDER_NEVER", "_20_EXCHANGE_REMINDER_NEVER");
  static readonly _21_EXCHANGE_REMINDER_NOT_ANYMORE = new HijiEmail(21, "EXCHANGE_REMINDER_NOT_ANYMORE", "_21_EXCHANGE_REMINDER_NOT_ANYMORE");
  static readonly _22_EXCHANGE_REMINDER_1_49 = new HijiEmail(22, "EXCHANGE_REMINDER_1_49", "_22_EXCHANGE_REMINDER_1_49");
  static readonly _23_EXCHANGE_REMINDER_50_99 = new HijiEmail(23, "EXCHANGE_REMINDER_50_99", "_23_EXCHANGE_REMINDER_50_99");
  static readonly _24_EXCHANGE_REMINDER_100 = new HijiEmail(24, "EXCHANGE_REMINDER_100", "_24_EXCHANGE_REMINDER_100");
  static readonly _25_COLLAB_AUTOOBS_NEED_EXCHANGE = new HijiEmail(25, "COLLAB_AUTOOBS_NEED_EXCHANGE", "_25_COLLAB_AUTOOBS_NEED_EXCHANGE");
  static readonly _26_FEEDBACK_SOLICITATION_EMAIL = new HijiEmail(26, "FEEDBACK_SOLICITATION_EMAIL", "_26_FEEDBACK_SOLICITATION_EMAIL");
  static readonly _27_SURVEY_LAUNCH = new HijiEmail(27, "SURVEY_LAUNCH", "_27_SURVEY_LAUNCH");
  static readonly _28_SURVEY_FOLLOW_UP_START = new HijiEmail(28, "SURVEY_FOLLOW_UP_START", "_28_SURVEY_FOLLOW_UP_START");
  static readonly _29_SURVEY_FOLLOW_UP_END = new HijiEmail(29, "SURVEY_FOLLOW_UP_END", "_29_SURVEY_FOLLOW_UP_END");
  static readonly _30_SURVEY_FOLLOW_UP_LAST_DAY = new HijiEmail(30, "SURVEY_FOLLOW_UP_LAST_DAY", "_30_SURVEY_FOLLOW_UP_LAST_DAY");
  static readonly _31_SURVEY_FOLLOW_UP_OVERTIME = new HijiEmail(31, "SURVEY_FOLLOW_UP_OVERTIME", "_31_SURVEY_FOLLOW_UP_OVERTIME");
  static readonly _32_PDI_LAUNCH_EMPLOYEE = new HijiEmail(32, "PDI_LAUNCH_EMPLOYEE", "_32_PDI_LAUNCH_EMPLOYEE");
  static readonly _33_PDI_LAUNCH_MANAGER = new HijiEmail(33, "PDI_LAUNCH_MANAGER", "_33_PDI_LAUNCH_MANAGER");
  static readonly _34_PDI_READY_PICK_FORMATION = new HijiEmail(34, "PDI_READY_PICK_FORMATION", "_34_PDI_READY_PICK_FORMATION");
  static readonly _35_PDI_RESULT = new HijiEmail(35, "PDI_RESULT", "_35_PDI_RESULT");
  static readonly _36_MIF_PARTICIPANT_ACQUISITION_BEFORE = new HijiEmail(36, "MIF_PARTICIPANT_ACQUISITION_BEFORE", "_36_MIF_PARTICIPANT_ACQUISITION_BEFORE");
  static readonly _37_MIF_RELANCE_PARTICIPANT_ACQUISITION_BEFORE = new HijiEmail(37, "MIF_RELANCE_PARTICIPANT_ACQUISITION_BEFORE", "_37_MIF_RELANCE_PARTICIPANT_ACQUISITION_BEFORE");
  static readonly _38_MIF_MANAGER_FORMATION_INFOS = new HijiEmail(38, "MIF_MANAGER_FORMATION_INFOS", "_38_MIF_MANAGER_FORMATION_INFOS");
  static readonly _39_MIF_TRAINER_FORMATION_INFOS = new HijiEmail(39, "MIF_TRAINER_FORMATION_INFOS", "_39_MIF_TRAINER_FORMATION_INFOS");
  static readonly _40_MIF_PARTICIPANT_SATISFACTION = new HijiEmail(40, "MIF_PARTICIPANT_SATISFACTION", "_40_MIF_PARTICIPANT_SATISFACTION");
  static readonly _41_MIF_PARTICIPANT_ACQUISITION_AFTER = new HijiEmail(41, "MIF_PARTICIPANT_ACQUISITION_AFTER", "_41_MIF_PARTICIPANT_ACQUISITION_AFTER");
  static readonly _42_MIF_RELANCE_PARTICIPANT_ACQUISITION_AFTER = new HijiEmail(42, "MIF_RELANCE_PARTICIPANT_ACQUISITION_AFTER", "_42_MIF_RELANCE_PARTICIPANT_ACQUISITION_AFTER");
  static readonly _43_MIF_MANAGER_AFTER_FORMATION = new HijiEmail(43, "MIF_MANAGER_AFTER_FORMATION", "_43_MIF_MANAGER_AFTER_FORMATION");
  static readonly _44_MIF_PARTICIPANT_EVA_DEFINED = new HijiEmail(44, "MIF_PARTICIPANT_EVA_DEFINED", "_44_MIF_PARTICIPANT_EVA_DEFINED");
  static readonly _45_MIF_MANAGER_EVA_DEFINED = new HijiEmail(45, "MIF_MANAGER_EVA_DEFINED", "_45_MIF_MANAGER_EVA_DEFINED");
  static readonly _47_MIF_RELANCE_MANAGER_EVA = new HijiEmail(47, "MIF_RELANCE_MANAGER_EVA", "_47_MIF_RELANCE_MANAGER_EVA");
  static readonly _48_MIF_LAST_RELANCE_MANAGER_EVA = new HijiEmail(48, "MIF_LAST_RELANCE_MANAGER_EVA", "_48_MIF_LAST_RELANCE_MANAGER_EVA");
  static readonly _50_EMA_PLANNED = new HijiEmail(50, "EMA_PLANNED", "_50_EMA_PLANNED");
  static readonly _51_SURVEY_PARTICIPATION = new HijiEmail(51, "SURVEY_PARTICIPATION", "_51_SURVEY_PARTICIPATION");
  static readonly _52_RAPPORT_EMA_EMAIL = new HijiEmail(52, "REPORT_EMA", "_52_RAPPORT_EMA_EMAIL");
  static readonly _53_MIF_EVENT_ACQUISITION_BEFORE = new HijiEmail(53, "MIF_EVENT_ACQUISITION_BEFORE", "_53_MIF_EVENT_ACQUISITION_BEFORE");
  static readonly _54_MIF_EVENT_ACQUISITION_AFTER = new HijiEmail(54, "MIF_EVENT_ACQUISITION_AFTER", "_54_MIF_EVENT_ACQUISITION_AFTER");
  static readonly _55_MIF_EVENT_SATISFACTION = new HijiEmail(55, "MIF_EVENT_SATISFACTION", "_55_MIF_EVENT_SATISFACTION");
  static readonly _56_MIF_EVENT_SATISFACTION_RELANCE = new HijiEmail(56, "MIF_EVENT_SATISFACTION_RELANCE", "_56_MIF_EVENT_SATISFACTION_RELANCE");
  static readonly _57_MIF_EVENT_ACQUISITION_BEFORE_RELANCE = new HijiEmail(57, "MIF_EVENT_ACQUISITION_BEFORE_RELANCE", "_57_MIF_EVENT_ACQUISITION_BEFORE_RELANCE");
  static readonly _58_MIF_EVENT_ACQUISITION_AFTER_RELANCE = new HijiEmail(58, "MIF_EVENT_ACQUISITION_AFTER_RELANCE", "_58_MIF_EVENT_ACQUISITION_AFTER_RELANCE");
  static readonly _59_FORMATION_QUIZ_PARTICIPANT = new HijiEmail(59, "FORMATION_QUIZ_PARTICIPANT", "_59_FORMATION_QUIZ_PARTICIPANT");
  static readonly _60_FORMATION_QUIZ_PARTICIPANT_RELANCE = new HijiEmail(60, "FORMATION_QUIZ_PARTICIPANT_RELANCE", "_60_FORMATION_QUIZ_PARTICIPANT_RELANCE");
  static readonly _61_MIF_RELANCE_PARTICIPANT_SATISFACTION = new HijiEmail(61, "MIF_RELANCE_PARTICIPANT_SATISFACTION", "_61_MIF_RELANCE_PARTICIPANT_SATISFACTION");
  static readonly _62_EMA_PLAN_NEXT_EXCHANGE = new HijiEmail(62, "EMA_PLAN_NEXT_EXCHANGE", "_62_EMA_PLAN_NEXT_EXCHANGE");

  static readonly values = [
    HijiEmail._1_360_LANCEMENT_CAMPAGNE,
 
    HijiEmail._2_360_LANCEMENT_INQUIRY,
 
    HijiEmail._3_360_RELANCE_INQUIRY_NOT_DEPLOYED,
 
    HijiEmail._4_360_RELANCE_INQUIRY_NOT_AUTOOBS,
 
    HijiEmail._5_360_RELANCE_INQUIRY_TARGETS_NO_ANSWER,
 
    HijiEmail._6_360_INQUIRY_TARGET_ANSWER,
 
    HijiEmail._7_360_INQUIRY_END_NOT_DEPLOYED_ALERT,
 
    HijiEmail._8_360_INQUIRY_END_TARGET_NO_ANSWER_ALERT,
 
    HijiEmail._9_360_INQUIRY_END,
 
    HijiEmail._10_RESULT_AVAILABLE,
 
    HijiEmail._11_360_INQUIRY_TARGET_DEPLOYED,
 
    HijiEmail._12_NOTIF_FEEDBACK,
 
    HijiEmail._13_SEND_EMAIL_RECAP,
 
    HijiEmail._14_SEND_EMAIL_FEEDBACK_REMINDER,
 
    HijiEmail._15_ACCOUNT_CREATED,
 
    HijiEmail._16_RESET_PWD,
 
    HijiEmail._17_ENVOI_RAPPORT_MENSUEL,
 
    HijiEmail._18_ENVOI_RAPPORT_HEBDO,
 
    HijiEmail._19_MAIL_AUTH,
 
    HijiEmail._20_EXCHANGE_REMINDER_NEVER,
 
    HijiEmail._21_EXCHANGE_REMINDER_NOT_ANYMORE,
 
    HijiEmail._22_EXCHANGE_REMINDER_1_49,
 
    HijiEmail._23_EXCHANGE_REMINDER_50_99,
 
    HijiEmail._24_EXCHANGE_REMINDER_100,
 
    HijiEmail._25_COLLAB_AUTOOBS_NEED_EXCHANGE,
 
    HijiEmail._26_FEEDBACK_SOLICITATION_EMAIL,
 
    HijiEmail._27_SURVEY_LAUNCH,
 
    HijiEmail._28_SURVEY_FOLLOW_UP_START,
 
    HijiEmail._29_SURVEY_FOLLOW_UP_END,
 
    HijiEmail._30_SURVEY_FOLLOW_UP_LAST_DAY,
 
    HijiEmail._31_SURVEY_FOLLOW_UP_OVERTIME,
 
    HijiEmail._32_PDI_LAUNCH_EMPLOYEE,
 
    HijiEmail._33_PDI_LAUNCH_MANAGER,
 
    HijiEmail._34_PDI_READY_PICK_FORMATION,
 
    HijiEmail._35_PDI_RESULT,
 
    HijiEmail._36_MIF_PARTICIPANT_ACQUISITION_BEFORE,
 
    HijiEmail._37_MIF_RELANCE_PARTICIPANT_ACQUISITION_BEFORE,
 
    HijiEmail._38_MIF_MANAGER_FORMATION_INFOS,
 
    HijiEmail._39_MIF_TRAINER_FORMATION_INFOS,
 
    HijiEmail._40_MIF_PARTICIPANT_SATISFACTION,
 
    HijiEmail._41_MIF_PARTICIPANT_ACQUISITION_AFTER,
 
    HijiEmail._42_MIF_RELANCE_PARTICIPANT_ACQUISITION_AFTER,
 
    HijiEmail._43_MIF_MANAGER_AFTER_FORMATION,
 
    HijiEmail._44_MIF_PARTICIPANT_EVA_DEFINED,
 
    HijiEmail._45_MIF_MANAGER_EVA_DEFINED,
 
    HijiEmail._47_MIF_RELANCE_MANAGER_EVA,
 
    HijiEmail._48_MIF_LAST_RELANCE_MANAGER_EVA,
 
    HijiEmail._50_EMA_PLANNED,
 
    HijiEmail._51_SURVEY_PARTICIPATION,
 
    HijiEmail._52_RAPPORT_EMA_EMAIL,
 
    HijiEmail._53_MIF_EVENT_ACQUISITION_BEFORE,
 
    HijiEmail._54_MIF_EVENT_ACQUISITION_AFTER,
 
    HijiEmail._55_MIF_EVENT_SATISFACTION,
 
    HijiEmail._56_MIF_EVENT_SATISFACTION_RELANCE,
 
    HijiEmail._57_MIF_EVENT_ACQUISITION_BEFORE_RELANCE,
 
    HijiEmail._58_MIF_EVENT_ACQUISITION_AFTER_RELANCE,
 
    HijiEmail._59_FORMATION_QUIZ_PARTICIPANT,
 
    HijiEmail._60_FORMATION_QUIZ_PARTICIPANT_RELANCE,
 
    HijiEmail._61_MIF_RELANCE_PARTICIPANT_SATISFACTION,
 
    HijiEmail._62_EMA_PLAN_NEXT_EXCHANGE
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of HijiEmail.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of HijiEmail.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.tools.email.HijiEmail'] = HijiEmail;
reverseMapping[HijiEmail.name] = 'fr.hiji.metier.tools.email.HijiEmail';
