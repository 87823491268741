import { Component, Input, input } from '@angular/core';
import { Criteria } from '../../../ic2/entities/Criteria';
import { CriteriaType } from '../../../ic2/entities/CriteriaType';

@Component({
    selector: 'hiji-translate-criteria',
    templateUrl: './translate-criteria.component.html',
    styleUrl: './translate-criteria.component.scss',
    standalone: false
})
export class TranslateCriteriaComponent {
  @Input()
  criteria: Criteria;
  readonly sourceLang = input<string>(undefined);
  readonly destinationLang = input<string>(undefined);
  readonly textAreaRows = input<number>(5);
  readonly alreadyExist = input<boolean>(undefined);

  CriteriaType: typeof CriteriaType = CriteriaType;
}
