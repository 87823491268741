<div class="row mt-1">
  <div class="col-1 d-flex align-items-center">{{ title() }}</div>
  <div class="col">
    <div class="input-group flex-nowrap d-flex flex-grow-1">
      <div class="input-group-text">{{ sourceLang }}</div>
      <textarea class="form-control" [(ngModel)]="translation.obj[sourceLang]" type="text" [rows]="textAreaRows()" [disabled]="true"></textarea>
      @if (translation.obj[sourceLang]) {
        <img class="ms-2 pe-none align-self-center" style="max-width: 35px; max-height: 35px" container="body" [img-preloader]="translation.obj[sourceLang]" />
      } @else {
        <div
          class="ms-2 bg-light-30p"
          style="width: 35px; height: 35px"
          ngbTooltip="{{ 'common.translate.Il n\'y a pas d\'image pour votre langue actuelle : \{\{lang\}\}' | translate: { lang: destinationLang } }}"
        ></div>
      }
    </div>
  </div>
  <div class="col align-items-center">
    <div class="input-group flex-nowrap d-flex flex-grow-1">
      <div class="input-group-text">{{ destinationLang }}</div>
      <textarea class="form-control" [(ngModel)]="translation.obj[destinationLang]" type="text" [rows]="textAreaRows()" [disabled]="false"></textarea>
      @if (translation.obj[destinationLang]) {
        <img
          class="ms-2 pe-none align-self-center"
          style="max-width: 35px; max-height: 35px"
          container="body"
          [img-preloader]="translation.obj[destinationLang]"
          />
        } @else {
          <div
            class="ms-2 bg-light-30p"
            style="width: 35px; height: 35px"
            ngbTooltip="{{ 'common.translate.Il n\'y a pas d\'image pour votre langue actuelle : \{\{lang\}\}' | translate: { lang: destinationLang } }}"
          ></div>
        }
      </div>
    </div>
    <div class="col-auto d-flex align-items-center">
      <button class="btn btn-primary" type="button" (click)="fileInput.click()" [disabled]="this.translation.obj[this.sourceLang] == null">
        <input style="display: none" id="file" type="file" accept="image/png,image/jpeg,image/gif" (change)="handleFileInput($event)" #fileInput />

        {{ 'common.translate.Uploader une image' | translate }}
        @if (!loadingUpload) {
          <i class="fa-solid fa-upload"></i>
        }
        @if (loadingUpload) {
          <i class="fa-light fa-spinner spinning"></i>
        }
      </button>
    </div>
  </div>
