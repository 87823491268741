// ENTITY fr.hiji.metier.conf.dto.front.submodules.ModuleTMSDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleTMSDTO {

  enabled: boolean | null = false;
  trainingPathEnabled: boolean | null = false;
  diffusionListEnabled: boolean | null = false;
  trainingPathVisibleInFront: boolean | null = false;

  constructor(init?:Partial<ModuleTMSDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.front.submodules.ModuleTMSDTO'] = ModuleTMSDTO;

reverseMapping[ModuleTMSDTO.name] = 'fr.hiji.metier.conf.dto.front.submodules.ModuleTMSDTO';

fields['fr.hiji.metier.conf.dto.front.submodules.ModuleTMSDTO']  = {
    enabled: 'java.lang.Boolean',
    trainingPathEnabled: 'java.lang.Boolean',
    diffusionListEnabled: 'java.lang.Boolean',
    trainingPathVisibleInFront: 'java.lang.Boolean'
};
