// ENTITY fr.hiji.metier.conf.dto.back.submodules.ModuleMIFBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleMIFBODTO {

  moduleMesureImpactFormationEnabled: boolean | null = false;
  idCriteriaAction: number | null = 0;
  idCriteriaExpectation: number | null = 0;
  idCriteriaBusinessExpectation: number | null = 0;
  addPopulationToSessionEnabled: boolean | null = false;
  activationEnabled: boolean | null = false;
  expectationsManagerEnabled: boolean | null = false;
  attendanceEnabled: boolean | null = false;

  constructor(init?:Partial<ModuleMIFBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.back.submodules.ModuleMIFBODTO'] = ModuleMIFBODTO;

reverseMapping[ModuleMIFBODTO.name] = 'fr.hiji.metier.conf.dto.back.submodules.ModuleMIFBODTO';

fields['fr.hiji.metier.conf.dto.back.submodules.ModuleMIFBODTO']  = {
    moduleMesureImpactFormationEnabled: 'java.lang.Boolean',
    idCriteriaAction: 'java.lang.Integer',
    idCriteriaExpectation: 'java.lang.Integer',
    idCriteriaBusinessExpectation: 'java.lang.Integer',
    addPopulationToSessionEnabled: 'java.lang.Boolean',
    activationEnabled: 'java.lang.Boolean',
    expectationsManagerEnabled: 'java.lang.Boolean',
    attendanceEnabled: 'java.lang.Boolean'
};
