// ENTITY fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsParticipationPerDayBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class SurveyStatsParticipationPerDayBODTO {

  date: Date | null = null;
  nbDeployed: number | null = 0;
  nbLaunched: number | null = 0;
  nbSollicite: number | null = 0;
  nbAnswers: number | null = 0;

  constructor(init?:Partial<SurveyStatsParticipationPerDayBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsParticipationPerDayBODTO'] = SurveyStatsParticipationPerDayBODTO;

reverseMapping[SurveyStatsParticipationPerDayBODTO.name] = 'fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsParticipationPerDayBODTO';

fields['fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsParticipationPerDayBODTO']  = {
    date: 'java.time.LocalDate',
    nbDeployed: 'java.lang.Integer',
    nbLaunched: 'java.lang.Integer',
    nbSollicite: 'java.lang.Integer',
    nbAnswers: 'java.lang.Integer'
};
