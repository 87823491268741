// ENTITY fr.hiji.metier.user.dto.front.GroupDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { GroupType } from './GroupType';

export class GroupDTO {

  groupType: GroupType | null = null;
  idGroup: number | null = 0;
  groupName: string | null = '';
  users: UserFuncAvatarDTO[] | null = [];

  constructor(init?:Partial<GroupDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.front.GroupDTO'] = GroupDTO;

reverseMapping[GroupDTO.name] = 'fr.hiji.metier.user.dto.front.GroupDTO';

fields['fr.hiji.metier.user.dto.front.GroupDTO']  = {
    groupType: 'fr.hiji.metier.user.dto.front.GroupType',
    idGroup: 'java.lang.Integer',
    groupName: 'java.lang.String',
    users: 'java.util.List<fr.hiji.metier.user.dto.front.UserFuncAvatarDTO>'
};
