// ENTITY fr.hiji.metier.mif.dto.back.registration.DiffusionListBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { DiffusionList } from './DiffusionList';

export class DiffusionListBODTO {

  diffusionList: DiffusionList | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  populationSeeingName: MultilingualString | null = new MultilingualString();
  populationSeeingDel: boolean | null = false;
  nbUsersSeeing: number | null = 0;
  populationRegisteringName: MultilingualString | null = new MultilingualString();
  populationRegisteringDel: boolean | null = false;
  nbUsersRegistering: number | null = 0;
  populationValidatingName: MultilingualString | null = new MultilingualString();
  populationValidatingDel: boolean | null = false;
  nbUsersValidating: number | null = 0;

  constructor(init?:Partial<DiffusionListBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.registration.DiffusionListBODTO'] = DiffusionListBODTO;

reverseMapping[DiffusionListBODTO.name] = 'fr.hiji.metier.mif.dto.back.registration.DiffusionListBODTO';

fields['fr.hiji.metier.mif.dto.back.registration.DiffusionListBODTO']  = {
    diffusionList: 'fr.hiji.metier.mif.entities.registration.DiffusionList',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    populationSeeingName: 'com.ic2.sc.MultilingualString',
    populationSeeingDel: 'java.lang.Boolean',
    nbUsersSeeing: 'java.lang.Integer',
    populationRegisteringName: 'com.ic2.sc.MultilingualString',
    populationRegisteringDel: 'java.lang.Boolean',
    nbUsersRegistering: 'java.lang.Integer',
    populationValidatingName: 'com.ic2.sc.MultilingualString',
    populationValidatingDel: 'java.lang.Boolean',
    nbUsersValidating: 'java.lang.Integer'
};
