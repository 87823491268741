// ENTITY fr.hiji.metier.user.dto.front.coach.CoachPerimeterOrganisationDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { OrganisationType } from './OrganisationType';
import { UserInCoachPerimeterDTO } from './UserInCoachPerimeterDTO';

export class CoachPerimeterOrganisationDTO {

  idOrganisation: number | null = 0;
  idParent: number | null = 0;
  name: string | null = '';
  type: OrganisationType | null = null;
  nbUsers: number | null = 0;
  users: UserInCoachPerimeterDTO[] | null = [];
  suborgas: CoachPerimeterOrganisationDTO[] | null = [];

  constructor(init?:Partial<CoachPerimeterOrganisationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.front.coach.CoachPerimeterOrganisationDTO'] = CoachPerimeterOrganisationDTO;

reverseMapping[CoachPerimeterOrganisationDTO.name] = 'fr.hiji.metier.user.dto.front.coach.CoachPerimeterOrganisationDTO';

fields['fr.hiji.metier.user.dto.front.coach.CoachPerimeterOrganisationDTO']  = {
    idOrganisation: 'java.lang.Integer',
    idParent: 'java.lang.Integer',
    name: 'java.lang.String',
    type: 'fr.hiji.metier.reseau.entities.OrganisationType',
    nbUsers: 'java.lang.Integer',
    users: 'java.util.List<fr.hiji.metier.user.dto.front.coach.UserInCoachPerimeterDTO>',
    suborgas: 'java.util.List<fr.hiji.metier.user.dto.front.coach.CoachPerimeterOrganisationDTO>'
};
