import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvService, L, RpcError } from '@ic2/ic2-lib';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { timer } from 'rxjs';
import { HijiRight } from '../../ic2/entities/HijiRight';
import { SSOConfigDTO } from '../../ic2/entities/SSOConfigDTO';
import { SSOService } from '../../ic2/services/SSOService';
import { AuthService } from '../../services/auth.service';
import { Config } from '../../tools/Config';
import { SsoErrorComponent } from '../sso-error/sso-error.component';

//validate token with : https://demo.identityserver.io/connect/userinfo
/*
const config: AuthConfig = {
  issuer: 'https://demo.identityserver.io',
  clientId: 'interactive.public',
  redirectUri: 'http://hiji.local:4202/sso',
  logoutUrl: 'TODO/v2/logout?returnTo=' + window.location.origin,
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  scope: 'openid profile email',
  //responseType: 'client_credentials refresh_token authorization_code implicit',
  //responseType: 'id_token token',
  responseType: 'code',
  showDebugInformation: true,
};
*/

//https://login.microsoftonline.com/6052ae7d-612b-44b4-8d73-e471023ff979/v2.0/.well-known/openid-configuration
/*
const config: AuthConfig = {
  // Url of the Identity Provider
  issuer: 'https://login.microsoftonline.com/6052ae7d-612b-44b4-8d73-e471023ff979/v2.0',
  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/sso',

  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: '7c38187c-d8b2-48ef-a8c6-34c54fc1edd0',

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  //scope: 'openid your-app-id',

  // Or the API scopes
  scope: 'openid profile email',
  responseType: 'code',
  customQueryParams: { prompt: 'login' },
  strictDiscoveryDocumentValidation: false,
};
*/
/*
export const config: AuthConfig = {
  // Url of the Identity Provider
  issuer: 'https://accounts.google.com',

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/sso',

  // URL of the SPA to redirect the user after silent refresh
  //silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: '1034310187256-6bntcaulauafbhm1u9uf3fb8jd9ilio4.apps.googleusercontent.com',

  strictDiscoveryDocumentValidation: false,

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid profile email',

  customQueryParams: { prompt: 'login' },
  showDebugInformation: true,

  sessionChecksEnabled: true,
};
config.logoutUrl = `${config.issuer}/v2/logout?client_id=${config.clientId}&returnTo=${config.redirectUri}`;
*/
//Coté serv on peut validate le token via https://www.googleapis.com/userinfo/v2/me

@Component({
  selector: 'hiji-sso-login',
  templateUrl: './login-sso.component.html',
  styleUrls: ['./login-sso.component.scss'],
  standalone: false,
})
export class LoginSSOComponent implements OnInit {
  private oauthService = inject(OAuthService);
  private ssoService = inject(SSOService);
  private authService = inject(AuthService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private config = inject<EnvService<Config>>(EnvService);

  showMsg: boolean = false;
  maintenanceMode: boolean = false;
  ssoId: string = '';
  ssoConfig: SSOConfigDTO = null;

  logout() {
    this.oauthService.logOut();
  }

  tokenReceived() {
    const backoffice = this.route.snapshot.data['backoffice'] ?? false;
    this.ssoService
      .loginSSO(
        this.ssoConfig.id,
        this.oauthService.getIdToken(),
        this.oauthService.getAccessToken(),
        backoffice ? AuthService.BACKOFFICE_ORIGIN : AuthService.FRONTOFFICE_ORIGIN
      )
      .subscribe(
        (data) => {
          if (backoffice) {
            const loggedIn = data && AuthService.hasAccessToBackoffice(data);
            if (!loggedIn) {
              this.router.navigate(['/sso-error', 'no_right', data.user.email]);
              return;
            }
          }
          this.authService.loginWith(
            data,
            () => {
              let redirectUrl = '/';
              if (backoffice && AuthService.has(data, HijiRight.CONSULTATION_STATS)) redirectUrl = '/campaign';
              if (backoffice && AuthService.has(data, HijiRight.TRADUCTEUR)) redirectUrl = '/configuration/translation';
              if (localStorage.getItem('redirectUrl') !== null) {
                redirectUrl = localStorage.getItem('redirectUrl');
                localStorage.removeItem('redirectUrl');
              }
              this.router.navigate([redirectUrl]);
            },
            false
          );
        },
        (err: RpcError) => {
          if (err.isBusinessError() && err.data && err.data['code'] && err.data['email'])
            this.router.navigate(['/sso-error', err.data['code'], err.data['email']]);
          else if (err.isBusinessError() && err.data && err.data['code']) {
            SsoErrorComponent.last_error = err.message;
            this.router.navigate(['/sso-error', err.data['code']]);
          } else if (err.code === -33000) this.maintenanceMode = true;
          else L.e(err);
        }
      );
  }

  ngOnInit(): void {
    if (this.route.snapshot.params['id']) this.ssoId = this.route.snapshot.params['id'];
    this.ssoService
      .getSSOConfig(this.ssoId)
      .onErrorUseDefault()
      .execute((data) => {
        this.ssoConfig = data;

        timer(3000).subscribe(() => (this.showMsg = true));
        // For debugging:
        this.oauthService.events.subscribe((e) => {
          console.log('EVENTSSO', e);
          if (e.type === 'token_received') this.tokenReceived();
        });

        const config: AuthConfig = {
          issuer: this.ssoConfig.issuer,
          clientId: this.ssoConfig.clientId,
          scope: this.ssoConfig.scope,
          responseType: this.ssoConfig.responseType,
          strictDiscoveryDocumentValidation: this.ssoConfig.strictDiscoveryDocumentValidation,
          loginUrl: this.ssoConfig.loginUrl,
        };

        if (this.ssoConfig.frontTokenEndpoint) {
          config.tokenEndpoint = this.ssoConfig.frontTokenEndpoint + '?id=' + this.ssoConfig.id;
        }

        config.redirectUri = window.location.origin + '/sso';
        if (this.ssoId) config.redirectUri += '/' + this.ssoId;

        if (localStorage.getItem('forcelogin') !== null) {
          config.customQueryParams = { prompt: 'login' };
          localStorage.removeItem('forcelogin');
        }

        this.oauthService.configure(config);

        console.log(config);
        console.log('access token valid:', this.oauthService.hasValidAccessToken());
        console.log('id token valid:', this.oauthService.hasValidIdToken());
        console.log('identity claims', this.oauthService.getIdentityClaims());
        console.log('id token', this.oauthService.getIdToken());
        this.route.queryParams.subscribe((params) => console.log('params', params));

        if (this.ssoConfig !== null && this.ssoConfig.loginUrl !== null && this.ssoConfig.loginUrl !== undefined) {
          this.oauthService
            .tryLoginCodeFlow()
            .then((res) => {
              console.log('tryLoginCodeFlow ok', res);
              if (!this.oauthService.hasValidIdToken()) {
                this.oauthService.initCodeFlow();
              }
              //already done in even listener
              //else this.tokenReceived();
            })
            .catch((err) => {
              L.e(err);
              //this.oauthService.initCodeFlow();
            });
          /*
      if (this.oauthService.hasValidAccessToken() || this.oauthService.hasValidIdToken()) {
        this.tokenReceived();
        return;
      } else this.oauthService.initCodeFlow();
      */
        } else {
          this.oauthService.loadDiscoveryDocumentAndLogin().catch((err) => {
            L.e(err);
            if (err instanceof Object) SsoErrorComponent.last_error = JSON.stringify(err);
            else SsoErrorComponent.last_error = err;
            this.router.navigate(['/sso-error']);
          });
        }

        if (this.oauthService.hasValidIdToken()) this.tokenReceived();

        /*this.oauthService.loadDiscoveryDocument(
      'https://6052ae7d-612b-44b4-8d73-e471023ff979.b2clogin.com/6052ae7d-612b-44b4-8d73-e471023ff979.onmicrosoft.com/b2c_1_sign_in/v2.0/.well-known/openid-configuration'
    );*/
        //this.oauthService.tryLogin();

        //azure b2c https://docs.microsoft.com/fr-fr/azure/active-directory-b2c/openid-connect
        //https://github.com/manfredsteyer/angular-oauth2-oidc/issues/494
        //https://login.microsoftonline.com/thisismydirectoryname.onmicrosoft.com/v2.0/.well-known/openid-configuration

        /*
    // Load information from Auth0 (could also be configured manually)
    oauthService
      .loadDiscoveryDocument()

      // See if the hash fragment contains tokens (when user got redirected back)
      .then(() => oauthService.tryLogin())

      // If we're still not logged in yet, try with a silent refresh:
      .then(() => {
        if (!oauthService.hasValidAccessToken()) {
          return oauthService.silentRefresh();
        }
        return null;
      })

      // Get username, if possible.
      .then(() => {
        if (oauthService.getIdentityClaims()) {
          this.username = oauthService.getIdentityClaims()['name'];
        }
      });
*/
        //oauthService.setupAutomaticSilentRefresh();
        //this.oauthService.loadDiscoveryDocumentAndLogin();
      });
  }
}
