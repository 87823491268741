import { Component, OnChanges, OnInit, SimpleChanges, inject, input } from '@angular/core';
import { EnvService, MsPipe, MultilingualString } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { CriteriaType } from '../../../ic2/entities/CriteriaType';
import { Tag } from '../../../ic2/entities/Tag';
import { AuthService } from '../../../services/auth.service';
import { TagManager } from '../../../services/tag-manager.service';
import { Config } from '../../../tools/Config';
import { RadarData, RadarValue } from '../radar-chart/radar-chart.component';

export interface TagRadarData {
  name: string;
  color: string;
  background: boolean;
  values: TagRadarValue[];
}

export interface TagRadarValue {
  axis: MultilingualString;
  value: number;
  tooltip?: string;
  color: string;
  data?: any;
}
@Component({
  selector: 'hiji-tag-radar-chart',
  templateUrl: './tag-radar-chart.component.html',
  styleUrls: ['./tag-radar-chart.component.scss'],
  standalone: false,
})
export class TagRadarChartComponent implements OnInit, OnChanges {
  private tagManager = inject(TagManager);
  private env = inject<EnvService<Config>>(EnvService);
  private translate = inject(TranslateService);
  private authService = inject(AuthService);
  private msPipe = inject(MsPipe);

  readonly data = input<TagRadarData[]>([]);
  readonly criteriaType = input<CriteriaType>(null);
  readonly showAllSkills = input<boolean>(false);

  reworkedData: RadarData[] = [];
  showChildrenOf: number = null;

  ngOnInit(): void {
    this.buildReworkedData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) this.buildReworkedData();
  }

  getLanguage() {
    return this.authService.modules.frontConfiguration.tagRadarSortDefaultLang ? this.env.configuration.defaultLanguage : this.translate.currentLang;
  }

  sortAxesByLanguage(axes: RadarValue[]): RadarValue[] {
    const language = this.getLanguage();
    return axes.sort((a, b) => {
      const aLabel = a.axis[language];
      const bLabel = b.axis[language];
      return aLabel.localeCompare(bLabel);
    });
  }

  radarTitleClick(data) {
    //console.log('radar title clic', data);
    if (this.tagManager.getSonsOf(data, false).length > 0) {
      this.showChildrenOf = data;
      this.buildReworkedData();
    }
  }
  hasAnyTagsToShow() {
    return this.reworkedData.some((data) => data.values.length > 0);
  }

  canBeExpanded(data) {
    return this.tagManager.getSonsOf(data, false).length > 0;
  }

  resetShowChildren() {
    this.showChildrenOf = null;
    this.buildReworkedData();
  }

  getCurrentTag() {
    return this.tagManager.getTag(this.showChildrenOf);
  }

  buildReworkedData() {
    const showTag = (tag: Tag) =>
      (this.showAllSkills() && this.showChildrenOf === null) ||
      (tag.showInRadar && this.showChildrenOf === null) ||
      this.showChildrenOf === this.tagManager.getTag(tag.idTag).idParent;

    this.reworkedData = this.data().map((data) => {
      return {
        name: data.name,
        color: data.color,
        background: data.background,
        values: data.values
          .filter((value) => showTag(this.tagManager.getTag(value.data)))
          .map((value) => {
            return {
              ...value,
              axis: this.msPipe.transform(value.axis).toUpperCase(),
            } as RadarValue;
          }),
      } as RadarData;
    });

    const nbRootSkillsMinimumToForceShownParents = 2;

    if (
      this.reworkedData.length > 0 &&
      this.reworkedData[0].values.length < nbRootSkillsMinimumToForceShownParents &&
      this.data().length > 0 &&
      this.data()[0].values.length >= nbRootSkillsMinimumToForceShownParents
    ) {
      //console.log('SHOULD SHOW MORE');
      this.reworkedData = this.data().map((data) => {
        //Find tags wihout children in our data
        let tags = data.values.map((value) => this.tagManager.getTag(value.data));
        tags = tags.filter((tag) => {
          return !tags.some((t) => t.idParent === tag.idTag);
        });
        //console.log(tags);

        const tagsWithoutChildren = data.values
          .filter((value) => {
            return tags.some((t) => t.idTag === value.data);
          })
          .map((value) => {
            return {
              ...value,
              axis: this.msPipe.transform(value.axis).toUpperCase(),
            } as RadarValue;
          });
        return {
          name: data.name,
          color: data.color,
          background: data.background,
          values: tagsWithoutChildren,
        } as RadarData;
      });
    }

    //console.log('reworkedData', this.reworkedData);
  }
}
