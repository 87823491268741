// ENTITY fr.hiji.metier.mif.entities.trainingpath.TrainingPathFormationRequirement generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class TrainingPathFormationRequirement {

  idTrainingPath: number | null = 0;
  idFormation: number | null = 0;
  idFormationRequired: number | null = 0;

  constructor(init?:Partial<TrainingPathFormationRequirement>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.trainingpath.TrainingPathFormationRequirement'] = TrainingPathFormationRequirement;

reverseMapping[TrainingPathFormationRequirement.name] = 'fr.hiji.metier.mif.entities.trainingpath.TrainingPathFormationRequirement';

fields['fr.hiji.metier.mif.entities.trainingpath.TrainingPathFormationRequirement']  = {
    idTrainingPath: 'java.lang.Integer',
    idFormation: 'java.lang.Integer',
    idFormationRequired: 'java.lang.Integer'
};
