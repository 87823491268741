// fr.hiji.metier.certif.services.back.CertificationSessionBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { CertificationSessionLiteBODTO } from '../entities/CertificationSessionLiteBODTO';
import { CertificationSessionBODTO } from '../entities/CertificationSessionBODTO';

@Injectable({
  providedIn: 'root',
})
export class CertificationSessionBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getCertificationSessions(idCertification: number, filter: GenericFilter): RpcRequestBuilder<ListWithCount<CertificationSessionLiteBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationSessionBOService.getCertificationSessions';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCertification, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getCertificationSession(id: number): RpcRequestBuilder<CertificationSessionBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationSessionBOService.getCertificationSession';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionBODTO');
  }

  saveCertificationSession(dto: CertificationSessionBODTO): RpcRequestBuilder<CertificationSessionBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationSessionBOService.saveCertificationSession';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionBODTO');
  }

}
