<hiji-wavy-container>
  <div class="container bg-white rounded shadow-2 py-3 m-3 mx-sm-auto" style="z-index: 10; max-width: 600px">
    <div class="row justify-content-center">
      <div class="col-auto">
        <img style="width: 75px" src="assets/logo.png" />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 text-center justify-content-center">
        <div class="col">
          <h4>
            {{ 'common.reset-password.Vous avez oublié votre mot de passe ?' | translate }}
          </h4>
          <p class="mt-2">
            {{ 'common.reset-password-pincode.Demandez à votre manager de vous donner votre code PIN afin de réinitialiser votre compte' | translate }}
          </p>
        </div>
      </div>
    </div>
    <form #f="ngForm" [formGroup]="form" (ngSubmit)="submit()">
      <div class="row mt-4">
        <div class="col">
          <input class="form-control" type="text" placeholder="{{ 'common.login.Login' | translate }}" formControlName="login" autocomplete="username" />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <input
            class="form-control"
            type="text"
            placeholder="{{ 'common.reset-password-pincode.placeholder.Code PIN' | translate }}"
            formControlName="pinCode"
            autocomplete="off"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <div class="position-relative">
              <input
                class="form-control input-with-icon"
                type="password"
                [placeholder]="'common.reset-password-form.Mot de passe' | translate"
                formControlName="password"
                autocomplete="new-password"
                #p
                />
                <div class="input-icon" (click)="p.type === 'password' ? (p.type = 'text') : (p.type = 'password')">
                  <i class="fa-light" [class.fa-eye]="p.type === 'text'" [class.fa-eye-slash]="p.type === 'password'"></i>
                </div>
              </div>
              <div
                class="my-fs-1"
                [class.text-danger]="form.get('password').touched && !form.hasError('required', 'password') && form.hasError('pattern', 'password')"
                [class.text-success]="form.get('password').touched && !form.hasError('required', 'password') && !form.hasError('pattern', 'password')"
                >
                <i class="fa-light fa-info-circle"></i>
                {{
                'common.signup.Au minimum 8 caractères avec au moins 3 des 4 critères suivants : 1 chiffre, 1 majuscule, 1 minuscule, un caractère spécial'
                | translate
                }}
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <div class="position-relative">
                <input
                  class="form-control input-with-icon"
                  type="password"
                  [placeholder]="'common.reset-password-form.Confirmer le mot de passe' | translate"
                  formControlName="passwordConfirm"
                  autocomplete="new-password"
                  #p2
                  />
                  <div class="input-icon" (click)="p2.type === 'password' ? (p2.type = 'text') : (p2.type = 'password')">
                    <i class="fa-light" [class.fa-eye]="p2.type === 'text'" [class.fa-eye-slash]="p2.type === 'password'"></i>
                  </div>
                </div>
              </div>
            </div>
            @if (f.submitted && !form.valid) {
              <div class="row mt-3">
                <div class="col">
                  <div class="errors">
                    <ul class="text-start">
                      @if (form.hasError('required', 'login')) {
                        <li>{{ 'common.login.Le login est requis' | translate }}</li>
                      }
                      @if (form.hasError('required', 'pinCode')) {
                        <li>{{ 'common.reset-password-pincode.errors.Le code PIN est requis' | translate }}</li>
                      }
                      @if (form.hasError('required', 'password')) {
                        <li>{{ 'common.reset-password-form.Le mot de passe est requis' | translate }}</li>
                      }
                      @if (form.hasError('pattern', 'password')) {
                        <li>{{ 'common.signup.Le mot de passe ne respecte pas les contraintes' | translate }}</li>
                      }
                      @if (form.hasError('required', 'passwordConfirm')) {
                        <li>
                          {{ 'common.reset-password-form.Vous devez confirmer le mot de passe' | translate }}
                        </li>
                      }
                      @if (form.hasError('minlength', 'password')) {
                        <li>{{ 'common.reset-password-form.Le mot de passe est trop court' | translate }}</li>
                      }
                      @if (form.hasError('notSame')) {
                        <li>{{ 'common.reset-password-form.Les deux mots de passe ne correspondent pas' | translate }}</li>
                      }
                    </ul>
                  </div>
                </div>
              </div>
            }
            <div class="row justify-content-between mt-5">
              <div class="col-auto">
                <button class="btn btn-outline-light text-uppercase" type="button" [disabled]="loading" routerLink="/login">
                  {{ 'common.global.Retour' | translate }}
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-primary text-uppercase" type="submit" [disabled]="loading">
                  {{ 'common.reset-password.Valider' | translate }}
                  @if (loading) {
                    <i class="fa-light fa-spinner ms-2 spinning"></i>
                  }
                </button>
              </div>
            </div>
          </form>
        </div>
      </hiji-wavy-container>

      <ic2-toastr-container class="position-fixed bottom-0 start-50 translate-middle-x"></ic2-toastr-container>
