// com.ic2.services.CoreServiceI generated by ic2 3.0.0-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { LoginResponse } from '../entities/LoginResponse';
import { UserBundle } from '../entities/UserBundle';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getBundle(): RpcRequestBuilder<UserBundle> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CoreService.getBundle';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.entity.UserBundle');
  }

  disconnect(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CoreService.disconnect';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  login(login: string, password: string): RpcRequestBuilder<LoginResponse> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CoreService.login';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(login, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(password, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.services.dto.LoginResponse');
  }

  loginWithOrigin(login: string, password: string, origin: string): RpcRequestBuilder<LoginResponse> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CoreService.loginWithOrigin';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(login, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(password, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(origin, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.services.dto.LoginResponse');
  }

  disconnectUser(idUser: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CoreService.disconnectUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  getUserModules(): RpcRequestBuilder<string[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CoreService.getUserModules';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  checkUpdate(): RpcRequestBuilder<string> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CoreService.checkUpdate';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.String');
  }

  forceLogin(id: number): RpcRequestBuilder<UserBundle> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CoreService.forceLogin';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.entity.UserBundle');
  }

}
