@if (criteriatype === CriteriaType.BINAIRE) {
  <div class="d-flex h-100 align-items-center">
    <div
      class="text-center p-1 rounded-start d-flex justify-content-center align-items-center"
      [ngStyle]="{ flexGrow: positive, backgroundColor: colors().vertFonce }"
      >
      @if (positive != 0) {
        <div title="{{ positive * 100 }}">{{ positive * 100 | number : '1.0-0' }}%</div>
      }
      @if (positive == 0) {
        <div>&nbsp;</div>
      }
    </div>
    <div
      class="text-center p-1 rounded-end d-flex justify-content-center align-items-center"
      [ngStyle]="{ flexGrow: verynegative, backgroundColor: colors().rouge }"
      >
      @if (verynegative != 0) {
        <div title="{{ verynegative * 100 }}">{{ verynegative * 100 | number : '1.0-0' }}%</div>
      }
      @if (verynegative == 0) {
        <div>&nbsp;</div>
      }
    </div>
  </div>
}

@if (criteriatype === CriteriaType.ECHELLE_5 || criteriatype === CriteriaType.ECHELLE_4) {
  <div class="d-flex h-100 align-items-center">
    <div
      class="text-center p-1 rounded-start d-flex justify-content-center align-items-center"
      [ngStyle]="{ flexGrow: positive, backgroundColor: colors().vertFonce }"
      >
      @if (positive != 0) {
        <div title="{{ positive * 100 }}">{{ positive * 100 | number : '1.0-0' }}%</div>
      }
      @if (positive == 0) {
        <div>&nbsp;</div>
      }
    </div>
    <div
      class="text-center p-1 d-flex justify-content-center align-items-center"
      [ngStyle]="{ flexGrow: neutral, backgroundColor: criteriatype === CriteriaType.ECHELLE_5 ? colors().gris : colors().vertclair }"
      >
      @if (neutral != 0) {
        <div title="{{ neutral * 100 }}">{{ neutral * 100 | number : '1.0-0' }}%</div>
      }
      @if (neutral == 0) {
        <div>&nbsp;</div>
      }
    </div>
    <div class="text-center p-1 d-flex justify-content-center align-items-center" [ngStyle]="{ flexGrow: negative, backgroundColor: colors().orange }">
      @if (negative != 0) {
        <div title="{{ negative * 100 }}">{{ negative * 100 | number : '1.0-0' }}%</div>
      }
      @if (negative == 0) {
        <div>&nbsp;</div>
      }
    </div>
    <div
      class="text-center p-1 rounded-end d-flex justify-content-center align-items-center"
      [ngStyle]="{ flexGrow: verynegative, backgroundColor: colors().rouge }"
      >
      @if (verynegative != 0) {
        <div title="{{ verynegative * 100 }}">{{ verynegative * 100 | number : '1.0-0' }}%</div>
      }
      @if (verynegative == 0) {
        <div>&nbsp;</div>
      }
    </div>
  </div>
}

@if (criteriatype !== CriteriaType.ECHELLE_5 && criteriatype !== CriteriaType.ECHELLE_4 && criteriatype !== CriteriaType.BINAIRE) {
  <div
    class="d-flex h-100 align-items-center"
    >
    {{ criteriatype?.lib }} non géré
  </div>
}
