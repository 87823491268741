<div class="modal-header">
  <h4 class="modal-title" id="modal-title">
    {{ type === 'confirm' ? ('common.modal.confirmation' | translate) : ("common.modal.Point d'attention" | translate) }}
  </h4>
</div>
<div class="modal-body text-center">
  <p style="white-space: break-spaces">
    <strong>{{ message }}</strong>
  </p>
</div>
<div class="modal-footer justify-content-between">
  <button class="btn btn-outline-medium" type="button" (click)="cancel()">
    {{ type == 'confirm' ? ('common.modal.Annuler' | translate) : ('common.modal.Fermer' | translate) }}
  </button>
  @if (type == 'confirm') {
    <button class="btn btn-secondary" type="button" (click)="confirm()">{{ 'common.modal.Confirmer' | translate }}</button>
  }
</div>
