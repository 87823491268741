// ENTITY fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathCertificationBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { TrainingPathCertificationRequirementBODTO } from './TrainingPathCertificationRequirementBODTO';
import { TrainingPathCertification } from './TrainingPathCertification';

export class TrainingPathCertificationBODTO {

  certification: TrainingPathCertification | null = null;
  certificationName: MultilingualString | null = new MultilingualString();
  certificationImageExtension: string | null = '';
  certificationDel: boolean | null = false;
  requirements: TrainingPathCertificationRequirementBODTO[] | null = [];

  constructor(init?:Partial<TrainingPathCertificationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathCertificationBODTO'] = TrainingPathCertificationBODTO;

reverseMapping[TrainingPathCertificationBODTO.name] = 'fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathCertificationBODTO';

fields['fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathCertificationBODTO']  = {
    certification: 'fr.hiji.metier.mif.entities.trainingpath.TrainingPathCertification',
    certificationName: 'com.ic2.sc.MultilingualString',
    certificationImageExtension: 'java.lang.String',
    certificationDel: 'java.lang.Boolean',
    requirements: 'java.util.List<fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathCertificationRequirementBODTO>'
};
