// ENTITY fr.hiji.metier.ema.dto.back.stats.EMACoachStatsDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMACoachStatDTO } from './EMACoachStatDTO';

export class EMACoachStatsDTO {

  nbCoach: number | null = 0;
  nbActiveCoachs: number | null = 0;
  coachs: EMACoachStatDTO[] | null = [];

  constructor(init?:Partial<EMACoachStatsDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.stats.EMACoachStatsDTO'] = EMACoachStatsDTO;

reverseMapping[EMACoachStatsDTO.name] = 'fr.hiji.metier.ema.dto.back.stats.EMACoachStatsDTO';

fields['fr.hiji.metier.ema.dto.back.stats.EMACoachStatsDTO']  = {
    nbCoach: 'java.lang.Integer',
    nbActiveCoachs: 'java.lang.Integer',
    coachs: 'java.util.List<fr.hiji.metier.ema.dto.back.stats.EMACoachStatDTO>'
};
