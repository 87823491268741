// ENTITY fr.hiji.metier.ema.dto.common.EMASkillMapSkillSerieDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class EMASkillMapSkillSerieDTO {

  idTag: number | null = 0;
  tagName: MultilingualString | null = new MultilingualString();
  colorCode: string | null = '';
  showInRadar: boolean | null = false;
  average: number | null = null;

  constructor(init?:Partial<EMASkillMapSkillSerieDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.common.EMASkillMapSkillSerieDTO'] = EMASkillMapSkillSerieDTO;

reverseMapping[EMASkillMapSkillSerieDTO.name] = 'fr.hiji.metier.ema.dto.common.EMASkillMapSkillSerieDTO';

fields['fr.hiji.metier.ema.dto.common.EMASkillMapSkillSerieDTO']  = {
    idTag: 'java.lang.Integer',
    tagName: 'com.ic2.sc.MultilingualString',
    colorCode: 'java.lang.String',
    showInRadar: 'java.lang.Boolean',
    average: 'java.lang.Double'
};
