// fr.hiji.metier.translate.services.Campaign180.ImportExportTranslationCampaign180BOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { LangueUpdatedChangedDTO } from '../entities/LangueUpdatedChangedDTO';

@Injectable({
  providedIn: 'root',
})
export class ImportExportTranslationCampaign180BOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  importCampaign180Translations(): RpcRequestBuilder<LangueUpdatedChangedDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportExportTranslationCampaign180BOService.importCampaign180Translations';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  exportCampaign180Translations(idCampaign: number, langues: string[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportExportTranslationCampaign180BOService.exportCampaign180Translations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(langues, 'java.util.List<java.lang.String>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

}
