import { Component, OnInit, input } from '@angular/core';
import { OrganisationType } from '../../ic2/entities/OrganisationType';

@Component({
    selector: 'hiji-organisation-icon',
    templateUrl: './organisation-icon.component.html',
    styleUrls: ['./organisation-icon.component.scss'],
    standalone: false
})
export class OrganisationIconComponent implements OnInit {
  readonly type = input<OrganisationType>(null);
  OrganisationType: typeof OrganisationType = OrganisationType;

  constructor() {}

  ngOnInit(): void {}
}
