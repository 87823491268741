// ENTITY fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsParticipationByOrganisationBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { OrganisationType } from './OrganisationType';

export class SurveyStatsParticipationByOrganisationBODTO {

  idOrganisation: number | null = 0;
  idParent: number | null = 0;
  name: string | null = '';
  type: OrganisationType | null = null;
  nbTotalUsers: number | null = 0;
  nbAnswer: number | null = 0;
  percentageAnswer: number | null = 0;
  users: string[] | null = [];
  isInMyPerimeter: boolean | null = false;

  constructor(init?:Partial<SurveyStatsParticipationByOrganisationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsParticipationByOrganisationBODTO'] = SurveyStatsParticipationByOrganisationBODTO;

reverseMapping[SurveyStatsParticipationByOrganisationBODTO.name] = 'fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsParticipationByOrganisationBODTO';

fields['fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsParticipationByOrganisationBODTO']  = {
    idOrganisation: 'java.lang.Integer',
    idParent: 'java.lang.Integer',
    name: 'java.lang.String',
    type: 'fr.hiji.metier.reseau.entities.OrganisationType',
    nbTotalUsers: 'java.lang.Integer',
    nbAnswer: 'java.lang.Integer',
    percentageAnswer: 'java.lang.Double',
    users: 'java.util.List<java.lang.String>',
    isInMyPerimeter: 'java.lang.Boolean'
};
