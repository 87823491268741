// ENTITY fr.hiji.metier.user.dto.front.ema.UserActionsEMADTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { EMARDV } from './EMARDV';
import { TeamMemberEMARecapDTO } from './TeamMemberEMARecapDTO';
import { EMA } from './EMA';

export class UserActionsEMADTO {

  isEMATarget: boolean | null = false;
  hadEMAInThePast: boolean | null = false;
  nextRDV: EMARDV | null = null;
  nextRDVManager: UserFuncAvatarDTO | null = null;
  lastUnfinishedEMA: EMA | null = null;
  lastUnfinishedEMAManager: UserFuncAvatarDTO | null = null;
  nbObsDoneSinceLastEMA: number | null = 0;
  nbAutoObsDoneSinceLastEMA: number | null = 0;
  nbCoachObsDoneSinceLastEMA: number | null = 0;
  allowObservationWithoutFirstMeeting: boolean | null = false;
  isCoach: boolean | null = false;
  team: TeamMemberEMARecapDTO[] | null = [];
  allowAutoObs: boolean | null = false;
  hasDraftObs: boolean | null = false;

  constructor(init?:Partial<UserActionsEMADTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.front.ema.UserActionsEMADTO'] = UserActionsEMADTO;

reverseMapping[UserActionsEMADTO.name] = 'fr.hiji.metier.user.dto.front.ema.UserActionsEMADTO';

fields['fr.hiji.metier.user.dto.front.ema.UserActionsEMADTO']  = {
    isEMATarget: 'java.lang.Boolean',
    hadEMAInThePast: 'java.lang.Boolean',
    nextRDV: 'fr.hiji.metier.ema.entities.EMARDV',
    nextRDVManager: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    lastUnfinishedEMA: 'fr.hiji.metier.ema.entities.EMA',
    lastUnfinishedEMAManager: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    nbObsDoneSinceLastEMA: 'java.lang.Integer',
    nbAutoObsDoneSinceLastEMA: 'java.lang.Integer',
    nbCoachObsDoneSinceLastEMA: 'java.lang.Integer',
    allowObservationWithoutFirstMeeting: 'java.lang.Boolean',
    isCoach: 'java.lang.Boolean',
    team: 'java.util.List<fr.hiji.metier.user.dto.front.ema.TeamMemberEMARecapDTO>',
    allowAutoObs: 'java.lang.Boolean',
    hasDraftObs: 'java.lang.Boolean'
};
