// DB ENUM fr.hiji.metier.HijiRight generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:59 UTC 2025

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class HijiRight {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly ADMIN = new HijiRight(1, "Admin", "ADMIN");
  static readonly GESTIONNAIRE = new HijiRight(2, "Gestionnaire", "GESTIONNAIRE");
  static readonly UTILISATEUR = new HijiRight(3, "Utilisateur", "UTILISATEUR");
  static readonly EXTERNE = new HijiRight(4, "Externe", "EXTERNE");
  static readonly CONSULTATION_STATS = new HijiRight(5, "Consultation Stats", "CONSULTATION_STATS");
  static readonly TRADUCTEUR = new HijiRight(6, "Traducteur", "TRADUCTEUR");
  static readonly COACH = new HijiRight(7, "Coach", "COACH");

  static readonly values = [
    HijiRight.ADMIN,
 
    HijiRight.GESTIONNAIRE,
 
    HijiRight.UTILISATEUR,
 
    HijiRight.EXTERNE,
 
    HijiRight.CONSULTATION_STATS,
 
    HijiRight.TRADUCTEUR,
 
    HijiRight.COACH
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of HijiRight.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of HijiRight.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.HijiRight'] = HijiRight;
reverseMapping[HijiRight.name] = 'fr.hiji.metier.HijiRight';
