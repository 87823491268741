// ENTITY fr.hiji.metier.mif.dto.front.trainer.ParticipantAttendanceWithFormationSessionDateDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationSessionDate } from './FormationSessionDate';
import { FormationSessionParticipantAttendance } from './FormationSessionParticipantAttendance';

export class ParticipantAttendanceWithFormationSessionDateDTO {

  idFormationSession: number | null = 0;
  attendanceEnabled: boolean | null = false;
  participantFullAttendance: boolean | null = false;
  idUser: number | null = 0;
  dates: FormationSessionDate[] | null = [];
  attendances: FormationSessionParticipantAttendance[] | null = [];

  constructor(init?:Partial<ParticipantAttendanceWithFormationSessionDateDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.trainer.ParticipantAttendanceWithFormationSessionDateDTO'] = ParticipantAttendanceWithFormationSessionDateDTO;

reverseMapping[ParticipantAttendanceWithFormationSessionDateDTO.name] = 'fr.hiji.metier.mif.dto.front.trainer.ParticipantAttendanceWithFormationSessionDateDTO';

fields['fr.hiji.metier.mif.dto.front.trainer.ParticipantAttendanceWithFormationSessionDateDTO']  = {
    idFormationSession: 'java.lang.Integer',
    attendanceEnabled: 'java.lang.Boolean',
    participantFullAttendance: 'java.lang.Boolean',
    idUser: 'java.lang.Integer',
    dates: 'java.util.List<fr.hiji.metier.mif.entities.session.FormationSessionDate>',
    attendances: 'java.util.List<fr.hiji.metier.mif.entities.session.FormationSessionParticipantAttendance>'
};
