// ENTITY fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFSessionSatisfactionStatDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { OrangeMIFSessionSatisfactionOpenQuestionStatDTO } from './OrangeMIFSessionSatisfactionOpenQuestionStatDTO';

export class OrangeMIFSessionSatisfactionStatDTO {

  idFormationSession: number | null = 0;
  name: string | null = '';
  globalNPS: number | null = null;
  userNPS: number | null = null;
  openQuestionAnswers: OrangeMIFSessionSatisfactionOpenQuestionStatDTO[] | null = [];

  constructor(init?:Partial<OrangeMIFSessionSatisfactionStatDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFSessionSatisfactionStatDTO'] = OrangeMIFSessionSatisfactionStatDTO;

reverseMapping[OrangeMIFSessionSatisfactionStatDTO.name] = 'fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFSessionSatisfactionStatDTO';

fields['fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFSessionSatisfactionStatDTO']  = {
    idFormationSession: 'java.lang.Integer',
    name: 'java.lang.String',
    globalNPS: 'java.lang.Double',
    userNPS: 'java.lang.Double',
    openQuestionAnswers: 'java.util.List<fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFSessionSatisfactionOpenQuestionStatDTO>'
};
