// ENTITY fr.hiji.metier.mif.dto.front.trainer.TrainingPathCertificationDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { TrainingPathCertificationRequirementDTO } from './TrainingPathCertificationRequirementDTO';
import { TrainingPathCertification } from './TrainingPathCertification';

export class TrainingPathCertificationDTO {

  certification: TrainingPathCertification | null = null;
  certificationName: MultilingualString | null = new MultilingualString();
  userMaxIdCertificationSessionAmongAllSessions: number | null = 0;
  userMaxCertificationSessionNameAmongAllSessions: string | null = '';
  certificationImageExtension: string | null = '';
  requirements: TrainingPathCertificationRequirementDTO[] | null = [];
  certified: boolean | null = false;
  order: number | null = 0;

  constructor(init?:Partial<TrainingPathCertificationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.trainer.TrainingPathCertificationDTO'] = TrainingPathCertificationDTO;

reverseMapping[TrainingPathCertificationDTO.name] = 'fr.hiji.metier.mif.dto.front.trainer.TrainingPathCertificationDTO';

fields['fr.hiji.metier.mif.dto.front.trainer.TrainingPathCertificationDTO']  = {
    certification: 'fr.hiji.metier.mif.entities.trainingpath.TrainingPathCertification',
    certificationName: 'com.ic2.sc.MultilingualString',
    userMaxIdCertificationSessionAmongAllSessions: 'java.lang.Integer',
    userMaxCertificationSessionNameAmongAllSessions: 'java.lang.String',
    certificationImageExtension: 'java.lang.String',
    requirements: 'java.util.List<fr.hiji.metier.mif.dto.front.trainer.TrainingPathCertificationRequirementDTO>',
    certified: 'java.lang.Boolean',
    order: 'java.lang.Integer'
};
