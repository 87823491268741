// ENTITY fr.hiji.metier.ema.dto.back.stats.EMACustomStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMACustomOGSkillStatBODTO } from './EMACustomOGSkillStatBODTO';

export class EMACustomStatsBODTO {

  nbUserWith1GestureCreated: number | null = 0;
  nbManagerWith1GestureCreated: number | null = 0;
  nbManager: number | null = 0;
  customOgSkillStats: EMACustomOGSkillStatBODTO[] | null = [];

  constructor(init?:Partial<EMACustomStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.stats.EMACustomStatsBODTO'] = EMACustomStatsBODTO;

reverseMapping[EMACustomStatsBODTO.name] = 'fr.hiji.metier.ema.dto.back.stats.EMACustomStatsBODTO';

fields['fr.hiji.metier.ema.dto.back.stats.EMACustomStatsBODTO']  = {
    nbUserWith1GestureCreated: 'java.lang.Integer',
    nbManagerWith1GestureCreated: 'java.lang.Integer',
    nbManager: 'java.lang.Integer',
    customOgSkillStats: 'java.util.List<fr.hiji.metier.ema.dto.back.stats.EMACustomOGSkillStatBODTO>'
};
