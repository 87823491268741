// ENTITY fr.hiji.metier.user.dto.UserLiteDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class UserLiteDTO {

  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  del: boolean | null = false;

  constructor(init?:Partial<UserLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.UserLiteDTO'] = UserLiteDTO;

reverseMapping[UserLiteDTO.name] = 'fr.hiji.metier.user.dto.UserLiteDTO';

fields['fr.hiji.metier.user.dto.UserLiteDTO']  = {
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    del: 'java.lang.Boolean'
};
