// ENTITY fr.hiji.metier.reporting.dto.survey.SurveyReportGlobalDataDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class SurveyReportGlobalDataDTO {

  nb: number | null = 0;
  avg: number | null = null;
  nbValue1: number | null = 0;
  nbValue2: number | null = 0;
  nbValue3: number | null = 0;
  nbValue4: number | null = 0;
  nbValue5: number | null = 0;
  positive: number | null = 0;
  neutral: number | null = 0;
  negative: number | null = 0;
  verynegative: number | null = 0;

  constructor(init?:Partial<SurveyReportGlobalDataDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.survey.SurveyReportGlobalDataDTO'] = SurveyReportGlobalDataDTO;

reverseMapping[SurveyReportGlobalDataDTO.name] = 'fr.hiji.metier.reporting.dto.survey.SurveyReportGlobalDataDTO';

fields['fr.hiji.metier.reporting.dto.survey.SurveyReportGlobalDataDTO']  = {
    nb: 'java.lang.Integer',
    avg: 'java.lang.Double',
    nbValue1: 'java.lang.Integer',
    nbValue2: 'java.lang.Integer',
    nbValue3: 'java.lang.Integer',
    nbValue4: 'java.lang.Integer',
    nbValue5: 'java.lang.Integer',
    positive: 'java.lang.Double',
    neutral: 'java.lang.Double',
    negative: 'java.lang.Double',
    verynegative: 'java.lang.Double'
};
