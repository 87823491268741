// fr.hiji.metier.conf.services.back.submodules.GlobalModuleCfgBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class GlobalModuleCfgBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  updateEmailAssistanceClient(email: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GlobalModuleCfgBOService.updateEmailAssistanceClient';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateSignupEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GlobalModuleCfgBOService.updateSignupEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateModeMaintenance(maintenance: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GlobalModuleCfgBOService.updateModeMaintenance';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(maintenance, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateNbMiniUsersStats(nbMiniUsersStats: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GlobalModuleCfgBOService.updateNbMiniUsersStats';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(nbMiniUsersStats, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateManualHierarchicalManagementEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GlobalModuleCfgBOService.updateManualHierarchicalManagementEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateManualHierarchicalManagementSkipInvitationProcess(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GlobalModuleCfgBOService.updateManualHierarchicalManagementSkipInvitationProcess';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateOpinionEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GlobalModuleCfgBOService.updateOpinionEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateUseCampaignAccessControl(use: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GlobalModuleCfgBOService.updateUseCampaignAccessControl';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(use, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateOpinionDataCollectionEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GlobalModuleCfgBOService.updateOpinionDataCollectionEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateEmailUserImportEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GlobalModuleCfgBOService.updateEmailUserImportEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  changeLogo(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GlobalModuleCfgBOService.changeLogo';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  deleteLogo(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GlobalModuleCfgBOService.deleteLogo';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateLocalAccountLoginEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GlobalModuleCfgBOService.updateLocalAccountLoginEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
