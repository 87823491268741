// ENTITY fr.hiji.metier.certif.dto.back.stats.CertificationStatsTagBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class CertificationStatsTagBODTO {

  idTag: number | null = 0;
  value: number | null = 0;

  constructor(init?:Partial<CertificationStatsTagBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.CertificationStatsTagBODTO'] = CertificationStatsTagBODTO;

reverseMapping[CertificationStatsTagBODTO.name] = 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsTagBODTO';

fields['fr.hiji.metier.certif.dto.back.stats.CertificationStatsTagBODTO']  = {
    idTag: 'java.lang.Integer',
    value: 'java.lang.Float'
};
