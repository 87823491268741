import { Injectable, inject } from '@angular/core';
import { EnvService } from '@ic2/ic2-lib';
import { Config } from '../tools/Config';

export class Client {
  private name: string;
  private constructor(name: string) {
    this.name = name;
  }

  getName(): string {
    return this.name;
  }

  static EDENRED = new Client('edenred');
  static RECETTE = new Client('recette');
  static DEMO1 = new Client('demo1');
  static DEMO2 = new Client('demo2');
  static DEMO3 = new Client('demo3');
  static NOUS = new Client('nous');
  static INTERMARCHE_NETTO = new Client('intermarche-netto');
  static FNAC = new Client('fnac');
  static DARTY = new Client('darty');
  static SKILLSUP = new Client('skillsup');
  static SKILLSUP_FASHION = new Client('skillsup-fashion');
  static DIOR = new Client('dior');
  static ARMATIS = new Client('armatis');
  static LACOSTE = new Client('lacoste');
  static APRR = new Client('aprr');
  static ORANGE = new Client('orange');
}

@Injectable({
  providedIn: 'root',
})
export class ClientEnv {
  private env = inject<EnvService<Config>>(EnvService);

  /** @deprecated */
  EDENRED = Client.EDENRED;
  /** @deprecated */
  RECETTE = Client.RECETTE;
  /** @deprecated */
  DEMO1 = Client.DEMO1;
  /** @deprecated */
  DEMO2 = Client.DEMO2;
  /** @deprecated */
  DEMO3 = Client.DEMO3;
  /** @deprecated */
  NOUS = Client.NOUS;
  /** @deprecated */
  INTERMARCHE_NETTO = Client.INTERMARCHE_NETTO;
  /** @deprecated */
  FNAC = Client.FNAC;
  /** @deprecated */
  DARTY = Client.DARTY;
  /** @deprecated */
  SKILLSUP = Client.SKILLSUP;
  /** @deprecated */
  SKILLSUP_FASHION = Client.SKILLSUP_FASHION;
  /** @deprecated */
  DIOR = Client.DIOR;
  /** @deprecated */
  ARMATIS = Client.ARMATIS;
  /** @deprecated */
  LACOSTE = Client.LACOSTE;

  //environments
  private PRODUCTIONENV = 'production';
  private RECETTEENV = 'recette';
  private DEVELOPPMENTENV = 'dev';
  private PREPRODENV = 'preprod';

  /**
   *  @returns true if the current environment is RECETTE
   */

  isRecetteEnv(): boolean {
    return this.env.configuration.environment === this.RECETTEENV;
  }

  /**
   * @returns true if the current environment is PRODUCTION
   */
  isProductionEnv(): boolean {
    return this.env.configuration.environment === this.PRODUCTIONENV;
  }

  /**
   * @returns true if the current environment is DEVELOPMENT
   */

  isDeveloppmentEnv(): boolean {
    return this.env.configuration.environment === this.DEVELOPPMENTENV;
  }

  /**
   * @returns true if the current environment is preProd
   */

  isPreProdEnv(): boolean {
    return this.env.configuration.environment === this.PREPRODENV;
  }

  is(client: Client): boolean {
    return this.env.configuration.client === client.getName();
  }

  isDemo(): boolean {
    return (
      this.env.configuration.client === Client.DEMO1.getName() ||
      this.env.configuration.client === Client.DEMO2.getName() ||
      this.env.configuration.client === Client.DEMO3.getName()
    );
  }

  isAnyOf(...args: Client[]): boolean {
    return args.some((client) => this.is(client));
  }

  getClientEnv(): ClientEnv {
    return this;
  }
}
