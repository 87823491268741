import { Component, OnInit, inject, input } from '@angular/core';
import { AvatarService } from '../../services/avatar.service';

@Component({
    selector: 'hiji-user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss'],
    standalone: false
})
export class UserAvatarComponent implements OnInit {
  avatarService = inject(AvatarService);

  readonly idUser = input<number>(undefined);
  readonly avatarExtension = input<string>(undefined);
  readonly defaultBlueAvatar = input<boolean>(false);
  readonly rounded = input<boolean>(true);

  ngOnInit(): void {}
}
