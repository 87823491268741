// ENTITY fr.hiji.metier.mif.dto.front.trainer.AttendanceDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationSessionDate } from './FormationSessionDate';
import { ParticipantAttendanceDTO } from './ParticipantAttendanceDTO';
import { FormationSessionDatePeriod } from './FormationSessionDatePeriod';

export class AttendanceDTO {

  dates: FormationSessionDate[] | null = [];
  participants: ParticipantAttendanceDTO[] | null = [];
  attendanceEnabled: boolean | null = false;
  openedAttendanceDate: Date | null = null;
  openedAttendancePeriod: FormationSessionDatePeriod | null = null;

  constructor(init?:Partial<AttendanceDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.trainer.AttendanceDTO'] = AttendanceDTO;

reverseMapping[AttendanceDTO.name] = 'fr.hiji.metier.mif.dto.front.trainer.AttendanceDTO';

fields['fr.hiji.metier.mif.dto.front.trainer.AttendanceDTO']  = {
    dates: 'java.util.List<fr.hiji.metier.mif.entities.session.FormationSessionDate>',
    participants: 'java.util.List<fr.hiji.metier.mif.dto.front.trainer.ParticipantAttendanceDTO>',
    attendanceEnabled: 'java.lang.Boolean',
    openedAttendanceDate: 'java.time.LocalDate',
    openedAttendancePeriod: 'fr.hiji.metier.mif.entities.session.FormationSessionDatePeriod'
};
