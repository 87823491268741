<div class="align-items-center d-flex flex-column justify-content-center" style="min-height: 100vh !important">
  <img style="height: 100px" src="assets/logo.png" />
  @if (ssoConfig === null) {
    <h1 class="mt-4">{{ 'common.login-sso.Connexion à hiji en cours' | translate }}</h1>
  }
  @if (ssoConfig !== null) {
    <h1 class="mt-4">{{ 'common.login-sso.Connexion à hiji via \{\{name\}\} en cours' | translate : { name: ssoConfig.name } }}</h1>
  }
  @if (!maintenanceMode) {
    <i class="fa-light fa-spinner spinning fs-1 mt-3"></i>
  }
  @if (maintenanceMode) {
    <div class="mt-3 my-fs-5">
      {{ 'common.login-sso.Une maintenance est en cours' | translate }}
    </div>
  }
  @if (showMsg && !maintenanceMode) {
    <div class="mt-3">
      {{ 'common.login-sso.Trop long ?' | translate }}
      <a href="javascript:window.location.reload(true)">{{ "common.login-sso.Essayez d'actualiser la page" | translate }}</a>
    </div>
  }
  <img class="mt-4" style="height: 100px" src="assets/hiji_logo.png" />
</div>
