// fr.hiji.metier.ema.services.front.EMAObservationServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { EMAObservationSaveResultDTO } from '../entities/EMAObservationSaveResultDTO';
import { EMAObservationDTO } from '../entities/EMAObservationDTO';

@Injectable({
  providedIn: 'root',
})
export class EMAObservationService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getObservation(idUser: number): RpcRequestBuilder<EMAObservationDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAObservationService.getObservation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.dto.front.observation.EMAObservationDTO');
  }

  saveObservation(emaObservationDTO: EMAObservationDTO): RpcRequestBuilder<EMAObservationSaveResultDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAObservationService.saveObservation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(emaObservationDTO, 'fr.hiji.metier.ema.dto.front.observation.EMAObservationDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.dto.front.observation.EMAObservationSaveResultDTO');
  }

}
