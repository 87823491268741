// ENTITY fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFTagStatDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class OrangeMIFTagStatDTO {

  tagName: MultilingualString | null = new MultilingualString();
  globalAvg: number | null = null;
  userAvg: number | null = null;

  constructor(init?:Partial<OrangeMIFTagStatDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFTagStatDTO'] = OrangeMIFTagStatDTO;

reverseMapping[OrangeMIFTagStatDTO.name] = 'fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFTagStatDTO';

fields['fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFTagStatDTO']  = {
    tagName: 'com.ic2.sc.MultilingualString',
    globalAvg: 'java.lang.Double',
    userAvg: 'java.lang.Double'
};
