// ENTITY fr.hiji.metier.conf.dto.back.submodules.ModuleGlobalBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleGlobalBODTO {

  manualHierarchicalManagementEnabled: boolean | null = false;
  manualHierarchicalManagementSkipInvitationProcess: boolean | null = false;
  signupEnabled: boolean | null = false;
  localAccountLoginEnabled: boolean | null = false;
  opinionEnabled: boolean | null = false;
  nbMiniUsersStats: number | null = 0;
  emailAssistanceClient: string | null = '';
  modeMaintenance: boolean | null = false;
  useCampaignAccessControl: boolean | null = false;
  opinionDataCollectionEnabled: boolean | null = false;
  emailUserImportEnabled: boolean | null = false;

  constructor(init?:Partial<ModuleGlobalBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.back.submodules.ModuleGlobalBODTO'] = ModuleGlobalBODTO;

reverseMapping[ModuleGlobalBODTO.name] = 'fr.hiji.metier.conf.dto.back.submodules.ModuleGlobalBODTO';

fields['fr.hiji.metier.conf.dto.back.submodules.ModuleGlobalBODTO']  = {
    manualHierarchicalManagementEnabled: 'java.lang.Boolean',
    manualHierarchicalManagementSkipInvitationProcess: 'java.lang.Boolean',
    signupEnabled: 'java.lang.Boolean',
    localAccountLoginEnabled: 'java.lang.Boolean',
    opinionEnabled: 'java.lang.Boolean',
    nbMiniUsersStats: 'java.lang.Integer',
    emailAssistanceClient: 'java.lang.String',
    modeMaintenance: 'java.lang.Boolean',
    useCampaignAccessControl: 'java.lang.Boolean',
    opinionDataCollectionEnabled: 'java.lang.Boolean',
    emailUserImportEnabled: 'java.lang.Boolean'
};
