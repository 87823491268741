// ENTITY fr.hiji.metier.certif.dto.back.stats.interview.InterviewStatsQuickQuestionBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class InterviewStatsQuickQuestionBODTO {

  question: string | null = '';
  value: number | null = 0;
  idCriteria: number | null = 0;

  constructor(init?:Partial<InterviewStatsQuickQuestionBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.interview.InterviewStatsQuickQuestionBODTO'] = InterviewStatsQuickQuestionBODTO;

reverseMapping[InterviewStatsQuickQuestionBODTO.name] = 'fr.hiji.metier.certif.dto.back.stats.interview.InterviewStatsQuickQuestionBODTO';

fields['fr.hiji.metier.certif.dto.back.stats.interview.InterviewStatsQuickQuestionBODTO']  = {
    question: 'java.lang.String',
    value: 'java.lang.Integer',
    idCriteria: 'java.lang.Integer'
};
