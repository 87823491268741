import { Component, Input, output, input } from '@angular/core';

@Component({
  selector: 'hiji-smart-pagination',
  templateUrl: './smart-pagination.component.html',
  styleUrls: ['./smart-pagination.component.scss'],
  standalone: false,
})
export class SmartPaginationComponent {
  @Input() page: number = 1;
  readonly collectionSize = input<number>(undefined);
  readonly maxSize = input<number>(10);
  readonly rotate = input<boolean>(true);
  readonly loading = input<boolean>(false);
  readonly nbPossiblePerPage = input<number[]>([10, 20, 50]);
  @Input() pageSize: number;
  readonly pageChange = output<number>();
  readonly pageSizeChange = output<number>();

  onPageChange(newPage: number): void {
    this.page = newPage;
    this.pageChange.emit(this.page);
  }

  onPageSizeChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const newPageSize = parseInt(selectElement.value, 10);
    this.pageSize = newPageSize;
    this.pageSizeChange.emit(this.pageSize);
  }

  constructor() {}
}
