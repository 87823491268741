// ENTITY fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationEventAnimationParticipation } from './FormationEventAnimationParticipation';
import { FormationEventObservationObservableGesture } from './FormationEventObservationObservableGesture';
import { FormationEventObservation } from './FormationEventObservation';
import { FormationEventAnimationOGSDTO } from './FormationEventAnimationOGSDTO';
import { FormationEventSatisfactionOgsOrderDTO } from './FormationEventSatisfactionOgsOrderDTO';
import { Criteria } from './Criteria';
import { FormationEventSatisfactionOGDTO } from './FormationEventSatisfactionOGDTO';

export class FormationEventSatisfactionDTO {

  oogs: FormationEventObservationObservableGesture[] | null = [];
  globalIdOgs: FormationEventSatisfactionOgsOrderDTO[] | null = [];
  animationIdOgs: FormationEventAnimationOGSDTO[] | null = [];
  ogs: FormationEventSatisfactionOGDTO[] | null = [];
  participations: FormationEventAnimationParticipation[] | null = [];
  criterias: Criteria[] | null = [];
  idFormationEvent: number | null = 0;
  startAnsweringDate: Date | null = null;
  observation: FormationEventObservation | null = null;

  constructor(init?:Partial<FormationEventSatisfactionDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionDTO'] = FormationEventSatisfactionDTO;

reverseMapping[FormationEventSatisfactionDTO.name] = 'fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionDTO';

fields['fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionDTO']  = {
    oogs: 'java.util.List<fr.hiji.metier.mif.entities.event.observation.FormationEventObservationObservableGesture>',
    globalIdOgs: 'java.util.List<fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionOgsOrderDTO>',
    animationIdOgs: 'java.util.List<fr.hiji.metier.mif.dto.front.event.FormationEventAnimationOGSDTO>',
    ogs: 'java.util.List<fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionOGDTO>',
    participations: 'java.util.List<fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationParticipation>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    idFormationEvent: 'java.lang.Integer',
    startAnsweringDate: 'java.time.Instant',
    observation: 'fr.hiji.metier.mif.entities.event.observation.FormationEventObservation'
};
