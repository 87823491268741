// ENTITY fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsScoreByOrganisationBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { OrganisationType } from './OrganisationType';

export class SurveyStatsScoreByOrganisationBODTO {

  idOrganisation: number | null = 0;
  name: string | null = '';
  idParent: number | null = 0;
  type: OrganisationType | null = null;
  nbAnswers: number | null = 0;
  nbUser: number | null = 0;
  avg: number | null = null;
  nbOGObs: number | null = 0;
  nbValue1: number | null = 0;
  nbValue2: number | null = 0;
  nbValue3: number | null = 0;
  nbValue4: number | null = 0;
  nbValue5: number | null = 0;
  nps: number | null = 0;

  constructor(init?:Partial<SurveyStatsScoreByOrganisationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsScoreByOrganisationBODTO'] = SurveyStatsScoreByOrganisationBODTO;

reverseMapping[SurveyStatsScoreByOrganisationBODTO.name] = 'fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsScoreByOrganisationBODTO';

fields['fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsScoreByOrganisationBODTO']  = {
    idOrganisation: 'java.lang.Integer',
    name: 'java.lang.String',
    idParent: 'java.lang.Integer',
    type: 'fr.hiji.metier.reseau.entities.OrganisationType',
    nbAnswers: 'java.lang.Integer',
    nbUser: 'java.lang.Integer',
    avg: 'java.lang.Double',
    nbOGObs: 'java.lang.Integer',
    nbValue1: 'java.lang.Integer',
    nbValue2: 'java.lang.Integer',
    nbValue3: 'java.lang.Integer',
    nbValue4: 'java.lang.Integer',
    nbValue5: 'java.lang.Integer',
    nps: 'java.lang.Double'
};
