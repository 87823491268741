// ENTITY fr.hiji.metier.conf.dto.front.submodules.ModuleSurveyDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleSurveyDTO {

  enabled: boolean | null = false;
  changeBONamesToSurveyMode: boolean | null = false;

  constructor(init?:Partial<ModuleSurveyDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.front.submodules.ModuleSurveyDTO'] = ModuleSurveyDTO;

reverseMapping[ModuleSurveyDTO.name] = 'fr.hiji.metier.conf.dto.front.submodules.ModuleSurveyDTO';

fields['fr.hiji.metier.conf.dto.front.submodules.ModuleSurveyDTO']  = {
    enabled: 'java.lang.Boolean',
    changeBONamesToSurveyMode: 'java.lang.Boolean'
};
