import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { L, RpcError } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { RegisterService } from '../../ic2/services/RegisterService';
import { AuthService } from '../../services/auth.service';
import { ResetPasswordService } from '../../services/reset-password.service';

@Component({
    selector: 'hiji-mail-auth',
    templateUrl: './mail-auth.component.html',
    styleUrls: ['./mail-auth.component.scss'],
    standalone: false
})
export class MailAuthComponent implements OnInit {
  private fb = inject(FormBuilder);
  private authService = inject(AuthService);
  private registerService = inject(RegisterService);
  private translate = inject(TranslateService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private resetPasswordService = inject(ResetPasswordService);

  form: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
  });
  error: string = null;
  loading: boolean = false;
  done: boolean = false;
  backoffice: boolean = false;
  constructor() {
    this.backoffice = this.route.snapshot.data['backoffice'] ?? false;
  }

  ngOnInit(): void {
    this.authService.isLoggedIn().subscribe((data) => {
      if (data) this.router.navigate(['/']);
      else this.authService.logout();
    });
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    if (this.loading) return;
    this.form.disable();
    this.loading = true;
    this.registerService.askForLink(this.form.value.email, this.backoffice).subscribe(
      (data) => {
        this.loading = false;
        this.done = true;
      },
      (err: RpcError) => {
        this.form.enable();
        this.loading = false;
        this.error = this.translate.instant('common.mail-auth.Une erreur est survenue');
        L.e(err);
      }
    );
  }
}
