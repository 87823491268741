// ENTITY fr.hiji.metier.referential.entities.quiz.QuestionFlatTag generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class QuestionFlatTag {

  idQuestion: number | null = 0;
  idTag: number | null = 0;
  assigned: boolean | null = false;

  constructor(init?:Partial<QuestionFlatTag>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.entities.quiz.QuestionFlatTag'] = QuestionFlatTag;

reverseMapping[QuestionFlatTag.name] = 'fr.hiji.metier.referential.entities.quiz.QuestionFlatTag';

fields['fr.hiji.metier.referential.entities.quiz.QuestionFlatTag']  = {
    idQuestion: 'java.lang.Integer',
    idTag: 'java.lang.Integer',
    assigned: 'java.lang.Boolean'
};
