// ENTITY fr.hiji.metier.mif.dto.front.trainer.FormationQuizLiteDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { FormationSessionQuizAvailable } from './FormationSessionQuizAvailable';
import { FormationSessionEmailConfig } from './FormationSessionEmailConfig';

export class FormationQuizLiteDTO {

  formationSessionQuizAvailable: FormationSessionQuizAvailable | null = null;
  name: MultilingualString | null = new MultilingualString();
  nbAnswers: number | null = 0;
  deployed: boolean | null = false;
  emailConfigs: FormationSessionEmailConfig[] | null = [];

  constructor(init?:Partial<FormationQuizLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.trainer.FormationQuizLiteDTO'] = FormationQuizLiteDTO;

reverseMapping[FormationQuizLiteDTO.name] = 'fr.hiji.metier.mif.dto.front.trainer.FormationQuizLiteDTO';

fields['fr.hiji.metier.mif.dto.front.trainer.FormationQuizLiteDTO']  = {
    formationSessionQuizAvailable: 'fr.hiji.metier.mif.entities.session.FormationSessionQuizAvailable',
    name: 'com.ic2.sc.MultilingualString',
    nbAnswers: 'java.lang.Integer',
    deployed: 'java.lang.Boolean',
    emailConfigs: 'java.util.List<fr.hiji.metier.mif.entities.session.FormationSessionEmailConfig>'
};
