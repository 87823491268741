// ENTITY fr.hiji.metier.mif.dto.front.trainer.TrainingPathFormationRequirementDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { TrainingPathFormationRequirement } from './TrainingPathFormationRequirement';
import { FormationState } from './FormationState';
import { FormationDoneByUser } from './FormationDoneByUser';

export class TrainingPathFormationRequirementDTO {

  trainingPathFormationRequirement: TrainingPathFormationRequirement | null = null;
  formationName: MultilingualString | null = new MultilingualString();
  formationImageExtension: string | null = '';
  formationColorCode: string | null = '';
  formationDoneByUser: FormationDoneByUser | null = null;
  state: FormationState | null = null;
  userMaxDateAmongAllSessions: Date | null = null;

  constructor(init?:Partial<TrainingPathFormationRequirementDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.trainer.TrainingPathFormationRequirementDTO'] = TrainingPathFormationRequirementDTO;

reverseMapping[TrainingPathFormationRequirementDTO.name] = 'fr.hiji.metier.mif.dto.front.trainer.TrainingPathFormationRequirementDTO';

fields['fr.hiji.metier.mif.dto.front.trainer.TrainingPathFormationRequirementDTO']  = {
    trainingPathFormationRequirement: 'fr.hiji.metier.mif.entities.trainingpath.TrainingPathFormationRequirement',
    formationName: 'com.ic2.sc.MultilingualString',
    formationImageExtension: 'java.lang.String',
    formationColorCode: 'java.lang.String',
    formationDoneByUser: 'fr.hiji.metier.mif.entities.formation.FormationDoneByUser',
    state: 'fr.hiji.metier.mif.entities.formation.FormationState',
    userMaxDateAmongAllSessions: 'java.time.LocalDate'
};
