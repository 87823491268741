// ENTITY fr.hiji.metier.ema.dto.front.repositoryinfo.EMARepositoryInfoDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { EMARepositoryInfoOGDTO } from './EMARepositoryInfoOGDTO';
import { EMAUserOGC } from './EMAUserOGC';

export class EMARepositoryInfoDTO {

  idRepository: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  observableGestures: EMARepositoryInfoOGDTO[] | null = [];
  complementaryObservableGestures: EMARepositoryInfoOGDTO[] | null = [];
  observableGesturesCustom: EMAUserOGC[] | null = [];

  constructor(init?:Partial<EMARepositoryInfoDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.repositoryinfo.EMARepositoryInfoDTO'] = EMARepositoryInfoDTO;

reverseMapping[EMARepositoryInfoDTO.name] = 'fr.hiji.metier.ema.dto.front.repositoryinfo.EMARepositoryInfoDTO';

fields['fr.hiji.metier.ema.dto.front.repositoryinfo.EMARepositoryInfoDTO']  = {
    idRepository: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    observableGestures: 'java.util.List<fr.hiji.metier.ema.dto.front.repositoryinfo.EMARepositoryInfoOGDTO>',
    complementaryObservableGestures: 'java.util.List<fr.hiji.metier.ema.dto.front.repositoryinfo.EMARepositoryInfoOGDTO>',
    observableGesturesCustom: 'java.util.List<fr.hiji.metier.ema.entities.EMAUserOGC>'
};
