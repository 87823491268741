// DB ENUM fr.hiji.metier.ema.entities.EMACoachingActionRemoveReason generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:59 UTC 2025

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class EMACoachingActionRemoveReason {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly SKILL_ACQUIRED = new EMACoachingActionRemoveReason(1, "Skill acquired", "SKILL_ACQUIRED");
  static readonly NOT_NECESSARY_ANYMORE = new EMACoachingActionRemoveReason(2, "Not necessary anymore", "NOT_NECESSARY_ANYMORE");
  static readonly RESULTS_IN_PROGRESS = new EMACoachingActionRemoveReason(3, "Results in progress", "RESULTS_IN_PROGRESS");
  static readonly SUBSTITUTED_BY_ANOTHER_COACHING_ACTION = new EMACoachingActionRemoveReason(4, "Substituted by another coaching action", "SUBSTITUTED_BY_ANOTHER_COACHING_ACTION");
  static readonly OTHER = new EMACoachingActionRemoveReason(20, "Other", "OTHER");

  static readonly values = [
    EMACoachingActionRemoveReason.SKILL_ACQUIRED,
 
    EMACoachingActionRemoveReason.NOT_NECESSARY_ANYMORE,
 
    EMACoachingActionRemoveReason.RESULTS_IN_PROGRESS,
 
    EMACoachingActionRemoveReason.SUBSTITUTED_BY_ANOTHER_COACHING_ACTION,
 
    EMACoachingActionRemoveReason.OTHER
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of EMACoachingActionRemoveReason.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of EMACoachingActionRemoveReason.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.ema.entities.EMACoachingActionRemoveReason'] = EMACoachingActionRemoveReason;
reverseMapping[EMACoachingActionRemoveReason.name] = 'fr.hiji.metier.ema.entities.EMACoachingActionRemoveReason';
