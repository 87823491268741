// fr.hiji.metier.referential.services.back.TagBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, MultilingualString } from '@ic2/ic2-lib';
import { TagBODTO } from '../entities/TagBODTO';
import { Tag } from '../entities/Tag';

@Injectable({
  providedIn: 'root',
})
export class TagBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getTag(idTag: number): RpcRequestBuilder<TagBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TagBOService.getTag';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idTag, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.referential.dto.TagBODTO');
  }

  tagNameAlreadyExists(name: MultilingualString, idTag: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TagBOService.tagNameAlreadyExists';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'com.ic2.sc.MultilingualString', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idTag, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  saveTag(tag: TagBODTO): RpcRequestBuilder<TagBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TagBOService.saveTag';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(tag, 'fr.hiji.metier.referential.dto.TagBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.referential.dto.TagBODTO');
  }

  updateTags(tagList: Tag[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TagBOService.updateTags';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(tagList, 'java.util.List<fr.hiji.metier.referential.entities.Tag>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
