// fr.hiji.metier.ema.services.common.EMASkillMapServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { EMASkillMapRepositoryDTO } from '../entities/EMASkillMapRepositoryDTO';
import { EMASkillMapFilterDTO } from '../entities/EMASkillMapFilterDTO';
import { EMASkillMapDTO } from '../entities/EMASkillMapDTO';

@Injectable({
  providedIn: 'root',
})
export class EMASkillMapService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getSkillMap(filter: EMASkillMapFilterDTO): RpcRequestBuilder<EMASkillMapDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMASkillMapService.getSkillMap';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.ema.dto.common.EMASkillMapFilterDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.dto.common.EMASkillMapDTO');
  }

  getRepositories(idUser: number): RpcRequestBuilder<EMASkillMapRepositoryDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMASkillMapService.getRepositories';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
