import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-notfound',
    templateUrl: './notfound.component.html',
    styleUrls: ['./notfound.component.scss'],
    standalone: false
})
export class NotFoundComponent {
  route = inject(ActivatedRoute);

  url;
  constructor() {
    this.url = window.location.pathname;
  }
}
