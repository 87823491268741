import { Component, Input, inject, output, input } from '@angular/core';
import { EnvService, MultilingualString } from '@ic2/ic2-lib';
import { HijiBundle } from '../../../ic2/entities/HijiBundle';
import { TranslationBOService } from '../../../ic2/services/TranslationBOService';
import { AuthService } from '../../../services/auth.service';
import { Config } from '../../../tools/Config';

@Component({
    selector: 'hiji-translate-textarea',
    templateUrl: './translate-textarea.component.html',
    styleUrl: './translate-textarea.component.scss',
    standalone: false
})
export class TranslateTextareaComponent {
  private config = inject<EnvService<Config>>(EnvService);
  private authService = inject(AuthService);
  private translationBOService = inject(TranslationBOService);

  @Input() sourceLang: string;
  @Input() destinationLang: string;
  readonly textAreaRows = input<number>(5);
  readonly title = input<string>(undefined);

  loadingTrad: boolean = false;

  private _translation: MultilingualString;

  @Input()
  get translation(): MultilingualString {
    return this._translation;
  }
  set translation(value: MultilingualString) {
    this._translation = value;
    this.translationChange.emit(value);
  }

  readonly translationChange = output<MultilingualString>();

  translate() {
    if (this.translation.obj[this.sourceLang] == null) return;
    this.loadingTrad = true;
    this.checkLang();
    this.translationBOService
      .translateAPI(this.translation.obj[this.sourceLang], this.sourceLang, this.destinationLang)
      .onErrorUseDefault()
      .onErrorAlwaysDo(() => (this.loadingTrad = false))
      .execute((data) => {
        this.translation.obj[this.destinationLang] = data;
        this.loadingTrad = false;
      });
  }

  checkLang() {
    if (this.destinationLang == null) this.destinationLang = (this.authService.userBundle.data as HijiBundle).hijiUser.locale;

    if (this.sourceLang == null) this.sourceLang = this.config.configuration.defaultLanguage;
  }
}
