// ENTITY fr.hiji.metier.teamboard.dto.TeamBoardFileDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { HijiFile } from './HijiFile';
import { TeamBoardFile } from './TeamBoardFile';

export class TeamBoardFileDTO {

  teamBoardFile: TeamBoardFile | null = null;
  hijiFile: HijiFile | null = null;
  token: string | null = '';

  constructor(init?:Partial<TeamBoardFileDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.teamboard.dto.TeamBoardFileDTO'] = TeamBoardFileDTO;

reverseMapping[TeamBoardFileDTO.name] = 'fr.hiji.metier.teamboard.dto.TeamBoardFileDTO';

fields['fr.hiji.metier.teamboard.dto.TeamBoardFileDTO']  = {
    teamBoardFile: 'fr.hiji.metier.teamboard.entities.TeamBoardFile',
    hijiFile: 'fr.hiji.metier.file.entities.HijiFile',
    token: 'java.lang.String'
};
