// ENTITY fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFQuizTagStatDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class OrangeMIFQuizTagStatDTO {

  idTag: number | null = 0;
  idParent: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  colorCode: string | null = '';
  showInRadar: boolean | null = false;
  score: number | null = null;
  total: number | null = null;

  constructor(init?:Partial<OrangeMIFQuizTagStatDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFQuizTagStatDTO'] = OrangeMIFQuizTagStatDTO;

reverseMapping[OrangeMIFQuizTagStatDTO.name] = 'fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFQuizTagStatDTO';

fields['fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFQuizTagStatDTO']  = {
    idTag: 'java.lang.Integer',
    idParent: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    colorCode: 'java.lang.String',
    showInRadar: 'java.lang.Boolean',
    score: 'java.lang.Double',
    total: 'java.lang.Double'
};
