// ENTITY fr.hiji.metier.ema.dto.back.stats.EMACoachStatDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMAPerceptionStatsBODTO } from './EMAPerceptionStatsBODTO';

export class EMACoachStatDTO {

  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  perimeterNames: string[] | null = [];
  teamCount: number | null = 0;
  nbObs: number | null = 0;
  nbTeamAutoObs: number | null = 0;
  nbExchange: number | null = 0;
  perceptionStats: EMAPerceptionStatsBODTO[] | null = [];

  constructor(init?:Partial<EMACoachStatDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.stats.EMACoachStatDTO'] = EMACoachStatDTO;

reverseMapping[EMACoachStatDTO.name] = 'fr.hiji.metier.ema.dto.back.stats.EMACoachStatDTO';

fields['fr.hiji.metier.ema.dto.back.stats.EMACoachStatDTO']  = {
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    perimeterNames: 'java.util.List<java.lang.String>',
    teamCount: 'java.lang.Integer',
    nbObs: 'java.lang.Integer',
    nbTeamAutoObs: 'java.lang.Integer',
    nbExchange: 'java.lang.Integer',
    perceptionStats: 'java.util.List<fr.hiji.metier.ema.dto.back.stats.EMAPerceptionStatsBODTO>'
};
