// ENTITY fr.hiji.metier.mif.entities.trainingpath.TrainingPathCertificationRequirement generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class TrainingPathCertificationRequirement {

  idTrainingPath: number | null = 0;
  idCertification: number | null = 0;
  idCertificationRequired: number | null = 0;

  constructor(init?:Partial<TrainingPathCertificationRequirement>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.trainingpath.TrainingPathCertificationRequirement'] = TrainingPathCertificationRequirement;

reverseMapping[TrainingPathCertificationRequirement.name] = 'fr.hiji.metier.mif.entities.trainingpath.TrainingPathCertificationRequirement';

fields['fr.hiji.metier.mif.entities.trainingpath.TrainingPathCertificationRequirement']  = {
    idTrainingPath: 'java.lang.Integer',
    idCertification: 'java.lang.Integer',
    idCertificationRequired: 'java.lang.Integer'
};
