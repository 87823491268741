// ENTITY fr.hiji.metier.ema.dto.common.EMASkillMapFilterDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:59 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMASkillMapDateType } from './EMASkillMapDateType';
import { EMASkillMapPopulationType } from './EMASkillMapPopulationType';

export class EMASkillMapFilterDTO {

  idRepository: number | null = 0;
  idUser: number | null = 0;
  date: Date | null = null;
  dateType: EMASkillMapDateType | null = null;
  populationType: EMASkillMapPopulationType | null = null;

  constructor(init?:Partial<EMASkillMapFilterDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.common.EMASkillMapFilterDTO'] = EMASkillMapFilterDTO;

reverseMapping[EMASkillMapFilterDTO.name] = 'fr.hiji.metier.ema.dto.common.EMASkillMapFilterDTO';

fields['fr.hiji.metier.ema.dto.common.EMASkillMapFilterDTO']  = {
    idRepository: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    date: 'java.time.LocalDate',
    dateType: 'fr.hiji.metier.ema.dto.common.EMASkillMapDateType',
    populationType: 'fr.hiji.metier.ema.dto.common.EMASkillMapPopulationType'
};
