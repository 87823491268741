// ENTITY fr.hiji.metier.campaign.dto.front.pdi.PDIFormationDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { CampaignLiteDTO } from './CampaignLiteDTO';
import { CampaignPDIUserFormation } from './CampaignPDIUserFormation';

export class PDIFormationDTO {

  pdiFormations: CampaignPDIUserFormation[] | null = [];
  campaigns: CampaignLiteDTO[] | null = [];

  constructor(init?:Partial<PDIFormationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.pdi.PDIFormationDTO'] = PDIFormationDTO;

reverseMapping[PDIFormationDTO.name] = 'fr.hiji.metier.campaign.dto.front.pdi.PDIFormationDTO';

fields['fr.hiji.metier.campaign.dto.front.pdi.PDIFormationDTO']  = {
    pdiFormations: 'java.util.List<fr.hiji.metier.campaign.entities.pdi.CampaignPDIUserFormation>',
    campaigns: 'java.util.List<fr.hiji.metier.campaign.dto.CampaignLiteDTO>'
};
