// ENTITY fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationParticipation generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationEventAnimationParticipation {

  idFormationEventAnimation: number | null = 0;
  idFormationEventObservation: number | null = 0;
  participation: boolean | null = false;

  constructor(init?:Partial<FormationEventAnimationParticipation>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationParticipation'] = FormationEventAnimationParticipation;

reverseMapping[FormationEventAnimationParticipation.name] = 'fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationParticipation';

fields['fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationParticipation']  = {
    idFormationEventAnimation: 'java.lang.Integer',
    idFormationEventObservation: 'java.lang.Integer',
    participation: 'java.lang.Boolean'
};
