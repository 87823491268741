// ENTITY fr.hiji.metier.certif.entities.CertificationCandidateResult generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class CertificationCandidateResult {

  idCertification: number | null = 0;
  idUser: number | null = 0;
  date: Date | null = null;
  strength: string | null = '';
  opportunities: string | null = '';
  actionPlan: string | null = '';
  certified: boolean | null = false;
  allowCandidateToSeePreviousResults: boolean | null = false;

  constructor(init?:Partial<CertificationCandidateResult>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.CertificationCandidateResult'] = CertificationCandidateResult;

reverseMapping[CertificationCandidateResult.name] = 'fr.hiji.metier.certif.entities.CertificationCandidateResult';

fields['fr.hiji.metier.certif.entities.CertificationCandidateResult']  = {
    idCertification: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    date: 'java.time.Instant',
    strength: 'java.lang.String',
    opportunities: 'java.lang.String',
    actionPlan: 'java.lang.String',
    certified: 'java.lang.Boolean',
    allowCandidateToSeePreviousResults: 'java.lang.Boolean'
};
