// ENTITY fr.hiji.metier.campaign.dto.front.ObservableGestureDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { HighlightType } from './HighlightType';
import { ObservableGestureFlatTag } from './ObservableGestureFlatTag';

export class ObservableGestureDTO {

  idObservableGesture: number | null = 0;
  observableGestureName: MultilingualString | null = new MultilingualString();
  observableGestureDescription: MultilingualString | null = new MultilingualString();
  idCriteria: number | null = 0;
  nbObservationGeste: number | null = 0;
  order: number | null = 0;
  highlight: HighlightType | null = null;
  tags: ObservableGestureFlatTag[] | null = [];
  averageBefore: number | null = 0;
  averageAfter: number | null = 0;

  constructor(init?:Partial<ObservableGestureDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.ObservableGestureDTO'] = ObservableGestureDTO;

reverseMapping[ObservableGestureDTO.name] = 'fr.hiji.metier.campaign.dto.front.ObservableGestureDTO';

fields['fr.hiji.metier.campaign.dto.front.ObservableGestureDTO']  = {
    idObservableGesture: 'java.lang.Integer',
    observableGestureName: 'com.ic2.sc.MultilingualString',
    observableGestureDescription: 'com.ic2.sc.MultilingualString',
    idCriteria: 'java.lang.Integer',
    nbObservationGeste: 'java.lang.Integer',
    order: 'java.lang.Integer',
    highlight: 'fr.hiji.metier.referential.entities.HighlightType',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.ObservableGestureFlatTag>',
    averageBefore: 'java.lang.Double',
    averageAfter: 'java.lang.Double'
};
