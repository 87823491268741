<hiji-wavy-container>
  <div class="container bg-white rounded shadow-2 py-3 m-3 mx-sm-auto" style="z-index: 10">
    <!--<div style="width: 23rem"></div>-->

    <!--logo-->
    <div class="row justify-content-center">
      <div class="col-auto">
        <img style="width: 75px" src="assets/logo.png" />
      </div>
    </div>

    <!--form-->
    <div class="row justify-content-center mt-3 mb-3">
      <div class="col-12 col-md-6">
        <form #f="ngForm" [formGroup]="form" (ngSubmit)="submit()">
          <div class="container-fluid">
            <div class="row mt-4">
              <div class="col-12 text-center justify-content-center">
                <div class="text-left">
                  @if (!done) {
                    <h3>{{ 'common.mail-auth.Bienvenue dans votre espace hiji' | translate }}</h3>
                  }
                  @if (done) {
                    <h3>{{ 'common.mail-auth.Email de connexion envoyé' | translate }}</h3>
                  }
                </div>
                @if (!done) {
                  <div class="text-left mt-2">{{ 'common.mail-auth.Entrez votre email afin de recevoir un lien de connexion' | translate }}</div>
                }
              </div>
            </div>

            @if (done) {
              <div class="row mt-4 justify-content-center">
                <div class="col-12 col-md-8 text-center">
                  {{ 'common.mail-auth.Vous allez reçevoir un email contenant un lien personnel de connexion' | translate }}.
                  <br />
                  <br />
                  {{ 'common.mail-auth.Vous pouvez fermer cette page' | translate }}.
                </div>
              </div>
            }

            @if (!done) {
              <div class="row mt-4 justify-content-center">
                <div class="col-12 col-md-8">
                  <div class="form-group" style="margin-bottom: 2rem">
                    <input class="form-control" type="email" [placeholder]="'common.login.E-mail' | translate" formControlName="email" />
                  </div>
                </div>
              </div>
            }

            <!--Errors handling-->
            @if (!loading && !done && f.submitted && (!form.valid || error)) {
              <div class="row justify-content-center mt-4">
                <div class="col-12 col-md-8">
                  <ul class="errors">
                    @if (form.hasError('required', 'email')) {
                      <li>{{ "common.login.L'E-mail est requis" | translate }}</li>
                    }
                    @if (form.hasError('email', 'email')) {
                      <li>{{ "common.login.L'E-mail est invalide" | translate }}</li>
                    }
                    @if (error) {
                      <li>{{ error }}</li>
                    }
                  </ul>
                </div>
              </div>
            }

            @if (!done) {
              <div class="row mt-3">
                <div class="col text-center">
                  <button class="btn btn-primary text-uppercase" type="submit" [disabled]="loading">
                    {{ 'common.mail-auth.Valider' | translate }}
                    @if (loading) {
                      <i class="fa-light fa-spinner spinning"></i>
                    }
                  </button>
                </div>
              </div>
            }
          </div>
        </form>
      </div>
    </div>
  </div>
</hiji-wavy-container>
