import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L } from '@ic2/ic2-lib';
import { AutoLogService } from '../../ic2/services/AutoLogService';
import { CoreService } from '../../ic2/services/CoreService';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'lib-autolog',
    templateUrl: './autolog.component.html',
    styleUrls: ['./autolog.component.scss'],
    standalone: false
})
export class AutologComponent implements OnInit {
  private router = inject(Router);
  private authService = inject(AuthService);
  private route = inject(ActivatedRoute);
  private coreService = inject(CoreService);
  private autoLogService = inject(AutoLogService);


  ngOnInit(): void {
    if (window.location.hash.length > 0 && window.location.hash.indexOf('#token=') !== -1) {
      const authToken = window.location.hash.substring(window.location.hash.indexOf('#token=') + '#token='.length);
      window.location.hash = '';
      console.log('token from autolog', authToken);

      this.authService.getBundleSubscription = this.autoLogService
        .loginAs(authToken)
        .defaultOnErrorAnd((err) => {
          L.e(err);
          this.router.navigate(['/']);
        })
        .execute((data) => {
          this.authService.loginWith(data, () => {
            this.router.navigate(['/']);
          });
        });
    } else {
      this.router.navigate(['/']);
    }
  }
}
