// ENTITY fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFOpenAnswerStatDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class OrangeMIFOpenAnswerStatDTO {

  name: MultilingualString | null = new MultilingualString();
  answer: string | null = '';

  constructor(init?:Partial<OrangeMIFOpenAnswerStatDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFOpenAnswerStatDTO'] = OrangeMIFOpenAnswerStatDTO;

reverseMapping[OrangeMIFOpenAnswerStatDTO.name] = 'fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFOpenAnswerStatDTO';

fields['fr.hiji.metier.mif.dto.front.orangestats.OrangeMIFOpenAnswerStatDTO']  = {
    name: 'com.ic2.sc.MultilingualString',
    answer: 'java.lang.String'
};
