// ENTITY fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionLiteBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { CertificationSession } from './CertificationSession';

export class CertificationSessionLiteBODTO {

  certificationSession: CertificationSession | null = null;
  assessors: string[] | null = [];
  searchLanguagesMatch: string[] | null = [];

  constructor(init?:Partial<CertificationSessionLiteBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionLiteBODTO'] = CertificationSessionLiteBODTO;

reverseMapping[CertificationSessionLiteBODTO.name] = 'fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionLiteBODTO';

fields['fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionLiteBODTO']  = {
    certificationSession: 'fr.hiji.metier.certif.entities.session.CertificationSession',
    assessors: 'java.util.List<java.lang.String>',
    searchLanguagesMatch: 'java.util.List<java.lang.String>'
};
