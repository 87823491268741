// ENTITY fr.hiji.metier.home.dto.front.FormationSessionActionDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class FormationSessionActionDTO {

  idFormation: number | null = 0;
  formationName: MultilingualString | null = new MultilingualString();
  idFormationSession: number | null = 0;
  formationSessionName: string | null = '';

  constructor(init?:Partial<FormationSessionActionDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.home.dto.front.FormationSessionActionDTO'] = FormationSessionActionDTO;

reverseMapping[FormationSessionActionDTO.name] = 'fr.hiji.metier.home.dto.front.FormationSessionActionDTO';

fields['fr.hiji.metier.home.dto.front.FormationSessionActionDTO']  = {
    idFormation: 'java.lang.Integer',
    formationName: 'com.ic2.sc.MultilingualString',
    idFormationSession: 'java.lang.Integer',
    formationSessionName: 'java.lang.String'
};
