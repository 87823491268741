// DB ENUM fr.hiji.metier.mif.entities.session.FormationSessionWorkflowStep generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:59 UTC 2025

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class FormationSessionWorkflowStep {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly ACQUISITION_BEFORE = new FormationSessionWorkflowStep(1, "Acquisition avant", "ACQUISITION_BEFORE");
  static readonly SATISFACTION_PARTICIPANT = new FormationSessionWorkflowStep(2, "Satisfaction participant", "SATISFACTION_PARTICIPANT");
  static readonly ACQUISITION_AFTER = new FormationSessionWorkflowStep(3, "Acquisition après", "ACQUISITION_AFTER");
  static readonly ACTION = new FormationSessionWorkflowStep(4, "Action", "ACTION");

  static readonly values = [
    FormationSessionWorkflowStep.ACQUISITION_BEFORE,
 
    FormationSessionWorkflowStep.SATISFACTION_PARTICIPANT,
 
    FormationSessionWorkflowStep.ACQUISITION_AFTER,
 
    FormationSessionWorkflowStep.ACTION
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of FormationSessionWorkflowStep.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of FormationSessionWorkflowStep.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.mif.entities.session.FormationSessionWorkflowStep'] = FormationSessionWorkflowStep;
reverseMapping[FormationSessionWorkflowStep.name] = 'fr.hiji.metier.mif.entities.session.FormationSessionWorkflowStep';
