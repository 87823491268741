// ENTITY fr.hiji.metier.home.dto.front.FormationEventLiteDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class FormationEventLiteDTO {

  idFormationEvent: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  startDate: Date | null = null;
  endDate: Date | null = null;

  constructor(init?:Partial<FormationEventLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.home.dto.front.FormationEventLiteDTO'] = FormationEventLiteDTO;

reverseMapping[FormationEventLiteDTO.name] = 'fr.hiji.metier.home.dto.front.FormationEventLiteDTO';

fields['fr.hiji.metier.home.dto.front.FormationEventLiteDTO']  = {
    idFormationEvent: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    startDate: 'java.time.LocalDate',
    endDate: 'java.time.LocalDate'
};
