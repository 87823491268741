// ENTITY fr.hiji.metier.referential.dto.back.TagForTradBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class TagForTradBODTO {

  idTag: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();

  constructor(init?:Partial<TagForTradBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.back.TagForTradBODTO'] = TagForTradBODTO;

reverseMapping[TagForTradBODTO.name] = 'fr.hiji.metier.referential.dto.back.TagForTradBODTO';

fields['fr.hiji.metier.referential.dto.back.TagForTradBODTO']  = {
    idTag: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString'
};
