import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { filter, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PreviousRouteService {
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  previousRoute: RoutesRecognized = null;
  currentRoute: RoutesRecognized = null;
  previousUrl: string = null;
  currentUrl: string = null;

  constructor() {
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        startWith(this.route.snapshot)
      )
      .subscribe((event: RoutesRecognized) => {
        //console.log('url', event);
        this.previousRoute = this.currentRoute;
        this.currentRoute = event;
      });
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
    });
  }

  getPreviousComponent() {
    if (this.previousRoute === null || this.previousRoute.state === undefined) return null;
    let previous = this.previousRoute.state.root.firstChild;
    while (previous !== null)
      if (previous.firstChild === null) break;
      else previous = previous.firstChild;
    return previous;
  }
}
