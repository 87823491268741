// ENTITY fr.hiji.metier.reporting.dto.survey.SurveyReportSkillDataDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CriteriaType } from './CriteriaType';

export class SurveyReportSkillDataDTO {

  idTag: number | null = 0;
  tagName: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  benchmarkCode: string | null = '';
  nb: number | null = 0;
  avg: number | null = null;
  nbValue1: number | null = 0;
  nbValue2: number | null = 0;
  nbValue3: number | null = 0;
  nbValue4: number | null = 0;
  nbValue5: number | null = 0;
  hideInFO: boolean | null = false;
  showInRadar: boolean | null = false;
  positive: number | null = 0;
  neutral: number | null = 0;
  negative: number | null = 0;
  verynegative: number | null = 0;
  criteriaType: CriteriaType | null = null;

  constructor(init?:Partial<SurveyReportSkillDataDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.survey.SurveyReportSkillDataDTO'] = SurveyReportSkillDataDTO;

reverseMapping[SurveyReportSkillDataDTO.name] = 'fr.hiji.metier.reporting.dto.survey.SurveyReportSkillDataDTO';

fields['fr.hiji.metier.reporting.dto.survey.SurveyReportSkillDataDTO']  = {
    idTag: 'java.lang.Integer',
    tagName: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    benchmarkCode: 'java.lang.String',
    nb: 'java.lang.Integer',
    avg: 'java.lang.Double',
    nbValue1: 'java.lang.Integer',
    nbValue2: 'java.lang.Integer',
    nbValue3: 'java.lang.Integer',
    nbValue4: 'java.lang.Integer',
    nbValue5: 'java.lang.Integer',
    hideInFO: 'java.lang.Boolean',
    showInRadar: 'java.lang.Boolean',
    positive: 'java.lang.Double',
    neutral: 'java.lang.Double',
    negative: 'java.lang.Double',
    verynegative: 'java.lang.Double',
    criteriaType: 'fr.hiji.metier.referential.entities.CriteriaType'
};
