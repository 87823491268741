// ENTITY fr.hiji.metier.ema.dto.common.EMASkillMapOGDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ObservableGestureFlatTag } from './ObservableGestureFlatTag';

export class EMASkillMapOGDTO {

  idObservableGesture: number | null = 0;
  idCriteria: number | null = 0;
  observableGestureName: MultilingualString | null = new MultilingualString();
  benchmarkCode: string | null = '';
  tags: ObservableGestureFlatTag[] | null = [];
  nb: number | null = 0;
  nbCoachObs: number | null = 0;
  nbObs: number | null = 0;
  nbAutoObs: number | null = 0;
  avg: number | null = null;
  nbNps0: number | null = 0;
  nbNps10: number | null = 0;
  nbNps20: number | null = 0;
  nbNps30: number | null = 0;
  nbNps40: number | null = 0;
  nbNps50: number | null = 0;
  nbNps60: number | null = 0;
  nbNps70: number | null = 0;
  nbNps80: number | null = 0;
  nbNps90: number | null = 0;
  nbNps100: number | null = 0;

  constructor(init?:Partial<EMASkillMapOGDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.common.EMASkillMapOGDTO'] = EMASkillMapOGDTO;

reverseMapping[EMASkillMapOGDTO.name] = 'fr.hiji.metier.ema.dto.common.EMASkillMapOGDTO';

fields['fr.hiji.metier.ema.dto.common.EMASkillMapOGDTO']  = {
    idObservableGesture: 'java.lang.Integer',
    idCriteria: 'java.lang.Integer',
    observableGestureName: 'com.ic2.sc.MultilingualString',
    benchmarkCode: 'java.lang.String',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.ObservableGestureFlatTag>',
    nb: 'java.lang.Integer',
    nbCoachObs: 'java.lang.Integer',
    nbObs: 'java.lang.Integer',
    nbAutoObs: 'java.lang.Integer',
    avg: 'java.lang.Double',
    nbNps0: 'java.lang.Integer',
    nbNps10: 'java.lang.Integer',
    nbNps20: 'java.lang.Integer',
    nbNps30: 'java.lang.Integer',
    nbNps40: 'java.lang.Integer',
    nbNps50: 'java.lang.Integer',
    nbNps60: 'java.lang.Integer',
    nbNps70: 'java.lang.Integer',
    nbNps80: 'java.lang.Integer',
    nbNps90: 'java.lang.Integer',
    nbNps100: 'java.lang.Integer'
};
