import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCALE_LOAD_MAPPING } from './LocaleLoadMapping';

@Pipe({
    name: 'localizedDate',
    pure: false,
    standalone: false
})
export class LocalizedDatePipe implements PipeTransform {
  private translateService = inject(TranslateService);


  transform(value: any, pattern: string = 'shortDate'): any {
    let lang = this.translateService.currentLang || this.translateService.defaultLang;

    if (lang in LOCALE_LOAD_MAPPING) lang = LOCALE_LOAD_MAPPING[lang];

    try {
      const datePipe: DatePipe = new DatePipe(lang);
      return datePipe.transform(value, pattern);
    } catch (e) {
      console.error('Error LocalizedDatePipe on value : ' + value + ' and lang : ' + lang, e);
      return value;
    }
  }
}
