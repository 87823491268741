// ENTITY fr.hiji.metier.referential.entities.Repository generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class Repository {

  idRepository: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  usableInCampaigns: boolean | null = false;
  usableIn360: boolean | null = false;
  usableInMIFSatisfaction: boolean | null = false;
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = null;
  del: boolean | null = false;

  constructor(init?:Partial<Repository>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.entities.Repository'] = Repository;

reverseMapping[Repository.name] = 'fr.hiji.metier.referential.entities.Repository';

fields['fr.hiji.metier.referential.entities.Repository']  = {
    idRepository: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    usableInCampaigns: 'java.lang.Boolean',
    usableIn360: 'java.lang.Boolean',
    usableInMIFSatisfaction: 'java.lang.Boolean',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
