// ENTITY fr.hiji.metier.ema.entities.template.EMATemplateCoachingAction generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMATemplateCoachingAction {

  idEMAPredefinedCoachingAction: number | null = 0;
  idEMATemplate: number | null = 0;

  constructor(init?:Partial<EMATemplateCoachingAction>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.template.EMATemplateCoachingAction'] = EMATemplateCoachingAction;

reverseMapping[EMATemplateCoachingAction.name] = 'fr.hiji.metier.ema.entities.template.EMATemplateCoachingAction';

fields['fr.hiji.metier.ema.entities.template.EMATemplateCoachingAction']  = {
    idEMAPredefinedCoachingAction: 'java.lang.Integer',
    idEMATemplate: 'java.lang.Integer'
};
