<ng-template let-modal #previewTemplate>
  <div class="modal-body">
    <h6>{{ 'common.email-edit.Sujet du mail' | translate }}</h6>
    <hr />
    <div [innerHTML]="subject"></div>
    <h6 class="mt-3">{{ 'common.email-edit.Corps du mail' | translate }}</h6>
    <hr />
    <div [innerHTML]="body"></div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark" type="button" data-dismiss="modal" (click)="modal.dismiss()">{{ 'common.global.Fermer' | translate }}</button>
  </div>
</ng-template>

@if (authService.modules.backConfiguration.allowedToEditEmailSender) {
  <div class="row">
    <div class="col">
      <label for="senderEmail">{{ "common.email-edit.Email de l'expéditeur" | translate }}:</label>
      <input class="form-control" name="senderEmail" type="text" [disabled]="_disabled" [(ngModel)]="email.senderEmail" required />
    </div>
  </div>
}
<div class="row mt-2">
  <div class="col">
    <label for="name">{{ "common.email-edit.Nom de l'expéditeur" | translate }}:</label>
    <ic2-multilingualstring-textassist
      id="name"
      name="name"
      [disabled]="_disabled"
      [(ngModel)]="email.senderName"
      msrequired
    ></ic2-multilingualstring-textassist>
  </div>
</div>
<div class="row mt-2">
  <div class="col">
    <label for="subject">{{ 'common.email-edit.Sujet du mail' | translate }}:</label>
    <ic2-multilingualstring-textassist
      id="subject"
      name="subject"
      [disabled]="_disabled"
      [(ngModel)]="email.subject"
      msrequired
    ></ic2-multilingualstring-textassist>
  </div>
</div>

<div class="row mt-3">
  <div class="col">
    <label for="slug">{{ 'common.email-edit.Contenu du mail' | translate }}:</label>
  </div>
  @if (canResetToDefaultEmail || canResetToHijiEmail) {
    <div class="col text-end">
      @if (canResetToDefaultEmail) {
        <button class="btn btn-sm btn-info" type="button" [disabled]="loadingResetEmail || _disabled" (click)="resetToDefault()">
          {{ 'common.email-edit.Réinitialiser le contenu du mail dans cette langue à sa version générique' | translate }}
          @if (loadingResetEmail) {
            <i class="fa-light fa-spinner spinning ms-2"></i>
          }
        </button>
      }
      @if (canResetToHijiEmail) {
        <button class="btn btn-sm btn-info mt-1" type="button" [disabled]="loadingResetEmail || _disabled" (click)="resetToHijiEmail()">
          {{ 'common.email-edit.Réinitialiser le contenu du mail dans cette langue à sa version hiji' | translate }}
          @if (loadingResetEmail) {
            <i class="fa-light fa-spinner spinning ms-2"></i>
          }
        </button>
      }
    </div>
  }
</div>

<div class="row mt-1">
  <div class="col">
    <label for="language">{{ 'common.email-edit.Langue' | translate }}:</label>
    <div class="input-group">
      <select class="form-control" id="language" name="language" [(ngModel)]="currentLanguage">
        @for (language of languages; track language) {
          <option [ngValue]="language">
            {{ email.content.obj.hasOwnProperty(language) ? '* ' : '' }}{{ 'common.languages.' + language | translate }}
          </option>
        }
      </select>
      @if (email.content.obj.hasOwnProperty(currentLanguage) && !_disabled) {
        <div class="input-group-text justify-content-center">
          <i class="fa-light fa-trash text-danger-hover pointer" (click)="deleteLanguage(currentLanguage)"></i>
        </div>
      }
    </div>
  </div>
</div>

@if (!email.content.obj.hasOwnProperty(currentLanguage)) {
  <div class="row mt-2">
    <div class="col text-center text-danger">
      {{ 'common.email-edit.Pas de version {{lang}} de cet email, {{ defaultLang }} sera utilisé par défaut' | translate:{lang:( 'common.languages.' +
      currentLanguage | translate),defaultLang:( 'common.languages.en' | translate)} }}
      <br />
      @if (!_disabled) {
        <button class="btn btn-primary mt-2" type="button" (click)="email.content.obj[currentLanguage] = ''">
          {{ 'common.email-edit.Créer une version {{lang}}' | translate:{lang:( 'common.languages.' + currentLanguage | translate)} }}
        </button>
      }
    </div>
  </div>
}

@if (email.content.obj.hasOwnProperty(currentLanguage)) {
  <div class="row mt-1">
    <div class="col">
      <ngx-monaco-editor
        style="min-height: 50vh"
        name="monaco-editor-{{ email.emailType.id }}-{{ currentLanguage }}"
        id="monaco-editor-{{ email.emailType.id }}-{{ currentLanguage }}"
        [options]="editorOptions"
        [(ngModel)]="email.content.obj[currentLanguage]"
        #editor
      ></ngx-monaco-editor>
      <!--<angular-editor name="html" [config]="getEditorConfig()" [(ngModel)]="email.content.obj[currentLanguage]" *ngIf="showOldEditor"></angular-editor>-->
    </div>
  </div>
}

<div class="row mt-3 justify-content-end">
  <div class="col-auto">
    <div class="d-inline-block mt-2 mt-lg-0 me-2" ngbDropdown container="body">
      <button class="btn btn-sm btn-info" type="button" [disabled]="loadingEmail" ngbDropdownToggle>
        {{ 'common.email-edit.Prévisualiser' | translate }}
        @if (loadingEmail) {
          <i class="fa-light fa-spinner spinning ms-2"></i>
        }
      </button>
      <div ngbDropdownMenu>
        @for (lang of multilingualstringConfigService.availableLanguages; track lang) {
          <button
            class="btn btn-sm btn-info me-2"
            type="button"
            [disabled]="loadingEmail"
            ngbDropdownItem
            (click)="askServerForPreview(email, lang)"
            >
            {{ 'common.languages.' + lang | translate }}
          </button>
        }
      </div>
    </div>
  </div>
  <div class="col-auto">
    <div class="d-inline-block mt-2 mt-lg-0 me-2" ngbDropdown container="body">
      <button class="btn btn-sm btn-info" type="button" [disabled]="loadingTestEmail" ngbDropdownToggle>
        {{ 'common.email-edit.Email test' | translate }}
        @if (loadingTestEmail) {
          <i class="fa-light fa-spinner spinning ms-2"></i>
        }
      </button>
      <div ngbDropdownMenu>
        @for (lang of multilingualstringConfigService.availableLanguages; track lang) {
          <button
            class="btn btn-sm btn-info me-2"
            type="button"
            [disabled]="loadingEmail"
            ngbDropdownItem
            (click)="askServerForTestEmail(lang)"
            >
            {{ 'common.languages.' + lang | translate }}
          </button>
        }
      </div>
    </div>
  </div>
</div>
