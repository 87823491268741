// ENTITY fr.hiji.metier.user.dto.UserProfileDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Organisation } from './Organisation';
import { UserLiteDTO } from './UserLiteDTO';

export class UserProfileDTO {

  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  fonctionName: string | null = '';
  email: string | null = '';
  organisations: Organisation[] | null = [];
  managers: UserLiteDTO[] | null = [];
  managed: UserLiteDTO[] | null = [];
  managedShared: UserLiteDTO[] | null = [];

  constructor(init?:Partial<UserProfileDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.UserProfileDTO'] = UserProfileDTO;

reverseMapping[UserProfileDTO.name] = 'fr.hiji.metier.user.dto.UserProfileDTO';

fields['fr.hiji.metier.user.dto.UserProfileDTO']  = {
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    fonctionName: 'java.lang.String',
    email: 'java.lang.String',
    organisations: 'java.util.List<fr.hiji.metier.reseau.entities.Organisation>',
    managers: 'java.util.List<fr.hiji.metier.user.dto.UserLiteDTO>',
    managed: 'java.util.List<fr.hiji.metier.user.dto.UserLiteDTO>',
    managedShared: 'java.util.List<fr.hiji.metier.user.dto.UserLiteDTO>'
};
