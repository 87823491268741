// ENTITY fr.hiji.metier.certif.dto.front.JoinResultDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:57 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class JoinResultDTO {

  idCertification: number | null = 0;
  certifName: MultilingualString | null = new MultilingualString();
  idCertificationSession: number | null = 0;
  sessionName: string | null = '';
  idCampaign: number | null = 0;
  campaignName: MultilingualString | null = new MultilingualString();
  idEMATemplate: number | null = 0;
  added: boolean | null = false;

  constructor(init?:Partial<JoinResultDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.JoinResultDTO'] = JoinResultDTO;

reverseMapping[JoinResultDTO.name] = 'fr.hiji.metier.certif.dto.front.JoinResultDTO';

fields['fr.hiji.metier.certif.dto.front.JoinResultDTO']  = {
    idCertification: 'java.lang.Integer',
    certifName: 'com.ic2.sc.MultilingualString',
    idCertificationSession: 'java.lang.Integer',
    sessionName: 'java.lang.String',
    idCampaign: 'java.lang.Integer',
    campaignName: 'com.ic2.sc.MultilingualString',
    idEMATemplate: 'java.lang.Integer',
    added: 'java.lang.Boolean'
};
