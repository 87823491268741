@if (type() === OrganisationType.ENSEIGNE) {
  <i class="fa-light fa-store" ngbTooltip="{{ 'common.organisation-icon.Enseigne' | translate }}" container="body"></i>
}
@if (type() === OrganisationType.SOCIETE) {
  <i
    class="fa-light fa-people-arrows"
    ngbTooltip="{{ 'common.organisation-icon.Société' | translate }}"
    container="body"
  ></i>
}
@if (type() === OrganisationType.PAYS) {
  <i
    class="fa-light fa-map-location-dot"
    ngbTooltip="{{ 'common.organisation-icon.Pays' | translate }}"
    container="body"
  ></i>
}
@if (type() === OrganisationType.REGION) {
  <i class="fa-light fa-globe" ngbTooltip="{{ 'common.organisation-icon.Région' | translate }}" container="body"></i>
}
@if (type() === OrganisationType.ETABLISSEMENT) {
  <i
    class="fa-light fa-building"
    ngbTooltip="{{ 'common.organisation-icon.Établissement' | translate }}"
    container="body"
  ></i>
}
@if (type() === OrganisationType.DIRECTION) {
  <i class="fa-light fa-tag" ngbTooltip="{{ 'common.organisation-icon.Direction' | translate }}" container="body"></i>
}
@if (type() === OrganisationType.SERVICE) {
  <i class="fa-light fa-sitemap" ngbTooltip="{{ 'common.organisation-icon.Service' | translate }}" container="body"></i>
}
@if (type() === OrganisationType.AUTRE) {
  <i
    class="fa-light fa-file-circle-question"
    ngbTooltip="{{ 'common.organisation-icon.Autre' | translate }}"
    container="body"
  ></i>
}
@if (type() === null) {
  <i class="fa-light fa-question-circle" ngbTooltip="{{ 'common.organisation-icon.Non défini' | translate }}" container="body"></i>
}
