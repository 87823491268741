// DB ENUM fr.hiji.metier.campaign.entities.SeniorityRange generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:59 UTC 2025

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class SeniorityRange {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly _INF_2 = new SeniorityRange(1, "<2", "_INF_2");
  static readonly _2_4 = new SeniorityRange(2, "2-4", "_2_4");
  static readonly _5_9 = new SeniorityRange(3, "5-9", "_5_9");
  static readonly _10_14 = new SeniorityRange(4, "10-14", "_10_14");
  static readonly _15_19 = new SeniorityRange(5, "15-19", "_15_19");
  static readonly _SUP_20 = new SeniorityRange(6, "+20", "_SUP_20");

  static readonly values = [
    SeniorityRange._INF_2,
 
    SeniorityRange._2_4,
 
    SeniorityRange._5_9,
 
    SeniorityRange._10_14,
 
    SeniorityRange._15_19,
 
    SeniorityRange._SUP_20
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of SeniorityRange.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of SeniorityRange.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.campaign.entities.SeniorityRange'] = SeniorityRange;
reverseMapping[SeniorityRange.name] = 'fr.hiji.metier.campaign.entities.SeniorityRange';
