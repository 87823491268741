// ENTITY fr.hiji.metier.ema.dto.front.repositoryinfo.EMARepositoryInfoOGDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { EMAUserOG } from './EMAUserOG';
import { ObservableGestureFlatTag } from './ObservableGestureFlatTag';

export class EMARepositoryInfoOGDTO {

  emaUserOG: EMAUserOG | null = null;
  idObservableGesture: number | null = 0;
  observableGestureName: MultilingualString | null = new MultilingualString();
  observableGestureDescription: MultilingualString | null = new MultilingualString();
  tags: ObservableGestureFlatTag[] | null = [];
  isComplementary: boolean | null = false;

  constructor(init?:Partial<EMARepositoryInfoOGDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.repositoryinfo.EMARepositoryInfoOGDTO'] = EMARepositoryInfoOGDTO;

reverseMapping[EMARepositoryInfoOGDTO.name] = 'fr.hiji.metier.ema.dto.front.repositoryinfo.EMARepositoryInfoOGDTO';

fields['fr.hiji.metier.ema.dto.front.repositoryinfo.EMARepositoryInfoOGDTO']  = {
    emaUserOG: 'fr.hiji.metier.ema.entities.EMAUserOG',
    idObservableGesture: 'java.lang.Integer',
    observableGestureName: 'com.ic2.sc.MultilingualString',
    observableGestureDescription: 'com.ic2.sc.MultilingualString',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.ObservableGestureFlatTag>',
    isComplementary: 'java.lang.Boolean'
};
