// ENTITY fr.hiji.metier.mif.entities.event.animation.FormationEventAnimation generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { FormationEventAnimationType } from './FormationEventAnimationType';

export class FormationEventAnimation {

  idFormationEventAnimation: number | null = 0;
  idFormationEvent: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  informations: MultilingualString | null = new MultilingualString();
  order: number | null = 0;
  type: FormationEventAnimationType | null = null;
  del: boolean | null = false;

  constructor(init?:Partial<FormationEventAnimation>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.event.animation.FormationEventAnimation'] = FormationEventAnimation;

reverseMapping[FormationEventAnimation.name] = 'fr.hiji.metier.mif.entities.event.animation.FormationEventAnimation';

fields['fr.hiji.metier.mif.entities.event.animation.FormationEventAnimation']  = {
    idFormationEventAnimation: 'java.lang.Integer',
    idFormationEvent: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    informations: 'com.ic2.sc.MultilingualString',
    order: 'java.lang.Integer',
    type: 'fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationType',
    del: 'java.lang.Boolean'
};
