// fr.hiji.metier.ema.services.back.EMAPredefinedCoachingActionBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:49 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { EMAPredefinedCoachingAction } from '../entities/EMAPredefinedCoachingAction';

@Injectable({
  providedIn: 'root',
})
export class EMAPredefinedCoachingActionBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getEMAPredefinedCoachingActions(filter: GenericFilter): RpcRequestBuilder<ListWithCount<EMAPredefinedCoachingAction>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAPredefinedCoachingActionBOService.getEMAPredefinedCoachingActions';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getEMAPredefinedCoachingAction(id: number): RpcRequestBuilder<EMAPredefinedCoachingAction> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAPredefinedCoachingActionBOService.getEMAPredefinedCoachingAction';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.entities.template.EMAPredefinedCoachingAction');
  }

  saveEMAPredefinedCoachingAction(category: EMAPredefinedCoachingAction): RpcRequestBuilder<EMAPredefinedCoachingAction> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAPredefinedCoachingActionBOService.saveEMAPredefinedCoachingAction';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(category, 'fr.hiji.metier.ema.entities.template.EMAPredefinedCoachingAction', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.entities.template.EMAPredefinedCoachingAction');
  }

}
