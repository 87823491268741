@for (tag of getParents(); track tag) {
  <span [ngStyle]="{ whiteSpace: noWrap() ? 'nowrap' : null }">
    @if (getParents()[0] !== tag) {
      <span class="my-fs-2 mx-2">&gt;</span>
    }
    <span class="my-fs-2" [ngStyle]="{ color: tag.colorCode }" title="{{ tag.name | ms }}">{{ tag.name | ms }}</span>
    @if (frontMode() && !hideDescInfoButton() && (tag.description | ms) !== '') {
      <i
        class="fa-light fa-info-circle ms-2 pointer"
        (click)="descInfoClick.emit(tag)"
      ></i>
    }
    @if (!frontMode() && tag.showInRadar) {
      <span
        class="ms-2"
        ngbTooltip="{{ 'common.tag-display.Afficher dans les radar ou comme rubriques de l\'EA' | translate }}"
        container="body"
        >
        <i class="fa-light fa-star fa-sm text-primary"></i>
      </span>
    }
    @if (!frontMode() && tag.hideInFO) {
      <span
        class="ms-2"
        ngbTooltip="{{ 'common.tag-display.Cacher dans le détail des compétences des gestes observables du front' | translate }}"
        container="body"
        >
        <i class="fa-light fa-eye-slash"></i>
      </span>
    }
    @if (!frontMode() && !tag.del && tagManager.hasParentDeleted(tag.idParent)) {
      <i
        class="fa-light fa-exclamation-triangle text-warning my-fs-2 ms-2"
        ngbTooltip="{{ 'common.tag-display.Parent supprimé' | translate }}"
        container="body"
      ></i>
    }
    @if (tag.del) {
      <i
        class="fa-light fa-exclamation-triangle text-danger my-fs-2 ms-2"
        ngbTooltip="{{ 'common.tag-display.Cette compétence est supprimée' | translate }}"
        container="body"
      ></i>
    }
  </span>
}
