// ENTITY fr.hiji.metier.certif.dto.front.modality.interview.ModalityInterviewDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ModalityInterviewQuickQuestion } from './ModalityInterviewQuickQuestion';
import { ModalityInterviewExamOG } from './ModalityInterviewExamOG';
import { CertificationExam } from './CertificationExam';
import { ModalityDTO } from './ModalityDTO';
import { ObservableGestureDTO } from './ObservableGestureDTO';
import { Criteria } from './Criteria';
import { ModalityInterviewOGPerTag } from './ModalityInterviewOGPerTag';

export class ModalityInterviewDTO {

  modality: ModalityDTO | null = null;
  exam: CertificationExam | null = null;
  observableGestures: ObservableGestureDTO[] | null = [];
  answers: ModalityInterviewExamOG[] | null = [];
  quickQuestions: ModalityInterviewQuickQuestion[] | null = [];
  criterias: Criteria[] | null = [];
  interviewOGPerTag: ModalityInterviewOGPerTag[] | null = [];
  hideSkill: boolean | null = false;
  forceAnswerAllQuestions: boolean | null = false;
  draft: boolean | null = false;

  constructor(init?:Partial<ModalityInterviewDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.modality.interview.ModalityInterviewDTO'] = ModalityInterviewDTO;

reverseMapping[ModalityInterviewDTO.name] = 'fr.hiji.metier.certif.dto.front.modality.interview.ModalityInterviewDTO';

fields['fr.hiji.metier.certif.dto.front.modality.interview.ModalityInterviewDTO']  = {
    modality: 'fr.hiji.metier.certif.dto.front.modality.ModalityDTO',
    exam: 'fr.hiji.metier.certif.entities.session.CertificationExam',
    observableGestures: 'java.util.List<fr.hiji.metier.campaign.dto.front.ObservableGestureDTO>',
    answers: 'java.util.List<fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewExamOG>',
    quickQuestions: 'java.util.List<fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewQuickQuestion>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    interviewOGPerTag: 'java.util.List<fr.hiji.metier.certif.entities.modality.interview.ModalityInterviewOGPerTag>',
    hideSkill: 'java.lang.Boolean',
    forceAnswerAllQuestions: 'java.lang.Boolean',
    draft: 'java.lang.Boolean'
};
