import { Component, forwardRef, HostListener, Input, output, input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const UI_SWITCH_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  /* tslint:disable-next-line: no-use-before-declare */
  useExisting: forwardRef(() => HijiSwitchComponent),
  multi: true,
};

//from https://github.com/UncommonConcept/ngx-toggle-switch/blob/master/src/lib/ui-switch/ui-switch.component.ts

@Component({
    selector: 'hiji-switch',
    template: `
    <span
      class="switch"
      [class.checked]="checked"
      [class.disabled]="disabled"
      [class.switch-large]="size() === 'large'"
      [class.switch-medium]="size() === 'medium'"
      [class.switch-small]="size() === 'small'"
      [class.switch-labeled]="!!labelOn || !!labelOff"
      >
      <input id="enabled" name="enabled" type="checkbox" [checked]="checked" style="display: none;" aria-invalid="false" />
      <small [style.background]="getColor()"></small>
      @if (!!labelOn || !!labelOff) {
        <span class="switch-text">
          <span class="on" [innerHtml]="labelOn"></span>
          <span class="off" [innerHtml]="labelOff"></span>
        </span>
      }
    </span>
    `,
    styles: [
        `
      .switch {
        vertical-align: bottom;
        border: 1px solid #dfdfdf;
        position: relative;
        display: inline-block;
        box-sizing: content-box;
        overflow: visible;
        padding: 0;
        margin: 0;
        cursor: pointer;
        box-shadow: rgb(223, 223, 223) 0 0 0 0 inset;
        transition: 0.3s ease-out all;
        -webkit-transition: 0.3s ease-out all;
      }
      small {
        border-radius: 100%;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.3s ease-out all;
        -webkit-transition: 0.3s ease-out all;
      }
      .switch-large {
        width: 66px;
        height: 40px;
        border-radius: 40px;
      }
      .switch-large small {
        width: 40px;
        height: 40px;
      }
      .switch-medium {
        width: 50px;
        height: 30px;
        border-radius: 30px;
      }
      .switch-medium.switch-labeled {
        width: 60px;
      }
      .switch-medium small {
        width: 30px;
        height: 30px;
      }
      .switch-small {
        width: 33px;
        height: 20px;
        border-radius: 20px;
      }
      .switch-small small {
        width: 20px;
        height: 20px;
      }
      .switch-labeled {
        cursor: pointer;
      }
      .switch-large.checked small {
        left: 26px;
      }
      .switch-medium.checked small {
        left: 20px;
      }
      .switch-medium.switch-labeled.checked small {
        left: 30px;
      }
      .switch-small.checked small {
        left: 13px;
      }
      .disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      .switch .switch-text {
        font-size: 13px;
      }
      .switch .off {
        opacity: 1;
        position: absolute;
        right: 10%;
        top: 25%;
        z-index: 0;
        color: #a9a9a9;
        transition: 0.4s ease-out all;
      }
      .switch .on {
        opacity: 0;
        z-index: 0;
        color: #fff;
        position: absolute;
        top: 25%;
        left: 9%;
        transition: 0.4s ease-out all;
      }
      .switch.checked .off {
        opacity: 0;
      }
      .switch.checked .on {
        opacity: 1;
      }
    `,
    ],
    providers: [UI_SWITCH_CONTROL_VALUE_ACCESSOR],
    standalone: false
})
export class HijiSwitchComponent implements ControlValueAccessor {
  private _checked: boolean;
  private _disabled: boolean;

  readonly size = input('medium');
  readonly change = output<boolean>();
  readonly switchOffColor = input('');
  readonly switchColor = input('#fff');
  @Input() labelOn = '';
  @Input() labelOff = '';
  readonly ignoreClick = input(false);

  @Input() set checked(v: boolean) {
    this._checked = v !== false;
  }

  get checked() {
    return this._checked;
  }

  @Input() set disabled(v: boolean) {
    this._disabled = v !== false;
  }

  get disabled() {
    return this._disabled;
  }

  getColor(flag = '') {
    return this.checked ? this.switchColor() : this.switchOffColor() || this.switchColor();
  }

  @HostListener('click')
  onToggle() {
    if (this.disabled || this.ignoreClick()) {
      return;
    }
    this.checked = !this.checked;
    this.change.emit(this.checked);
    this.onChangeCallback(this.checked);
    this.onTouchedCallback(this.checked);
  }

  writeValue(obj: any): void {
    if (obj !== this.checked) {
      this.checked = !!obj;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  private onTouchedCallback = (v: any) => {};
  private onChangeCallback = (v: any) => {};
}
