// ENTITY fr.hiji.metier.ema.entities.EMACoachingAction generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMACoachingActionRemoveReason } from './EMACoachingActionRemoveReason';

export class EMACoachingAction {

  idEMACoachingAction: number | null = 0;
  idUserTargeted: number | null = 0;
  idEMACreation: number | null = 0;
  idUserCreator: number | null = 0;
  idTag: number | null = null;
  idEMAObjective: number | null = null;
  idEMAPredefinedCoachingAction: number | null = null;
  start: Date | null = null;
  end: Date | null = null;
  done: boolean | null = false;
  doneDate: Date | null = null;
  idUserDone: number | null = null;
  idEMADone: number | null = null;
  del: boolean | null = false;
  removeReason: EMACoachingActionRemoveReason | null = null;
  otherRemoveReason: string | null = '';

  constructor(init?:Partial<EMACoachingAction>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.EMACoachingAction'] = EMACoachingAction;

reverseMapping[EMACoachingAction.name] = 'fr.hiji.metier.ema.entities.EMACoachingAction';

fields['fr.hiji.metier.ema.entities.EMACoachingAction']  = {
    idEMACoachingAction: 'java.lang.Integer',
    idUserTargeted: 'java.lang.Integer',
    idEMACreation: 'java.lang.Integer',
    idUserCreator: 'java.lang.Integer',
    idTag: 'java.lang.Integer',
    idEMAObjective: 'java.lang.Integer',
    idEMAPredefinedCoachingAction: 'java.lang.Integer',
    start: 'java.time.LocalDate',
    end: 'java.time.LocalDate',
    done: 'java.lang.Boolean',
    doneDate: 'java.time.Instant',
    idUserDone: 'java.lang.Integer',
    idEMADone: 'java.lang.Integer',
    del: 'java.lang.Boolean',
    removeReason: 'fr.hiji.metier.ema.entities.EMACoachingActionRemoveReason',
    otherRemoveReason: 'java.lang.String'
};
