// ENTITY fr.hiji.metier.ema.dto.front.EMACoachingActionDTO generated by ic2 0.0.1-SNAPSHOT at Thu Apr 03 15:05:58 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { EMAPredefinedCoachingAction } from './EMAPredefinedCoachingAction';
import { EMACoachingAction } from './EMACoachingAction';

export class EMACoachingActionDTO {

  coachingAction: EMACoachingAction | null = null;
  predefinedCoachingAction: EMAPredefinedCoachingAction | null = null;
  added: boolean | null = false;
  tagName: MultilingualString | null = new MultilingualString();
  objectiveName: MultilingualString | null = new MultilingualString();
  userDone: string | null = '';

  constructor(init?:Partial<EMACoachingActionDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMACoachingActionDTO'] = EMACoachingActionDTO;

reverseMapping[EMACoachingActionDTO.name] = 'fr.hiji.metier.ema.dto.front.EMACoachingActionDTO';

fields['fr.hiji.metier.ema.dto.front.EMACoachingActionDTO']  = {
    coachingAction: 'fr.hiji.metier.ema.entities.EMACoachingAction',
    predefinedCoachingAction: 'fr.hiji.metier.ema.entities.template.EMAPredefinedCoachingAction',
    added: 'java.lang.Boolean',
    tagName: 'com.ic2.sc.MultilingualString',
    objectiveName: 'com.ic2.sc.MultilingualString',
    userDone: 'java.lang.String'
};
